<template>
  <div>
    <MaterialInfo v-bind="$props">
      <div class="xa-cell">
        <div>
          <div v-if="readonly">x{{ item.count }}</div>
          <Stepper v-else v-model="item.count" min="0" />
        </div>
        <slot></slot>
      </div>
    </MaterialInfo>
  </div>
</template>
<script>
import { Stepper } from 'vant'
import MaterialInfo from '@/components/material/MaterialInfo'
export default {
  components: {
    Stepper,
    MaterialInfo
  },
  props: {
    readonly: {
      Type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() {
        return {}
      }
    }
  }
}
</script>
<style lang="scss">
.material-input-item {
  img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    object-fit: contain;
  }
  .material__code {
    line-height: 1.5;
  }
}
</style>
