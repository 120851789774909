<template>
  <div>
    <div v-for="(item, index) in items" :key="index" class="xa-line__bottom">
      <MaterialInfo :item="item" :readonly="true">
        <div>x{{ item.count }}</div>
      </MaterialInfo>
      <div v-if="item.items && item.items.length" class="type-item__box">
        <div v-for="(mItem, mIndex) in item.items" :key="mIndex + '_' + index">
          <div class="xa-cell info-box">
            <van-tag mark :color="getTypeColor(mItem.material_type_text)">{{
              mItem.material_type_text
            }}</van-tag>
            <div class="xa-flex info-box__code">{{ mItem.code }}</div>
            <div>{{ mItem.count }}</div>
          </div>
          <MaterialDisplaySn
            v-if="mItem.serial_numbers && mItem.serial_numbers.length"
            :items="mItem.serial_numbers"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MaterialInfo from '@/components/material/MaterialInfo'
import MaterialDisplaySn from '@/components/material/MaterialDisplaySn'
import { Tag } from 'vant'
export default {
  name: 'MaterialTypeList',
  components: { MaterialInfo, MaterialDisplaySn, VanTag: Tag },
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    }
  },
  methods: {
    getTypeColor(material_type_text) {
      return '#' + material_type_text.charCodeAt(0) + '0'
    }
  }
}
</script>
<style lang="scss" scoped>
.info-box {
  padding: 10px 15px 0px 15px;
  background-color: $default-page-color--light;
  &__code {
    padding-left: 8px;
  }
}
.type-item__box {
  padding-bottom: 10px;
  background-color: $default-page-color--light;
}
</style>
