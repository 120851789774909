import { register } from '../service'
register('/mock/responAnalysis', () => {
    return {

        "doc_title": "飞行日志分析报告",
        "head": {
            "title": "飞行日志分析报告",
            "sign": "",
            "remarks": [
                "提交时间：2020-06-15",
                "极飞科技-服务运营部"
            ]
        },
        "contents": [
            {
                "title": "基本信息",
                "contents": [
                    {
                        "key": "序列号",
                        "value": "2147370051"
                    },
                    {
                        "key": "机型",
                        "value": "P20 2017"
                    },
                    {
                        "key": "保障员",
                        "value": "王雨"
                    },
                    {
                        "key": "服务单号",
                        "value": "S2006125808"
                    },
                    {
                        "key": "质保期限",
                        "value": "2010-02-14"
                    }
                ],
                "img": ""
            },
            {
                "title": "故障分析信息",
                "contents": [
                    {
                        "key": "故障架次",
                        "value": "111111111"
                    },
                    {
                        "key": "故障类型",
                        "value": "机体震动过大"
                    },
                    {
                        "key": "故障原因",
                        "value": "环境影响"
                    }
                ],
                "img": ""
            },
            {
                "title": "飞行日志综合分析",
                "contents": [
                    {
                        "key": "",
                        "value": "44"
                    }
                ],
                "img": ""
            },
            {
                "title": "故障检查或维修建议",
                "contents": [
                    {
                        "key": "",
                        "value": "反馈建议10086"
                    }
                ],
                "img": ""
            },
            {
                "title": "附图1(点击查看原图)",
                "contents": [],
                "img": {
                    "url": "http://agri-private.static.xag.cn/v3/upload/2021/04/25/ddde51ecf9c61943a86c2c74afcc023d?e=1934681084&token=kL3qRYaFy4Ip8uZVbJyYE1KL6GgwtdNbqwu5C4lO:GDAKWRRNeSWtP25P_c_qkBcvoGM=",
                    "thumb_url": "http://agri-private.static.xag.cn/v3/upload/2021/04/25/ddde51ecf9c61943a86c2c74afcc023d?imageView2/0/w/450/h/450&e=1619493723&token=kL3qRYaFy4Ip8uZVbJyYE1KL6GgwtdNbqwu5C4lO:8YmuGiO7aQxz3S_e8PkmKJN7OiM="
                }
            },
            {
                "title": "附图2(点击查看原图)",
                "contents": [],
                "img": {
                    "url": "http://agri-private.static.xag.cn/v3/upload/2021/04/25/a38a2759a2f4b8a488e82a556313e648?e=1934681084&token=kL3qRYaFy4Ip8uZVbJyYE1KL6GgwtdNbqwu5C4lO:1RlUAi7lX5Jpssq6ZudyseKfU00=",
                    "thumb_url": "http://agri-private.static.xag.cn/v3/upload/2021/04/25/a38a2759a2f4b8a488e82a556313e648?imageView2/0/w/450/h/450&e=1619493723&token=kL3qRYaFy4Ip8uZVbJyYE1KL6GgwtdNbqwu5C4lO:6SkZynCzou07yPWUNgdAS-DwAGs="
                }
            },
            {
                "title": "附图3(点击查看原图)",
                "contents": [],
                "img": {
                    "url": "http://agri-private.static.xag.cn/v3/upload/2021/04/25/b79999dc07c6a8245c587fbacf00698c?e=1934681084&token=kL3qRYaFy4Ip8uZVbJyYE1KL6GgwtdNbqwu5C4lO:bYhdLJoEztjTDB7ooeLEwYiNRnw=",
                    "thumb_url": "http://agri-private.static.xag.cn/v3/upload/2021/04/25/b79999dc07c6a8245c587fbacf00698c?imageView2/0/w/450/h/450&e=1619493723&token=kL3qRYaFy4Ip8uZVbJyYE1KL6GgwtdNbqwu5C4lO:xAcYsXuk2XXOzWjF8kd8XJnh4Pk="
                }
            },
            {
                "title": "附图4(点击查看原图)",
                "contents": [],
                "img": {
                    "url": "http://agri-private.static.xag.cn/v3/upload/2021/04/25/2ed1159a67898b3d1dc938c7d3a52e0b?e=1934681084&token=kL3qRYaFy4Ip8uZVbJyYE1KL6GgwtdNbqwu5C4lO:SHDJ9aHvbQ-xtA8GkMmmcaSvg84=",
                    "thumb_url": "http://agri-private.static.xag.cn/v3/upload/2021/04/25/2ed1159a67898b3d1dc938c7d3a52e0b?imageView2/0/w/450/h/450&e=1619493723&token=kL3qRYaFy4Ip8uZVbJyYE1KL6GgwtdNbqwu5C4lO:9s-vHeJ36obOB_MZtsB8ny8q1bY="
                }
            }
        ],
        "type": null
    }

})
