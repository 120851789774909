<template>
  <div class="ensure-images">
    <div
      class="ensure-images__cell"
      v-for="(item, index) in list"
      :key="index"
      :style="cellStyle"
    >
      <img
        v-if="item && item.thumb_url"
        :src="item.thumb_url"
        @click.stop="onPreviewImage(index)"
      />
      <Loading v-else />
    </div>
  </div>
</template>
<script>
import { Loading, ImagePreview } from 'vant'
import resource from '@/controllers/resource'
export default {
  components: {
    Loading
  },
  props: {
    width: [Number, String],
    height: [Number, String],
    images: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      preViewInstance: null,
      list: []
    }
  },
  watch: {
    images(value) {
      this.list = Array(value.length)
      this.fetchImageFormQiniu(value)
    }
  },
  computed: {
    cellStyle() {
      if (this.width && this.height) {
        return {
          width: this.width + 'px',
          height: this.height + 'px',
          padding: 0
        }
      }
      return {}
    }
  },
  methods: {
    onPreviewImage(index) {
      const instance = ImagePreview({
        images: this.list.map(item => item.url),
        startPosition: index
      })
      this.preViewInstance = Object.freeze({
        close: instance.close
      })
    },
    async fetchImageFormQiniu(keys) {
      if (this.images.length) {
        const list = await resource.postQiniuKey2Path(keys)
        this.$nextTick(() => {
          this.list = list
        })
      }
    }
  },
  beforeMount() {
    this.list = Array(this.images.length)
    this.fetchImageFormQiniu(this.images)
  },
  beforeDestroy() {
    if (this.preViewInstance) {
      this.preViewInstance.close()
      this.preViewInstance = null
    }
  }
}
</script>
<style lang="scss">
.ensure-images {
  display: flex;
  flex-wrap: wrap;
  &__cell {
    $size: 100%/3;
    position: relative;
    width: $size;
    padding-top: $size;
  }
  .van-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
  img {
    display: block;
    position: absolute;
    width: 95%;
    height: 95%;
    top: 0;
    left: 0;
    object-fit: contain;
    box-shadow: 0px 0px 1px #888 inset;
  }
}
</style>
