/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    pullRefreshDisabled: false, // 是否开启PullRefresh 下拉刷新 控件
    isRefreshing: false,
    showActionSheet: false,
    actionSheetActions: [],
    actionSheetResolve: null
  },
  mutations: {
    setActionSheetResult(state, item) {
      state.actionSheetResolve(state.actionSheetActions.indexOf(item))
      state.showActionSheet = false
    },
    _setActionSheetResolve(state, promise) {
      state.actionSheetResolve = promise
    },
    setActionSheetActions(state, actions) {
      state.actionSheetActions = (actions || []).map(item => {
        // eslint-disable-next-line no-undef
        return item['name'] !== undefined
          ? item
          : {
              name: item
            }
      })
    },
    triggleRefreshDisable(state, value) {
      state.pullRefreshDisabled =
        value !== undefined ? value : !state.pullRefreshDisabled
    },
    triggleRefresh(state, value) {
      state.isRefreshing = value
    },
    triggleActionSheet(state, value) {
      state.showActionSheet = value
    }
  },
  actions: {
    async showActionSheet({ commit }, actions) {
      commit('setActionSheetActions', actions)
      commit('triggleActionSheet', true)
      return new Promise(resolve => {
        commit('_setActionSheetResolve', resolve)
      })
    }
  },
  modules: {}
})
