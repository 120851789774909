<template>
  <div>
    <MaterialInfo v-bind="$props" @click.native="showAllSn = !showAllSn">
      <div class="xa-cell">
        <div>
          <div>x{{ item.serial_numbers.length }}</div>
          <Icon :name="showAllSn ? 'arrow-up' : 'arrow-down'" />
        </div>
        <slot></slot>
      </div>
    </MaterialInfo>
    <transition name="van-fade">
      <MaterialRadioDeleteSn
        v-if="showAllSn && readonly == false"
        :items="item.serial_numbers"
        @delete="onDelete"
      />
      <MaterialDisplaySn
        v-if="showAllSn && readonly == true"
        :items="item.serial_numbers"
      />
    </transition>
  </div>
</template>
<script>
import { Icon } from 'vant'
import MaterialRadioDeleteSn from '@/components/material/MaterialRadioDeleteSn'
import MaterialDisplaySn from '@/components/material/MaterialDisplaySn'
import MaterialInfo from '@/components/material/MaterialInfo'
export default {
  components: {
    Icon,
    MaterialRadioDeleteSn,
    MaterialDisplaySn,
    MaterialInfo
  },
  data() {
    return {
      showAllSn: false
    }
  },
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    item: {
      Type: Object,
      default() {
        return {}
      }
    }
  },
  methods: {
    onDelete(sn) {
      this.$emit('delete', sn)
    }
  }
}
</script>
<style lang="scss"></style>
