import basePage from '@/mixins/basePage'
import TemplateDetail from '@/components/template/TemplateDetail'
import appController, { isApp } from '@/controllers/app-android'
const onAppName = 'onAppOpenMenuBtnsCompleted' // app端顶部菜单按钮事件
export default {
  mixins: [basePage],
  components: {
    TemplateDetail
  },
  data() {
    return {
      coms: [],
      actions: [],
      appActions: []
    }
  },
  methods: {
    $_detialOnMenuBtnsClick(index) {
      const btn = this.appActions[index]
      btn && this.$_handleCfgAction(btn)
    },
    // 初始化页面
    $_initDetailPage(result) {
      let { body: coms, actions = [], actionsPosition = '' } = result
      this.coms = coms
      this.actions = []
      this.appActions = []
      if (isApp) {
        appController.clearSubscriber(onAppName)
        appController.setAppMenuBtns()
      }
      if (actions.length === 0) return
      if (isApp && actionsPosition === 'APP_MENU') {
        this.appActions = Object.freeze(actions)
        appController.addSubscriber(onAppName, this.$_detialOnMenuBtnsClick)
        appController.setAppMenuBtns(actions.map(item => item.title))
      } else {
        const moreActions =
          actions.length > 2 ? actions.splice(2, actions.length - 2) : []
        if (moreActions.length) {
          actions.push({
            title: '更多',
            UiType: 'more',
            items: moreActions
          })
        }
        this.actions = actions
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    if (isApp) {
      try {
        appController.removeSubscriber(onAppName, this.$_detialOnMenuBtnsClick)
        appController.setAppMenuBtns()
      } catch (error) {
        window.console.error(error)
      }
    }
    next()
  }
}
