import { request } from './util'
const modules = {}
const service = function(config) {
  let { url, data } = config
  url = url.toUpperCase()
  if (url in modules === false) {
    window.console.warn('没有注册到该mock请求', url)
    return Promise.reject({ message: '没有注册到该mock请求' + url })
  }
  window.console.log(url)
  const result = modules[url](data)
  if (result.then) {
    return result
  } else {
    return request(data, result)
  }
}

service.in = function({ url }) {
  return url.toUpperCase() in modules
}

export default service

export function register(path, fn) {
  path = path.toUpperCase()
  if (path in modules) {
    window.console.warn('已经注册过该mock路由', path)
  }
  modules[path] = fn
}
