import CellBtn from './EnsureCellBtn'
export default function() {
  return {
    title: '维修步骤',
    activeIndex: 0,
    items: Array(8)
      .fill(1)
      .map((_, index) => {
        return {
          title: '接单',
          subTitle: index % 4 !== 0 ? '检查故障、录入坏件' : '',
          value: 'xxx已接单',
          href: index % 4 == 0 ? 'https://www.xa.com' : '',
          btns: CellBtn(index % 3)
        }
      })
  }
}
