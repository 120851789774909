<template>
  <div class="ensure-step xa-txt-15 xa-bg-white">
    <div
      v-for="(item, index) in items"
      :key="index + item.title"
      class="xa-cell ensure-step__cell xa-line__bottom"
      @click="onClick(item)"
      :class="{
        'has-subTitle': !!item.subTitle,
        'is-done': index < activeIndex,
        'is-active': index == activeIndex
      }"
    >
      <div class="ensure-step__index">{{ index + 1 }}</div>
      <div class="xa-flex">
        <div class="ensure-step__title">{{ item.title }}</div>
        <div
          v-if="item.subTitle"
          class="xa-txt-12 xa-txt-secondary ensure-step__subTitle"
        >
          {{ item.subTitle }}
        </div>
      </div>
      <div v-if="item.btns && item.btns.length">
        <xa-button
          size="small"
          v-for="(btn, index) in item.btns"
          :key="btn.txt + '' + index"
          v-bind="getBtnCfg(index, item)"
          @click.native.stop="onBtnClick(btn)"
          >{{ btn.txt || btn.title }}</xa-button
        >
      </div>
      <div v-else class="xa-txt-regular">{{ item.value }}</div>
      <div v-if="needArrow(item)" class="xa-arrow"></div>
    </div>
  </div>
</template>
<script>
import Button from '@/components/common/Button'
export default {
  components: {
    XaButton: Button
  },
  props: {
    activeIndex: {
      type: [Number, String],
      default: 0
    },
    items: {
      type: Array,
      default() {
        return []
      }
    }
  },
  methods: {
    getBtnCfg(btnIndex, item) {
      if (item.btns.length > 1 && btnIndex === 0) {
        return {
          plain: true,
          type: 'info'
        }
      }
      return {
        type: 'info'
      }
    },
    onClick(item) {
      const { href, ajax } = item
      if (href || ajax) {
        this.onBtnClick(item)
      }
    },
    onBtnClick(btn) {
      this.$emit('action', btn, this)
    },
    needArrow(item) {
      return !!item.href
    }
  }
}
</script>
<style lang="scss">
@mixin cell-colors($color) {
  .ensure-step__index {
    background: $color;
  }
  .ensure-step__title {
    color: $color;
  }
}
.ensure-step {
  text-align: left;
  .van-button + .van-button {
    margin-left: 8px;
  }
  .van-button--plain {
    border: none;
  }
  &__index {
    $size: 17px;
    margin: 2px 0;
    width: $size;
    height: $size;
    margin-right: $size/2;
    background: #999;
    border-radius: 50%;
    color: #ffffff;
    text-align: center;
    line-height: $size;
    font-size: 13px;
    align-self: flex-start;
  }
  &__head {
    padding: 15px;
  }
  &__title {
    line-height: 21px;
  }
  &__subTitle {
    margin-top: 6px;
  }
  &__cell {
    padding: 25px 15px;
    &.has-subTitle {
      padding: 15px;
    }
    &.is-done {
      @include cell-colors($color-green);
    }
    &.is-active {
      @include cell-colors($color-yellow);
    }
  }
}
</style>
