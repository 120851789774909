<template>
  <div class="material-display-sns">
    <div v-for="item in items" :key="item">
      <Tag plain type="primary" @click="$emit('click', item)">{{ item }}</Tag>
    </div>
  </div>
</template>
<script>
import { Tag } from 'vant'
export default {
  components: {
    Tag,
  },
  props: {
    items: {},
  },
}
</script>
<style lang="scss">
.material-display-sns {
  padding: 10px 0px 0px 15px;
  display: flex;
  flex-wrap: wrap;
  background-color: $default-page-color--light;
  .van-tag {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
</style>
