<template>
  <section>
    <van-tabs v-model="active">
      <van-tab v-for="item in tabs" :key="item" :title="item"></van-tab>
    </van-tabs>
    <CellGroup>
      <Cell
        v-for="(item, index) in items"
        :key="index"
        v-bind="item"
        @click.native="onCellClick(item)"
      >
        <div slot="label">
          <pre v-html="item.label" style="word-break: break-all;white-space: break-spaces;"></pre>
        </div>
      </Cell>
    </CellGroup>
  </section>
</template>
<script>
import diyDetail from '@/mixins/diyDetail'
import appController, { isApp } from '@/controllers/app-android'
import wxController, { isMini } from '@/controllers/app-wx'
import { Cell, CellGroup, Tab, Tabs } from 'vant'
export default {
  name: 'Cache-index',
  mixins: [diyDetail],
  config: {
    title: '目录',
    fetchDataFn: 'initView'
  },
  components: {
    Cell,
    CellGroup,
    VanTab: Tab,
    VanTabs: Tabs
  },
  data () {
    let tabs = isApp
      ? ['业务页面', '详情模板', '列表模板', 'App']
      : ['业务页面', '详情模板', '列表模板']
    if (isMini) {
      tabs.push('小程序')
    }
    return {
      tabs,
      dataSource: {},
      active: -1
    }
  },
  computed: {
    items () {
      let tab = this.tabs[this.active]
      return this.dataSource[tab] || []
    }
  },
  methods: {
    onCellClick (item) {
      const { method } = item
      this[method] && this[method](item)
    },
    async scanQrCode () {
      const code = await appController.toScanQRCode()
      this.$dialog.alert({
        message: 'scanQrCode:' + code
      })
    },
    gotoMini () {
      window.alert('gotoMini')
      wxController.push('/pages/entry')
    },
    async initView () {
      let dataSource = await this.$_request({
        url: '/mock/catalog'
      })
      this.dataSource = Object.freeze(dataSource)
      this.active = 0
    }
  }
}
</script>
