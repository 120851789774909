import { register } from './service'
import { request } from './util'
function mock(params) {
  return request(params, {
    title: '保障单处理记录',
    body: {
      active: 1,
      list: [
        {
          title: `请刘志勇到佳木斯市富锦市西平路19号“佳木斯极飞总代”仓库领配件，领配件号：098983274896`,
          subTitle: '2019.1.23  13:00',
        },
        {
          title: `保障专员张志杰已出故障维修单`,
          subTitle: '2019.1.23  13:00',
        },
        {
          title: `已通知保障网点，待保障专员接单`,
          subTitle: '2019.1.23  13:00',
        },
      ],
    },
  })
}

export default mock

register('/mock/ensure-steps', mock)
