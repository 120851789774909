<template>
  <div class="material-read-list">
    <div
      v-for="(item, index) in displayItems"
      :key="index"
      class="xa-line__bottom"
    >
      <materialInputItem
        v-if="item.is_single == 0"
        :item="item"
        :readonly="true"
      >
        <div
          v-if="item.images.length"
          class="xa-txt-center img-box xa-txt-12 xa-color-info"
          @click.stop="onImgClick(item)"
        >
          <span>{{ item.images.length }}</span>
          <div>照片</div>
        </div>
      </materialInputItem>
      <materialSnItem v-if="item.is_single == 1" :item="item" :readonly="true">
        <div
          v-if="item.images.length"
          class="xa-txt-center img-box xa-txt-12 xa-color-info"
          @click.stop="onImgClick(item)"
        >
          <span>{{ item.images.length }}</span>
          <div>照片</div>
        </div>
      </materialSnItem>
    </div>
  </div>
</template>
<script>
import materialInputItem from '@/components/material/MaterialInputItem'
import materialSnItem from '@/components/material/MaterialSnItem'
export default {
  components: {
    materialSnItem,
    materialInputItem
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    displayItems() {
      return this.items.map(item => {
        let is_single = 0
        if (item.serial_numbers && item.serial_numbers.length > 0) {
          is_single = 1
        }
        return {
          ...item,
          is_single: item.is_single || is_single,
          images: item.images || []
        }
      })
    }
  },
  methods: {
    onImgClick(item) {
      window.console.log(item)
      this.$router.push({
        name: 'image-preview',
        params: {
          guids: item.images
        }
      })
    }
  }
}
</script>
<style lang="scss">
.img-box {
  width: 40px;
  margin-left: 8px;
  border-left: 1px solid #e4e4e4;
}
</style>
