<template>
  <div class="ensure-info xa-bg-white xa-txt-14">
    <div
      v-for="(item, index) in items"
      :key="index"
      class="xa-cell ensure-info__cell"
    >
      <div class="ensure-info__title">{{ item.title }}</div>
      <div class="xa-flex">
        <MarkdownLoader v-if="item.value" v-bind="item" />
        <EnsureImages v-if="item.images && item.images.length" v-bind="item" />
      </div>
    </div>
  </div>
</template>
<script>
import EnsureImages from '@/components/EnsureImages'
import MarkdownLoader from '@/components/MarkdownLoader'
export default {
  components: {
    EnsureImages,
    MarkdownLoader
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    }
  }
}
</script>
<style lang="scss">
.ensure-info {
  padding: 15px;
  text-align: left;
  line-height: 1.5;
  &__cell {
    & + & {
      margin-top: 4px;
    }
  }
  &__title {
    align-self: flex-start;
    color: $default-text-color--light;
    width: 5.5em;
    flex-shrink: 0;
  }
}
</style>
