<template>
  <div class="ensure-logistics__box xa-cell__box">
    <div class="ensure-logistics__head xa-line__bottom xa-cell">
      <div class="xa-flex">
        <p class="xa-txt-blue">{{ name }}</p>
        <p class="xa-txt-12 xa-txt-primary" v-show="code">快递单号：{{ code }}</p>
      </div>
      <MarkdownLoader :value="status"/>
    </div>
    <div
      v-for="(item, index) in items"
      :key="index"
      class="xa-cell xa-cell--flex"
      v-show="showAll || index < 1"
    >
      <div class="icon-box xa-mgr-16">
        <div class="iconfont icon-zuobiao xa-txt-blue xa-bg-white"></div>
      </div>
      <div class="xa-flex">
        <div class="xa-txt-primary">{{ item.title }}</div>
        <div class="xa-txt-12 xa-txt-secondary">{{ item.value }}</div>
      </div>
    </div>
    <div
      class="xa-txt-12 xa-txt-center xa-txt-blue"
      v-show="items.length > 1"
      @click="showAll = !showAll"
    >
      {{ showAll ? '收起物流信息' : '展开物流信息' }}
    </div>
  </div>
</template>
<script>
import MarkdownLoader from '@/components/MarkdownLoader'
export default {
  components: {
    MarkdownLoader
  },
  props: {
    name: String,
    code: String,
    status: String,
    items: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      showAll: false
    }
  }
}
</script>
<style lang="scss" scoped>
.ensure-logistics__head {
  padding-bottom: 5px;
  margin-bottom: 5px;
}
.icon-box {
  align-self: flex-start;
}
</style>
