import { postQiniuKey2Path } from '@/apis/resource'
import request from '@/controllers/request'
class Cache {
  constructor() {
    this.store = {}
  }
  postQiniuKey2Path(keys) {
    let storeKey = JSON.stringify(keys)
    if (this.store[storeKey] === undefined) {
      this.store[storeKey] = request(postQiniuKey2Path(keys)).catch(error => {
        this.store[storeKey] = undefined
        throw error
      })
    }
    return this.store[storeKey]
  }
}

export default new Cache()
