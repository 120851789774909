const COLORS = {
  red: '#e1504f',
  blue: '#366eb4',
  green: '#52c41a',
  yellow: '#F17e20'
}
let mockId = 1
export function request(config, data, timeout) {
  let curMockId = mockId++
  console(`${curMockId}:页面 ${window.location.hash}`)
  console(`${curMockId}:mock:请求参数`)
  console(`${curMockId}:` + JSON.stringify(config, null, 2))
  return new Promise(resolve => {
    setTimeout(() => {
      console(`${curMockId}:返回结果`)
      console(`${curMockId}:` + JSON.stringify(data, null, 2))
      resolve(data)
    }, timeout || 1500)
  })
}

export function errResult(message) {
  return Promise.reject(new Error(message))
}

export function checkSubmitData(data, checkLists = []) {
  const checkResult = checkLists.find(key => {
    if (typeof key === 'string') {
      if (data[key] === undefined || data[key] === '') {
        return true
      }
    }
  })
  if (checkResult) {
    return errResult(`${checkResult}必填`)
  }
  return ''
}

export function getRangeNum(start, end) {
  return parseInt(Math.random() * (end - start)) + start
}

export function console(msg, color = COLORS.blue) {
  window.console.log(`%c${msg}`, `color:${color}`)
}
