import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {
  Button,
  Cell,
  Dialog,
  CellGroup,
  Field,
  ImagePreview,
  Toast,
  Notify,
  Popup
} from 'vant'
import 'reset-css'
import './styles/global.scss'
import { setGlobelHeaderByUrl } from '@/controllers/request'
import MarkdownLoader from '@/components/MarkdownLoader'

if (process.env.VUE_APP_ONLINE_MOCK > 0)
  // 是否需要加载mock
  require('./mock/index')

Vue.use(Button)
  .use(Cell)
  .use(CellGroup)
  .use(Dialog)
  .use(Field)
  .use(ImagePreview)
  .use(Toast)
  .use(Notify)
  .use(Popup)
Vue.config.productionTip = false
setGlobelHeaderByUrl(window.location.hash, 'token')
Vue.component(MarkdownLoader.name, MarkdownLoader)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
