import { register } from './service'

function getTime() {
  return Math.ceil(new Date().getTime() / 1000)
}
// 获取消息页面的轮播图
function GetMsgBanner() {
  return {
    banners: [
      {
        img:
          'http://agri-private.static.xag.cn/xservice/protection/materials/2019/07/04/ffa2952073697f460ece7466132fdd14.jpg?e=1877604662&token=kL3qRYaFy4Ip8uZVbJyYE1KL6GgwtdNbqwu5C4lO:eNgudCksUfkheWu54mnXdgg0XjQ=',
        title: 'P20上线啦'
      },
      {
        img:
          'http://agri-private.static.xag.cn/xservice/protection/materials/2019/07/04/bada3bba3e7ffc3e2cb8a7b61fbff976.jpg?e=1877604675&token=kL3qRYaFy4Ip8uZVbJyYE1KL6GgwtdNbqwu5C4lO:KPIqcEm7aLVYSfwCW3j0NogcAJw=',
        title: 'P20上线啦'
      }
    ]
  }
}

function GetMsgList() {
  return {
    list: [
      {
        img:
          'http://agri-private.static.xag.cn/xservice/protection/materials/2019/07/04/bada3bba3e7ffc3e2cb8a7b61fbff976.jpg?e=1877604675&token=kL3qRYaFy4Ip8uZVbJyYE1KL6GgwtdNbqwu5C4lO:KPIqcEm7aLVYSfwCW3j0NogcAJw=',
        title: '故障保障服务',
        subtitle: '您的"王者一号"保障单服务完成，邀请您王者一号',
        url: '',
        token: '',
        notice: 1,
        time: Math.ceil(new Date().getTime() / 1000)
      },
      {
        icon: 'icon-chaijieguanlian #FFA940',
        title: '故障保障服务',
        subtitle: '您的"王者一号"保障单服务完成，邀请您王者一号',
        url: '',
        token: '',
        notice: 1,
        time: 1574232311
      },
      {
        icon: 'icon-cangku #FFA940',
        title: '故障保障服务',
        subtitle: '您的"王者一号"保障单服务完成，邀请您王者一号',
        url: '',
        token: '',
        notice: 5,
        time: 1574145974
      },
      {
        img:
          'http://agri-private.static.xag.cn/xservice/protection/materials/2019/07/04/ffa2952073697f460ece7466132fdd14.jpg?e=1877604662&token=kL3qRYaFy4Ip8uZVbJyYE1KL6GgwtdNbqwu5C4lO:eNgudCksUfkheWu54mnXdgg0XjQ=',
        title: '故障保障服务',
        subtitle: '您的"王者一号"保障单服务完成，邀请您',
        url: '',
        token: '',
        time: 1572710400
      },
      {
        img:
          'http://agri-private.static.xag.cn/xservice/protection/materials/2019/07/04/ffa2952073697f460ece7466132fdd14.jpg?e=1877604662&token=kL3qRYaFy4Ip8uZVbJyYE1KL6GgwtdNbqwu5C4lO:eNgudCksUfkheWu54mnXdgg0XjQ=',
        title: '故障保障服务',
        subtitle: '您的"王者一号"保障单服务完成，邀请您王者一号',
        url: '',
        token: '',
        time: 1573574400
      },
      {
        icon: 'icon-dianli #36CFC9',
        title: '故障保障服务',
        subtitle: '您的"王者一号"保障单服务完成，邀请您王者一号',
        url: '',
        token: '',
        time: 1573574400
      },
      {
        icon: 'icon-sousuo #FFA940',
        title: '故障保障服务',
        subtitle: '您的"王者一号"保障单服务完成，邀请您王者一号',
        url: '',
        token: '',
        time: 1573574400
      }
    ]
  }
}

function GetMsgDetail(params) {
  const { id } = params
  window.console.warn(id)
  return {
    info: GetMsgList().list[id],
    list: [
      {
        content: '有客户新回复请查看',
        time: getTime(),
        href: ''
      },
      {
        content: '有客户新回复请查看 有客户新回复请查看 有客户新回复请查看',
        time: getTime(),
        href: ''
      },
      {
        content: '有客户新回复请查看 有客户新回复请查看 有客户新回复请查看',
        time: getTime(),
        href: ''
      },
      {
        content: '有客户新回复请查看 有客户新回复请查看 有客户新回复请查看',
        time: getTime(),
        href: ''
      },
      ...Array(12)
        .fill(1)
        .map(i => {
          return {
            content: '有客户新回复请查看 有客户新回复请查看 有客户新回复请查看',
            time: getTime() + i,
            href: ''
          }
        })
    ]
  }
}

register('/mock/banner', GetMsgBanner)
register('/mock/list', GetMsgList)
register('/mock/detail', GetMsgDetail)
