<template>
  <div class="material-info xa-cell__box xa-cell xa-bg-white">
    <img
      :src="
        item.image || 'http://agri-pub.static.xag.cn/image/xag/logo/logo_en.png'
      "
    />
    <div class="xa-flex">
      <div class="xa-txt-14 material__code">
        <div>{{ item.code }}</div>
      </div>
      <div class="material__tip xa-txt-12 xa-txt-secondary xa-cell">
        <span>{{ item.name }}</span>
        <span v-if="item.price != undefined&&item.price !== ''" class="xa-txt-yellow material__tab"
          >￥{{ item.price }}</span
        >
        <span v-if="item.stock != undefined" class="xa-txt-yellow material__tab"
          >库存:{{ item.stock }}</span
        >
      </div>
    </div>
    <div :class="{ 'read-box': readonly }">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    readonly: {
      Type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() {
        return {}
      }
    }
  }
}
</script>
<style lang="scss">
.material-info {
  img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    object-fit: contain;
  }
  .material__code {
    line-height: 1.5;
    justify-content: space-between;
    padding-right: 8px;
  }
  .material__tip {
    justify-content: space-between;
  }
  .material__tab {
    padding: 0 4px;
    flex-shrink: 0;
  }
  .read-box {
    align-self: flex-start;
  }
}
</style>
