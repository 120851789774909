import { getRangeNum } from '@/mock/util'
const MATERIAL_TYPES = {
  WHOLE_NEW: '成品',
  TURN_OVER: '周转',
  BROKEN: '不良品',
  SCRAP: '废品',
  GOOD: '好件',
  BAD: '坏件',
  RECYCLE_BAD: ' 可回收坏件'
}

function getTypeMaterial({ material_type = 'RECYCLE_BAD', needSn = true }) {
  const serial_numbers = needSn ? getSn(getRangeNum(0, 12)) : []
  return {
    material_type_text: MATERIAL_TYPES[material_type],
    code: '02-023-00033',
    count: serial_numbers.length || getRangeNum(1, 10),
    serial_numbers
  }
}

function getSn(count = 5) {
  return [
    'asdfg33hjk',
    'qwer2rtyui',
    '1re2345678',
    'ee1qaz2wsx',
    'e3eedc4rfv',
    'f234324234',
    '2343244dsf',
    '2343fdsfsd',
    'sdf23432dfs',
    'sd423432dfs',
    '4234322sdfd',
    '3r43242sdfd'
  ].slice(0, count)
}

const mockImages = [
  'CAFED0CAEFF82B6E7B84B2B626C9A2E9',
  'EA1976DC06ACFC7B2F58003BE8283AB7',
  'EE0313BB20ABD0E331BEAE69EE512A16',
  'F1A3B2316A7D2C85A103332E2886C0B5',
  '086716B0D563D556AD81156F56266086',
  '2644EA65F9DE15CBEC9F35EAEBA87DB2',
  '49924A73118931C3AFFACD4B78923C14',
  'B19D9C84C2A2126B2A56A7154D85C9CC'
]

function getMaterialBaseInfo({ type = '', count = 4 } = {}) {
  let info = {
    image:
      'http://agri-private.static.xag.cn/xservice/protection/materials/2019/07/04/bada3bba3e7ffc3e2cb8a7b61fbff976.jpg?imageView2/0/w/150/h/150&e=1878185193&token=kL3qRYaFy4Ip8uZVbJyYE1KL6GgwtdNbqwu5C4lO:FCUd7OOxItMw2uCZr9htopMseVo=',
    name: '螺旋桨（反）',
    code: '05-002-00212',
    stock:
      Math.ceil(Math.random() * 2) > 1 ? Math.ceil(Math.random() * 2) : null,
    count,
    images: mockImages
  }
  if (type.toUpperCase() === 'PRICE') {
    info.price = Math.ceil(Math.random() * 1000)
  }
  return info
}

function getIsSingleMaterial(type) {
  const material = getMaterialBaseInfo({ type })
  material.is_single = 1
  material.serial_numbers = [
    'asdfghjk',
    'qwertyui',
    '12345678',
    '1qaz2wsx',
    '3edc4rfv'
  ]
  return material
}

function getNoSingleMaterial(type) {
  const material = getMaterialBaseInfo({ type })
  material.is_single = 0
  return material
}

const MATERIAL_TYPES_FUNS = [getIsSingleMaterial, getNoSingleMaterial]

export function getTypeMaterialList() {
  function calcItemCount(material) {
    return material.items.reduce((total, item) => {
      return total + item.count
    }, 0)
  }
  const hasSnItem = getMaterialBaseInfo()
  hasSnItem.items = [
    getTypeMaterial({
      material_type: 'GOOD'
    }),
    getTypeMaterial({
      material_type: 'BAD'
    }),
    getTypeMaterial({
      material_type: 'WHOLE_NEW'
    }),
    getTypeMaterial({
      material_type: 'SCRAP'
    })
  ]
  hasSnItem.count = calcItemCount(hasSnItem)
  const noSnItem = getMaterialBaseInfo()
  noSnItem.items = [
    getTypeMaterial({
      material_type: 'GOOD',
      needSn: false
    }),
    getTypeMaterial({
      material_type: 'BAD',
      needSn: false
    }),
    getTypeMaterial({
      material_type: 'WHOLE_NEW',
      needSn: false
    }),
    getTypeMaterial({
      material_type: 'SCRAP',
      needSn: false
    })
  ]
  noSnItem.count = calcItemCount(noSnItem)
  return [hasSnItem, noSnItem]
}

export default function({ count = 4, type } = {}) {
  return {
    title: 'esMList',
    items: Array(count)
      .fill(2)
      .map((_, index) => {
        return MATERIAL_TYPES_FUNS[index % 2](type)
      })
  }
}
