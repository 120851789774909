import { register } from './service'

function resultOk() {
  return {}
}

register('/app/member/protection/service/home/check_can_auth_as', function(
  data
) {
  return {
    sn: data.serial_number,
    serial_number: data.serial_number,
    name: 'P30 2019款智能电池',
    model: 'BAT1',
    model_name: 'B12710',
    pic:
      'http://agri-private.static.xag.cn/v3/upload/2019/01/28/bfcc77c0413f680a06b3f8e4526c37ac.jpg?imageView2/0/w/600/h/600&e=1866027861&token=kL3qRYaFy4Ip8uZVbJyYE1KL6GgwtdNbqwu5C4lO:p0KViIeBhl1gQifrZfUZiJMDdRQ='
  }
})

register('/app/operation/protection/service/loss/ensure_sn', function() {
  return {}
})

function getLastLossMaterial() {
  return {
    warehouse: 'guid',
    warehouse_type: 'AUTH_WAREHOUSE',
    materials: [
      {
        name: 'P10 2019 主机',
        code: '01-001-00001',
        count: 1,
        hasCount: 4,
        is_single: 1,
        serial_numbers: ['801030113520977'],
        items: [
          {
            code: '01-001-01001',
            serial_numbers: [
              '101030113520901',
              '101030113520902',
              '101030113520903'
            ]
          }
        ]
      },
      {
        name: 'P20 2017 GPS天线',
        code: '01-003-00113',
        count: 1,
        hasCount: 4,
        is_single: 1,
        serial_numbers: ['801030113520977'],
        items: [
          {
            code: '01-001-00110',
            serial_numbers: [
              '801030113520901',
              '801030113520902',
              '801030113520903'
            ]
          },
          {
            code: '01-001-00112',
            serial_numbers: ['801030113520004', '801030113520005']
          }
        ]
      },
      {
        name: 'P30 2017 外壳',
        code: '01-004-00113',
        count: 10,
        hasCount: 7,
        is_single: 0,
        serial_numbers: [],
        items: [
          {
            code: '01-004-00110',
            count: 2
          },
          {
            code: '01-004-00112',
            count: 3
          }
        ]
      },
      {
        name: 'P30 2019 主机',
        code: '01-004-00118',
        count: 10,
        hasCount: 7,
        is_single: 0,
        serial_numbers: [],
        items: [
          {
            code: '01-004-00008',
            count: 2
          }
        ]
      }
    ],
    service_no: 'S1904029091',
    serial_number: 'PPP2019021806',
    type: 'P20_2017',
    model: 'UAV1',
    button: 'loss'
  }
}

register('/app/test/get/last/loss/material', getLastLossMaterial)

register('/app/operation/protection/service/loss/commit_for_as', function() {
  return {}
})

// register('/app/operation/protection/service/auth/pay', function() {
//   return {}
// })

register(
  '/app/member/protection/service/home/client_get_pay_order_info',
  function() {
    return {
      baseInfo: {
        guid: '920D9583D487875CD175252944C6D749',
        service_no: 'AR2008212769',
        status: 'END',
        offer_guid: 'offer_guid'
      },
      notice: '',
      responsibStatus: '非质保，已确认',
      materialList: [
        {
          guid: '434B9EA120775D108F7F2A9B0659457B',
          serial_number: '223790983',
          name: 'B12710',
          eq_mode: null,
          logo_url:
            'http://agri-private.static.xag.cn/v3/upload/2019/01/28/bfcc77c0413f680a06b3f8e4526c37ac.jpg?imageView2/0/w/600/h/600&e=1866027861&token=kL3qRYaFy4Ip8uZVbJyYE1KL6GgwtdNbqwu5C4lO:p0KViIeBhl1gQifrZfUZiJMDdRQ=',
          materials: [
            {
              guid: 'CAE96289056F122A153A207598C1DFF1',
              serial_number: '650318008',
              name: '左前脚架',
              code: '14-001-00030',
              price: 300,
              count: 1
            },
            {
              guid: '272C9678910898CEDAFC054F2405074C',
              serial_number: '',
              name: '电机壳防撞胶锁紧件',
              code: '02-001-02669',
              price: 5,
              count: 3
            },
            {
              guid: '0D04E813EE965A8007F00CF976F9CE3F',
              serial_number: '',
              name: '电机壳 防撞胶支架',
              code: '02-001-02668',
              price: 8,
              count: 1
            }
          ]
        },
        {
          guid: '434B9EA120775D108F7F2A9B0659457B',
          serial_number: '223790983',
          name: 'B12710',
          eq_mode: null,
          logo_url:
            'http://agri-private.static.xag.cn/v3/upload/2019/01/28/bfcc77c0413f680a06b3f8e4526c37ac.jpg?imageView2/0/w/600/h/600&e=1866027861&token=kL3qRYaFy4Ip8uZVbJyYE1KL6GgwtdNbqwu5C4lO:p0KViIeBhl1gQifrZfUZiJMDdRQ=',
          materials: [
            {
              guid: 'CAE96289056F122A153A207598C1DFF1',
              serial_number: '650318008',
              name: '左前脚架',
              code: '14-001-00030',
              price: 300,
              count: 1
            },
            {
              guid: '272C9678910898CEDAFC054F2405074C',
              serial_number: '',
              name: '电机壳防撞胶锁紧件',
              code: '02-001-02669',
              price: 5,
              count: 3
            },
            {
              guid: '0D04E813EE965A8007F00CF976F9CE3F',
              serial_number: '',
              name: '电机壳 防撞胶支架',
              code: '02-001-02668',
              price: 8,
              count: 1
            }
          ]
        }
      ],
      amountInfo: {
        materialCost: 323,
        hourlyWage: 100,
        isDisplay: true
      },
      payInfo: {
        payStatus: false,
        payBy: '738FA9632ADE0CF7C41F66BE36E67A4E',
        payTime: '2020-08-22',
        payType: 'ONLINE'
      }
    }
  }
)

register(
  '/app/operation/protection/service/loss/get_repair_equipment_info',
  function() {
    return {
      service_guid: '2B235D45234F708ED7CE49C2D130C6DE', // 服务单guid
      repair_equ_guid: '8FC5B1A43A2DD7E64734A39194D83FAB', // 维修设备guid
      name: 'B12710', // 设备名字
      eq_model: 'eq_model', // 设备类型
      serial_number: 'serial_number' // 设备序列号
    }
  }
)

register(
  '/app/member/protection/service/home/client_get_liability_list',
  function() {
    return [
      {
        type: '客户同意定责结果',
        user_guid: '0D505968-21E8-D6A6-B3B0-AFEF6FED171D',
        user_name: 'zhengxiaohuan',
        content: '我要申诉',
        images: [
          '1D483963EC521AF61E622BE29A34A826',
          'BA2457A1F24B2C2954F6306E8B745AC1',
          'EEE2F743ADE857EB972EA5F45FBE7193',
          'B76D17B778574F02E4600E6AF24C51D1'
        ],
        result: '',
        status: '分析完毕',
        time: '2020-08-11 13:44'
      },
      {
        type: '直属售后输出分析报告',
        user_guid: '0D505968-21E8-D6A6-B3B0-AFEF6FED171D',
        user_name: '小王',
        content: '人为造成的',
        images: [
          '1D483963EC521AF61E622BE29A34A826',
          'BA2457A1F24B2C2954F6306E8B745AC1'
        ],
        result: 'ARTIFICIAL',
        status: '分析完毕',
        time: '2020-08-11 14:43'
      },
      {
        type: '客户提出申诉',
        user_guid: '0D505968-21E8-D6A6-B3B0-AFEF6FED171D',
        user_name: 'zhengxiaohuan',
        content: '我要申诉',
        images: [
          '1D483963EC521AF61E622BE29A34A826',
          'BA2457A1F24B2C2954F6306E8B745AC1',
          'EEE2F743ADE857EB972EA5F45FBE7193',
          'B76D17B778574F02E4600E6AF24C51D1'
        ],
        result: '',
        status: '分析完毕',
        time: '2020-08-11 13:44'
      },
      {
        type: '直属售后输出分析报告',
        user_guid: '0D505968-21E8-D6A6-B3B0-AFEF6FED171D',
        user_name: '小王',
        content: '人为造成的',
        images: [
          '1D483963EC521AF61E622BE29A34A826',
          'BA2457A1F24B2C2954F6306E8B745AC1'
        ],
        result: 'ARTIFICIAL',
        status: '分析完毕',
        time: '2020-08-11 13:44'
      },
      {
        type: '客户提出申诉',
        user_guid: '0D505968-21E8-D6A6-B3B0-AFEF6FED171D',
        user_name: 'zhengxiaohuan',
        content: '我要申诉',
        images: [
          '1D483963EC521AF61E622BE29A34A826',
          'BA2457A1F24B2C2954F6306E8B745AC1',
          'EEE2F743ADE857EB972EA5F45FBE7193',
          'B76D17B778574F02E4600E6AF24C51D1'
        ],
        result: '',
        status: '分析完毕',
        time: '2020-08-11 13:42'
      },
      {
        type: '直属售后输出分析报告',
        user_guid: '0D505968-21E8-D6A6-B3B0-AFEF6FED171D',
        user_name: '小王',
        content: '人为造成的',
        images: [
          '1D483963EC521AF61E622BE29A34A826',
          'BA2457A1F24B2C2954F6306E8B745AC1'
        ],
        result: 'ARTIFICIAL',
        status: '分析完毕',
        time: '2020-08-11 13:42'
      },
      {
        type: '保障专员申请定责分析',
        user_guid: '0D505968-21E8-D6A6-B3B0-AFEF6FED171D',
        user_name: '王雨',
        content: '诊断描述1',
        images: [
          '1D483963EC521AF61E622BE29A34A826',
          'BA2457A1F24B2C2954F6306E8B745AC1',
          'EEE2F743ADE857EB972EA5F45FBE7193',
          'B76D17B778574F02E4600E6AF24C51D1'
        ],
        result: '',
        status: '已诊断',
        time: '2020-08-10 13:57'
      }
    ]
  }
)

register(
  '/app/operation/protection/service/auth/input_good_materials_info_list',
  function() {
    return {
      list: [
        {
          name: '锂电池',
          type: 'batch',
          code: '01-004-00093',
          sn: [],
          price: 1500,
          count: 1,
          image: '',
          code_list: [
            {
              code: '05-002-00716',
              count: 0,
              sn: []
            },
            {
              code: '01-004-00093',
              count: 1,
              sn: []
            }
          ]
        },
        {
          name: '电机壳防撞胶锁紧件',
          type: 'batch',
          code: '02-001-02669',
          sn: [],
          price: 5,
          count: 1,
          image: '',
          code_list: [
            {
              code: '02-001-02669',
              count: 1,
              sn: []
            }
          ]
        },
        {
          name: '左前脚架',
          type: 'single',
          code: '14-001-00030',
          sn: [
            // 定损坏件的物料编码
            '943613658',
            '943613659'
          ],
          price: 300,
          count: 2,
          image: '',
          code_list: [
            {
              code: '14-001-00030',
              count: 2,
              sn: [
                // 录入好件的物料编码
                '650318008',
                '650318018'
              ]
            },
            {
              code: '14-001-00031',
              count: 0,
              sn: ['650318448']
            }
          ]
        }
      ]
    }
  }
)

register(
  '/app/operation/protection/service/auth/input_single_good_materials',
  resultOk
)
register(
  '/app/operation/protection/service/auth/input_batch_good_materials',
  resultOk
)
register(
  '/app/operation/protection/service/auth/submit_input_good_materials',
  resultOk
)
