import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'

import {
  fetchSelectMaterialCount,
  fetchSelectMaterialSn
} from './select-material'

import commonRouter from './common'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Index
  },
  {
    path: '/message',
    name: 'message-index',
    component: () =>
      import(/* webpackChunkName: "Message" */ '../views/message/index.vue')
  },
  {
    path: '/store-message',
    name: 'store-message-index',
    component: () =>
      import(
        /* webpackChunkName: "StoreMessage" */ '../views/store-message/index.vue'
      )
  },
  {
    path: '/message/:id',
    name: 'message-detail',
    component: () =>
      import(/* webpackChunkName: "Message" */ '../views/message/detail.vue')
  },
  {
    path: '/ensure-diy-list',
    name: 'ensure-diy-list',
    component: () =>
      import(/* webpackChunkName: "EsDiyList" */ '../views/Ensure-Diy-List.vue')
  },
  {
    path: '/ensure-detail-list',
    name: 'ensure-detail-list',
    component: () =>
      import(
        /* webpackChunkName: "EsDetailList" */ '../views/ensure/Ensure-Detail-List.vue'
      )
  },
  {
    path: '/ensure-report-index',
    name: 'ensure-report-index',
    component: () =>
      import(
        /* webpackChunkName: "EsReport" */ '../views/ensure/Ensure-report-Index.vue'
      )
  },
  {
    path: '/ensure-detail',
    name: 'ensure-detail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "EnsureDetail" */ '../views/Ensure-Detail.vue'
      )
  },
  {
    path: '/ensure-close-form',
    name: 'ensure-close-form',
    // route level code-splitting
    // this generates a separate chunk (Steps.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "EsForm" */ '../views/Ensure-Close-Form.vue')
  },
  {
    path: '/ensure-loss-material-form',
    name: 'Ensure-Loss-Material-Form',
    component: () =>
      import(
        /* webpackChunkName: "EsForm" */ '../views/Ensure-Loss-Material-Form.vue'
      )
  },
  {
    path: '/ensure/loss-verify',
    name: 'Ensure-Loss-Verify', // 定损：提交定损描述以及定损照片页面
    component: () =>
      import(
        /* webpackChunkName: "EsForm" */ '../views/ensure/Ensure-Loss-Verify.vue'
      )
  },
  {
    path: '/ensure-steps',
    name: 'ensure-steps',
    component: () =>
      import(/* webpackChunkName: "Ensure-Steps" */ '../views/Ensure-Steps.vue')
  },
  {
    path: '/ensure-list',
    name: 'ensure-list',
    component: () =>
      import(/* webpackChunkName: "Ensure" */ '../views/Ensure-List.vue')
  },
  {
    path: '/authas/cost-bill',
    name: 'authas-cost-bill',
    component: () =>
      import(/* webpackChunkName: "Authas" */ '../views/authas/Cost-Bill.vue')
  },
  {
    path: '/authas/diagnosis',
    name: 'authas-diagnosis',
    component: () =>
      import(/* webpackChunkName: "Authas" */ '../views/authas/diagnosis.vue')
  },
  {
    path: '/authas/diagnosis-detail',
    name: 'authas-diagnosis-detail',
    component: () =>
      import(
        /* webpackChunkName: "EnsureDetail" */ '../views/authas/Diagnosis-Detail.vue'
      )
  },
  {
    path: '/authas/detection-list',
    name: 'authas-detection-list',
    component: () =>
      import(
        /* webpackChunkName: "EnsureDetail" */ '../views/authas/Detection-List.vue'
      )
  },
  {
    path: '/authas/network-feedback',
    name: 'authas-network-feedback',
    component: () =>
      import(
        /* webpackChunkName: "EnsureDetail" */ '../views/authas/Network-Feedback.vue'
      )
  },
  {
    path: '/ensure-loss-material',
    name: 'ensure-loss-material',
    component: () =>
      import(
        /* webpackChunkName: "EsMaterial" */ '../views/select-material/Router.vue'
      ),
    children: [
      {
        path: '',
        component: () =>
          import(
            /* webpackChunkName: "EsMaterial" */ '../views/select-material/Ensure-Loss-Material.vue'
          )
      },
      {
        path: 'select-count',
        name: 'material-select-count',
        component: fetchSelectMaterialCount
      },
      {
        path: 'select-sn',
        name: 'material-select-sn',
        component: fetchSelectMaterialSn
      },
      {
        path: 'loss-img-desc',
        name: 'loss-image-desc',
        component: () =>
          import(
            /* webpackChunkName: "EsLossImgDesc" */ '../views/ensure/Loss-Image.vue'
          )
      }
    ]
  },
  {
    path: '/ensure-diagnostic-audit',
    name: 'ensure-diagnostic-audit',
    component: () =>
      import(
        /* webpackChunkName: "EnsureDetail" */ '../views/Eusure-Diagnostic-Audit.vue'
      )
  },
  {
    path: '/ensure-repair-pick-list',
    name: 'ensure-repair-pick-list',
    component: () =>
      import(
        /* webpackChunkName: "EnsureDetail" */ '../views/Ensure-Repair-Pick-List.vue'
      )
  },
  {
    path: '/ensure-select-material-loss',
    name: 'Ensure-Select-Material-Loss',
    component: () =>
      import(
        /* webpackChunkName: "EsMaterial" */ '../views/Ensure-Select-Material-Loss.vue'
      )
  },
  {
    path: '/ensure-select-protectors',
    name: 'Ensure-Select-Protectors',
    children: [
      {
        path: '',
        component: () =>
          import(
            /* webpackChunkName: "SelectProtectors" */ '../views/select-protectors/Select-protector.vue'
          )
      },
      {
        path: 'warehouse',
        name: 'select-warehouse',
        component: () =>
          import(
            /* webpackChunkName: "SelectProtectors" */ '../views/select-protectors/Select-warehouse.vue'
          )
      }
    ],
    component: () =>
      import(
        /* webpackChunkName: "SelectProtectors" */ '../views/select-protectors/Router.vue'
      )
  },
  {
    path: '/authas/input-good-part',
    name: 'Input-Good-Part',
    component: () =>
      import(
        /* webpackChunkName: "InputGoodPart" */ '../views/authas/Input-Good-Part.vue'
      )
  },
  {
    path: '/material-apply-list',
    name: 'Return-Material-Apply',
    component: () =>
      import(
        /* webpackChunkName: "ReturnMaterial" */ '../views/return-material/Material-Apply-List.vue'
      )
  },
  {
    path: '/material-select',
    name: 'Return-Material-Select',
    component: () =>
      import(
        /* webpackChunkName: "ReturnMaterial" */ '../views/return-material/Material-Select.vue'
      )
  },
  {
    path: '/material-select-multi',
    name: 'Return-Material-Select-Multi',
    component: () =>
      import(
        /* webpackChunkName: "ReturnMaterial" */ '../views/return-material/Material-Select-Multi.vue'
      )
  },
  {
    path: '/logistics-edit',
    name: 'Logistics-Edit',
    component: () =>
      import(/* webpackChunkName: "logistics" */ '../views/logistics/edit.vue')
  },
  {
    path: '/test/app',
    name: 'test-app',
    component: () => import('@/views/test/App.vue')
  },
  {
    path: '/serve/data-service',
    name: 'data-service',
    component: () => import('@/views/serve/Data-Service.vue')
  },
  {
    path: '/serve/xpilot-qa/:type?/:lang?',
    component: () => import('@/views/serve/XPilot-Qa.vue')
  },
  ...commonRouter
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    // return 期望滚动到哪个的位置
    if (savedPosition) {
      return savedPosition
    }
  }
})

export default router
