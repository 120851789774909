<template>
  <button class="xa-button" :disabled="disabled" :class="clacClass">
    <slot>
      <span>{{ text }}</span>
    </slot>
  </button>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: 'default',
      validator(value) {
        // 这个值必须匹配下列字符串中的一个
        return (
          ['default', 'primary', 'info', 'warning', 'danger'].indexOf(value) !==
          -1
        )
      }
    },
    plain: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    text: String,
    size: {
      type: String,
      validator(value) {
        // 这个值必须匹配下列字符串中的一个
        return ['large', 'normal', 'small', 'mini'].indexOf(value) !== -1
      }
    }
  },
  computed: {
    clacClass() {
      let clacClass = []
      if (this.plain) {
        clacClass.push(`xa-bg-white`)
        clacClass.push(`xa-color-${this.type}`)
        clacClass.push(`xa-button--plain`)
      } else {
        clacClass.push(`xa-txt-white`)
        clacClass.push(`xa-bg-${this.type}`)
      }
      clacClass.push(`xa-button--${this.size}`)
      if (this.disabled) clacClass.push(`xa-button--disabled`)
      return clacClass
    }
  }
}
</script>

<style lang="scss" scoped>
.xa-button {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  height: 44px;
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 42px;
  text-align: center;
  border: 1px solid;
  border-radius: 2px;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  -webkit-appearance: none;
  -webkit-text-size-adjust: 100%;
  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background-color: #000;
    border: inherit;
    border-color: #000;
    border-radius: inherit;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 0;
    content: ' ';
  }
  &:active::before {
    opacity: 0.1;
  }
  & + & {
    margin-left: 16px;
  }
  &--plain {
    border-color: transparent;
  }
  &--disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  &--normal {
    padding: 0 15px;
    font-size: 14px;
  }
  &--small {
    min-width: 60px;
    height: 30px;
    padding: 0 8px;
    font-size: 12px;
    line-height: 28px;
  }
  &--mini {
    display: inline-block;
    min-width: 50px;
    height: 22px;
    font-size: 10px;
    line-height: 20px;
  }
}
</style>
