/* eslint-disable constructor-super */
export const isApp =
  navigator.userAgent.toLowerCase().indexOf('xag_store_fault') > -1
import { setGlobelHeader } from '@/controllers/request'
class Observer {
  constructor() {
    this.subscribers = {}
  }
  addSubscriber(event, fn) {
    let subs = this.subscribers[event] || (this.subscribers[event] = [])
    subs.push(fn)
  }
  clearSubscriber(event) {
    this.subscribers[event] = []
  }
  removeSubscriber(event, fn) {
    let subs = this.subscribers[event]
    if (subs === undefined || subs.length === 0) return
    subs.splice(subs.indexOf(fn), 1)
  }
  public(event) {
    let subs = Array.from(this.subscribers[event])
    while (subs.length) {
      typeof subs[0] === 'function' &&
        subs[0].apply(null, Array.from(arguments).slice(1))
      subs.pop()
    }
  }
}

function parseQuery(query) {
  const res = {}
  query = query.trim().replace(/^(\?|#|&)/, '')
  if (!query) {
    return res
  }
  query.split('&').forEach(param => {
    const parts = param.replace(/\+/g, ' ').split('=')
    const key = decodeURIComponent(parts.shift())
    const val = parts.length > 0 ? decodeURIComponent(parts.join('=')) : null

    if (res[key] === undefined) {
      res[key] = val
    } else if (Array.isArray(res[key])) {
      res[key].push(val)
    } else {
      res[key] = [res[key], val]
    }
  })
  return res
}
class App extends Observer {
  constructor() {
    super()
    if (isApp) {
      this._registerApp()
      this._accessTokenCompletedResolve = null
      this._appScanCompletedResolve = null
      setGlobelHeader('xa-app', 'auth_warehouse')
    }
  }
  _registerApp() {
    window.onAppOpenMenuBtnsCompleted = data => {
      this._onAppOpenMenuBtnsCompleted(this.handdleResult(data))
    }
    /**
     * 存在两个调用情况
     * 1 消费者调用toGetAccessToken方法，这时会设置_accessTokenCompletedResolve承诺
     * 2 app端在发生用户token失效，然后弹窗重新登录后，主动通知webview，发生了用户信息更新
     */
    window.onAccessTokenCompleted = result => {
      if (this._accessTokenCompletedResolve) {
        this._accessTokenCompletedResolve(this.handdleResult(result))
        this._accessTokenCompletedResolve = null
      } else {
        this.public('onAccessTokenCompleted', this.handdleResult(result))
      }
    }
    window.onAppScanCompleted = result => {
      const { status_code, data } = this.handdleResult(result)
      if (parseInt(status_code) === 200)
        this._appScanCompletedResolve(data.qrCode)
    }
  }
  handdleResult(result) {
    if (typeof result === 'string') {
      return JSON.parse(result)
    }
    return result
  }
  /**
   * 获取app端的用户信息
   *
   * 里面的信息有
   * https://doc.cloud.xair.cn/project/64/interface/api/6107
   * https://doc.cloud.xair.cn/project/64/interface/api/3633
   */
  toGetAccessToken() {
    return new Promise(resolve => {
      this._accessTokenCompletedResolve = resolve
      window.android.getAccessToken('')
    })
  }
  // 调用扫描二维码功能
  toScanQRCode() {
    return new Promise(resolve => {
      this._appScanCompletedResolve = resolve
      window.android.scan('')
    })
  }
  // 调用连续扫码功能
  toScanMaterial({ title, url, params, paramsKey }) {
    var vm = this
    return new Promise(resolve => {
      window.onAppScanMaterialCompleted = function(result) {
        const { status_code, data } = vm.handdleResult(result)
        if (parseInt(status_code) === 200) {
          resolve(data.materials)
        }
      }
      window.android.scanMaterial(
        JSON.stringify({
          title,
          url,
          params,
          paramsKey
        })
      )
    })
  }
  // 设置App右上角的菜单按钮
  setAppMenuBtns(btn) {
    if (btn) {
      window.android.setMenuBtns(
        JSON.stringify({
          btn
        })
      )
    } else {
      window.android.setMenuBtns('')
    }
  }
  // 私有的
  _onAppOpenMenuBtnsCompleted(result) {
    result = this.handdleResult(result)
    const { status_code, data } = result
    if (parseInt(status_code) === 200) {
      this.public('onAppOpenMenuBtnsCompleted', data.btn)
    }
  }
  open(href) {
    window.android.open(href)
  }
  push(href) {
    href = href.replace('app://', '')
    const key = href.match(/[\w]+/)[0]
    const params = parseQuery(href.replace(key, ''))
    window.android.push(
      JSON.stringify({
        key,
        params
      })
    )
  }
}

export default new App(window)
