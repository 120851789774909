import { register } from './service'

function getLossInfo() {
  return {
    warehouse: 'guid',
    warehouse_type: 'AUTH_WAREHOUSE',
    materials: [
      {
        name: 'P20 2017 GPS天线',
        code: '01-003-00113',
        count: 1,
        hasCount: 4,
        is_single: 1,
        serial_numbers: ['801030113520977'],
        loss_mate_guid: 'loss_mate_guid-1',
        images: [
          'CAFED0CAEFF82B6E7B84B2B626C9A2E9',
          'EA1976DC06ACFC7B2F58003BE8283AB7'
        ]
      },
      {
        name: 'P30 2017 外壳',
        code: '01-004-00113',
        count: 10,
        hasCount: 7,
        is_single: 0,
        serial_numbers: [],
        loss_mate_guid: 'loss_mate_guid-2',
        images: []
      }
    ],
    service_no: 'S1904029091',
    serial_number: 'PPP2019021806',
    type: 'P20_2017',
    model: 'UAV1',
    button: 'loss',
    category: 'P_SERIAL'
  }
}

function deleteSn() {
  return {}
}

function editCode() {
  return {}
}

function getUavMaterialSn() {
  return [
    {
      code: '05-002-00211',
      name: '螺旋桨（正）',
      count: 2,
      is_single: 1,
      serial_numbers: ['14711698', '88801498']
    },
    {
      code: '05-002-00351',
      name: 'P20 2018款碳包铝粘合铝件长管半成品',
      count: 2,
      is_single: 1,
      serial_numbers: ['08368376', '50140257']
    },
    {
      code: '14-001-00012',
      name: '快拆桨座(正)',
      count: 2,
      is_single: 1,
      serial_numbers: ['felsvvwf', 'ux50kgf9']
    },
    {
      code: '14-001-00026',
      name: '短机臂',
      count: 2,
      is_single: 1,
      serial_numbers: ['12286712', '95755138']
    },
    {
      code: '14-001-00027',
      name: '长机臂',
      count: 4,
      is_single: 1,
      serial_numbers: ['sn-1261', '96782925', '84074633', '76022784']
    },
    {
      code: '14-001-00133',
      name: '农业机32英寸折叠桨2017升级版正桨备件',
      count: 2,
      is_single: 1,
      serial_numbers: ['78814225', '00250518']
    },
    {
      code: '14-001-00134',
      name: '农业机32英寸折叠桨2017升级版反桨备件',
      count: 2,
      is_single: 1,
      serial_numbers: ['25077372', '17191954']
    }
  ]
}

function saveLossSn() {
  return {}
}

function checkMaterialInfo() {
  return [
    {
      code: '14-001-00039',
      name: '2合1/3合1模块承载件',
      serial_number: '579130244',
      type: 'BAD',
      warehouse_type: null,
      warehouse: '',
      group_no: 'D3C20BD271C9F6BAFCD64303E4ADD6BE',
      group_name: '测试单件可互换类型'
    },
    {
      code: '09-020-00001',
      name: '19P050油动储能发电机成品',
      serial_number: '579130244',
      type: 'BAD',
      warehouse_type: null,
      warehouse: '',
      group_no: 'D3C20BD271C9F6BAFCD64303E4ADD6BE',
      group_name: '测试单件可互换类型'
    }
  ]
}

function getMaterialTypsByGuid() {
  return [
    {
      name: 'name',
      key: 'key'
    },
    {
      name: 'name1',
      key: 'key1'
    },
    {
      name: 'name2',
      key: 'key3'
    }
  ]
}

function getMaterialListByGuid() {
  return [
    {
      code: '05-002-00351',
      name: 'P20 2018机臂（长）',
      price: 100,
      man_hour: 0,
      interchange_type: '',
      is_single: '1',
      image: '',
      source_image: ['?imageView2/1/w/750/h/750'],
      stock_count: 2
    },
    {
      code: '05-002-00454',
      name: 'P20 2018RTK模块',
      price: 12,
      man_hour: 0,
      interchange_type: '',
      is_single: '1',
      image: null,
      source_image: ['?imageView2/1/w/750/h/750'],
      stock_count: 1
    }
  ]
}

register('/app/operation/protection/service/loss/info', getLossInfo)
register('/app/operation/protection/service/loss/delete_sn', deleteSn)
register('/app/operation/protection/service/loss/edit_code', editCode)
register(
  '/app/operation/protection/service/loss/scan_code_info',
  checkMaterialInfo
)
register('/app/operation/protection/service/loss/save_sn', saveLossSn)
register('/app/member/equipment/home/uav_materials', getUavMaterialSn)
register(
  '/app/operation/protection/service/loss/get_select_types',
  getMaterialTypsByGuid
)
register(
  '/app/operation/protection/service/loss/get_select_materials',
  getMaterialListByGuid
)

register(
  '/app/operation/protection/service/loss/save_loss_mate_images',
  function() {
    return {}
  }
)

register('/app/operation/protection/service/view/l_detail', function() {
  return {
    title: 'test:故障单号S2009191726',
    actions: [],
    body: [
      {
        title: '',
        subTitle: '',
        type: 'esInfo',
        items: [
          {
            title: '状态',
            value: '[已定损](#ff7700)'
          },
          {
            title: '提交时间',
            value: '2020-09-19 22:15:04'
          }
        ]
      },
      {
        title: '',
        subTitle: '',
        type: 'esInfo',
        items: [
          {
            title: '定损描述：',
            value: '定损信息'
          },
          {
            title: '图片：',
            images: ['EE0313BB20ABD0E331BEAE69EE512A16']
          }
        ]
      },
      {
        title: '物料列表',
        type: 'esMList',
        items: [
          {
            serial_numbers: [],
            image: '',
            count: 1,
            name: '农业机32英寸CCW桨-分桨',
            code: '14-001-00198'
          },
          {
            serial_numbers: [],
            image: '',
            count: 1,
            name: '农业机32英寸CW桨-分桨',
            code: '14-001-00197',
            images: [
              'CAFED0CAEFF82B6E7B84B2B626C9A2E9',
              'EA1976DC06ACFC7B2F58003BE8283AB7',
              'EE0313BB20ABD0E331BEAE69EE512A16',
              'F1A3B2316A7D2C85A103332E2886C0B5',
              '086716B0D563D556AD81156F56266086',
              '2644EA65F9DE15CBEC9F35EAEBA87DB2',
              '49924A73118931C3AFFACD4B78923C14',
              'B19D9C84C2A2126B2A56A7154D85C9CC'
            ]
          }
        ]
      }
    ]
  }
})
