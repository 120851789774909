<template>
  <div class="material-radio-del">
    <div v-for="(sn, index) in items" :key="sn">
      <Tag plain type="primary" closeable @close="$emit('delete', sn, index)">{{
        sn
      }}</Tag>
    </div>
  </div>
</template>
<script>
import { Tag } from 'vant'
export default {
  components: {
    Tag,
  },
  props: {
    items: {},
  },
}
</script>
<style lang="scss">
.material-radio-del {
  padding: 10px 0px 0px 15px;
  display: flex;
  flex-wrap: wrap;
  background-color: $default-page-color--light;
  .van-tag {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
</style>
