import { request } from './util'
import { register } from './service'
import EnsureInfo from './components/EnsureInfo'
import EnsureStep from './components/EnsureStep'
import EnsureMaterial, {
  getTypeMaterialList
} from './components/EnsureMaterial'
import EnsureFootBtn from './components/EnsureFootBtn'
import EnsurePayInfo from './components/EnsurePayInfo'
import EnsureLogistics from './components/EnsureLogistics'
const database = {
  esStep: {
    type: 'esStep',
    title: 'esStep',
    subTitle: '[展示步骤(按钮，跳转)](#008800)',
    items: EnsureStep().items
  },
  esCells: {
    type: 'esCells',
    title: 'esCells',
    items: EnsureStep().items
  },
  esInfo: {
    type: 'esInfo',
    title: 'esInfo',
    subTitle: '[展示信息、图片](#228800)',
    ...EnsureInfo()
  },
  esPayInfo: {
    type: 'esPayInfo',
    ...EnsurePayInfo(),
    title: 'esPayInfo'
  },
  esLogistics: {
    type: 'esLogistics',
    title: 'esLogistics',
    subTitle: '[展示物流信息](#228800)',
    ...EnsureLogistics()
  },
  esMList: {
    type: 'esMList',
    ...EnsureMaterial({ count: 2, type: 'price' })
  },
  esMTList: {
    type: 'esMTList',
    title: 'esMTList',
    items: getTypeMaterialList()
  }
}
const mockUrl = '/mock/ensure-detail'
function mock(params = {}) {
  const { type } = params
  if (database[type]) {
    return {
      title: type,
      body: [database[type]]
    }
  }
  return request(params, {
    title: '诊断审核',
    actions: EnsureFootBtn(),
    actionsPosition: 'APP_MENU',
    body: [
      database.esLogistics,
      database.esInfo,
      {
        type: 'esCells',
        title: 'esCells',
        items: EnsureStep().items.slice(0, 3)
      },
      database.esStep,
      {
        type: 'esInfo',
        title: 'esInfo',
        ...EnsureInfo('Plan')
      },
      {
        type: 'esMList',
        subTitle: '[展示物料(支持单件/批量)](#228800)',
        ...EnsureMaterial()
      }
    ]
  })
}

register(mockUrl, mock)

register('/mock/Ensure-Repair-Pick-List', function mock() {
  return {
    title: '诊断-维修领料单',
    actions: EnsureFootBtn(),
    body: [
      {
        type: 'esInfo',
        title: 'esInfo',
        ...EnsureInfo('payInfo')
      },
      {
        type: 'esMList',
        ...EnsureMaterial({
          type: 'price'
        }),
        title: '物料列表'
      },
      {
        type: 'esPayInfo',
        ...EnsurePayInfo(),
        title: '线上支付',
        space: false
      },
      {
        type: 'esPayInfo',
        ...EnsurePayInfo('UNDERLINE'),
        title: '线下支付'
      }
    ]
  }
})

register('/mock/eusure-diagnostic-audit', function mock(params) {
  return request(params, {
    title: '诊断审核',
    body: [
      {
        type: 'esInfo',
        title: 'esInfo',
        ...EnsureInfo('Plan')
      },
      {
        type: 'esMList',
        ...EnsureMaterial()
      }
    ]
  })
})

// 目录
register(mockUrl + '/index', function() {
  function getTypeHref(type) {
    return `/ensure-detail?src=${mockUrl}&params={"type":"${type}"}`
  }
  return {
    title: '保障详情模板',
    body: [
      {
        ...database.esStep,
        href: getTypeHref('esStep')
      },
      {
        ...database.esCells,
        href: getTypeHref('esCells')
      },
      {
        ...database.esInfo,
        href: getTypeHref('esInfo')
      },
      {
        ...database.esPayInfo,
        href: getTypeHref('esPayInfo')
      },
      {
        ...database.esLogistics,
        href: getTypeHref('esLogistics')
      },
      {
        ...database.esMList,
        href: getTypeHref('esMList')
      },
      {
        ...database.esMTList,
        href: getTypeHref('esMTList')
      }
    ]
  }
})
