import * as UUID from 'uuidjs'

function post(url, data) {
  return {
    url: url,
    method: 'post',
    data
  }
}

export function getQiniuUploadCfg({ file, token }, cb) {
  const uuid = UUID.generate()
  const suffix = file.name.substring(file.name.lastIndexOf('.'))
  const key = 'xservice/protection/service/' + uuid + suffix
  const formData = new window.FormData()
  formData.append('file', file)
  formData.append('token', token)
  formData.append('key', key)
  return {
    method: 'post',
    url: '//upload.qiniup.com',
    data: formData,
    onUploadProgress: function(progressEvent) {
      cb && cb(progressEvent)
      // Do whatever you want with the native progress event
    }
  }
}

export function getUploadToken() {
  return {
    url: '/resource/home/upload_token',
    method: 'get'
  }
}

export function PostQiniuKey2Guid(keys) {
  return {
    method: 'post',
    url: '/resource/home/qiniu_key_convert',
    data: {
      keys: Array.isArray(keys) ? JSON.stringify(keys) : JSON.stringify([keys])
    }
  }
}

export function postQiniuKey2Path(qiniukey) {
  return post('/resource/home/get_resource_urls', {
    res_uids: Array.isArray(qiniukey) ? qiniukey : [qiniukey],
    width: 100,
    height: 100
  })
}

export function uploadFile(files, progressCb) {
  const formData = new window.FormData()
  files.forEach(file => formData.append('file_base[]', file))
  return {
    method: 'post',
    url: '/resource/home/upload',
    data: formData,
    onUploadProgress: function(progressEvent) {
      progressCb && progressCb(progressEvent)
      // Do whatever you want with the native progress event
    }
  }
}

export function uploadBase64() {}
