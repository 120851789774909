import axios from 'axios'
import qs from 'qs'

const gloableHeaderConfig = {}
const redirectToLoginStatus = [8888, 4304, 401, 4303]
const instance = axios.create({
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    token: ''
  }
})

instance.interceptors.request.use(
  function(config) {
    const commonData = {
      fromUrl: window.location.href
    }
    // Do something before request is sent
    if (config.method.toLowerCase() === 'get') {
      // 如果是get请求，但是配置了data参数，此时应该设置为params参数
      config.params = config.params || config.data || {}
      Object.assign(config.params, commonData)
    } else if (config.method.toLowerCase() === 'post') {
      Object.assign(config.data || {}, commonData)
      if (config.data instanceof window.FormData === false) {
        config.data = qs.stringify(config.data)
      }
    }
    Object.assign(config.headers, gloableHeaderConfig)
    return config
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// 嗅探是不是返回的body里面，再使用status,message,data包裹一层的接口
function isEmbeddedData(data) {
  return 'status' in data && 'message' in data && 'data' in data
}

function getResponseData(data) {
  return isEmbeddedData(data) ? data.data : data
}

function getErrorResponse(data) {
  if ('status' in data && parseInt(data.status) !== 200) {
    if (redirectToLoginStatus.includes(data.status)) {
      window.location.href = data.data
    } else {
      handleErrorResponse(data)
    }
  }
  return data
}

function handleErrorResponse(data) {
  throw data
}

instance.interceptors.response.use(
  function(response) {
    // Do something with response data
    getErrorResponse(response.data)
    return getResponseData(response.data)
  },
  function(error) {
    // Do something with response error
    // 解决错误信息为空的情况
    error.response.data = error.response.data || {
      message: `${error.response.status} : ${error.response.statusText}`
    }
    return Promise.reject(error.response.data)
  }
)

/**
 * 从url上提取target对应的值
 * eg:
 * url:/#/store-message?mini=member&token=4e5dc929e205f035f0186c624fab8dff
 * target: token
 * return: 4e5dc929e205f035f0186c624fab8dff
 * @param {*} url
 * @param {*} target
 */
export function getTargetValueFromUrl(url, target) {
  const matchReg = new RegExp(`[&|/?]${target}=([\\w]+)`)
  let targetStr = url.match(matchReg)
  if (targetStr && targetStr.length === 2) return targetStr[1]
  return ''
}

/**
 * 提取url上特定的参数，并注入到全局的headers里面去
 * @param {*} url
 * @param {*} target
 */
export function setGlobelHeaderByUrl(url, target) {
  const targetValue = getTargetValueFromUrl(url, target)
  if (targetValue && targetValue.length > 24)
    setGlobelHeader(target, targetValue)
}

/**
 *
 * @param {*} key
 * @param {*} value
 */
export function setGlobelHeader(key, value) {
  gloableHeaderConfig[key] = value
}

export const request = axios
export default instance
