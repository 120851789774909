function getRenderHref(text, href) {
  return `[${text}](${href})`
}

function getRenderText(text, color) {
  return `[${text}](${color})`
}

export default function(type) {
  if (type === 'Plan') {
    return getPlanContent()
  } else if (type === 'payInfo') {
    return getPayInfo()
  }
  return {
    items: [
      {
        title: '飞机信息',
        value: 'P30 2019款标准版 | 8038237824782'
      },
      {
        title: '故障描述',
        value: '飞行途中突然炸鸡，现在起飞不了'
      },
      {
        title: '故障相片',
        images: [
          'A21FA76FF56847D4910E33A9E3F0C1EE',
          '031429E4C388595C4D16F96AA3B87669'
        ]
      },
      {
        title: '故障日志',
        value: `2019年3月23日13:09 ${getRenderHref(
          '故障分析报告',
          'https://www.baidu.com'
        )}`
      },
      {
        title: '故障提单人',
        value: '刘波'
      },
      {
        title: '故障内容',
        value:
          '2020-01-09 15:49:39[故障分析报告](/pm/#!/ensure/report?src=/app/dev/service/technical_pdf_report&params={%22guid%22%3A%22DD2583A2CED2D83B40F782C3B44F4454%22}) | 故障分析 [待客户确认](#ff7700)'
      },
      {
        title: '状态',
        value: `${getRenderText('待提交', '#FF7700')}`
      }
    ]
  }
}

function getPayInfo() {
  return {
    items: [
      {
        title: '领料单号',
        value: '874382749234'
      },
      {
        title: '领料状态',
        value: `${getRenderText('待领料', '#FF7700')}`
      },
      {
        title: '领料地址',
        value: '大岭山镇连平村莞长路计岭力桑'
      }
    ]
  }
}

function getPlanContent() {
  return {
    items: [
      {
        title: '提单人',
        value: '刘志勇 15915797485'
      },
      {
        title: '提单时间',
        value: '2019/12/12'
      },
      {
        title: '保障单号',
        value: '234324324'
      }
    ]
  }
}
