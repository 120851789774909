function buildBtn({ title, href, ajax = {}, items }) {
  return {
    title,
    href,
    ajax,
    items
  }
}

function buildAjaxLoginBtn(title) {
  return buildBtn({
    title,
    ajax: {
      url: `/home/login`,
      data: {
        username: 'zhengxiaohuan',
        password: '123456'
      }
    }
  })
}

export default function() {
  return [
    buildBtn({
      title: '按钮1',
      href: 'https://www.baidu.com'
    }),
    buildBtn({
      title: '登录',
      ajax: {
        url: `/home/login`,
        data: {
          username: 'zhengxiaohuan',
          password: '123456'
        }
      }
    }),
    buildBtn({
      title: '按钮2',
      href: 'https://www.baidu.com'
    }),
    buildBtn({
      title: '更多啦',
      items: [
        buildBtn({
          title: '更多按钮1',
          items: [buildAjaxLoginBtn('登录1'), buildAjaxLoginBtn('登录2')]
        }),
        buildBtn({
          title: '更多按钮2',
          href: 'https://www.baidu.com'
        })
      ]
    })
  ]
}
