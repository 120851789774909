import wx from 'weixin-js-sdk'
export const isMini = window.__wxjs_environment === 'miniprogram'

class App {
  push(href) {
    href = href.replace('mini://', '')
    href = href.replace('app://', '')
    wx.checkJsApi({
      jsApiList: ['miniProgram'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
      success() {
        // 以键值对的形式返回，可用的api值true，不可用为false
        // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
        // window.alert(JSON.stringify(res))
      }
    })
    try {
      wx.miniProgram.navigateTo({
        url: href,
        fail(res) {
          window.alert(res)
        }
      })
    } catch (error) {
      window.alert(error)
    }
  }
}

export default new App()
