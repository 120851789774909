/**
 * @description 设置文档标题
 * @param {*} title
 */
export function setDocTitle(title) {
  document.title = document.getElementsByTagName('title')[0].innerText = title
  if (/ip(hone|od|ad)/i.test(navigator.userAgent)) {
    var iframe = document.createElement('iframe')
    iframe.style.display = 'none'
    iframe.src = './favicon.ico'
    iframe.onload = () => {
      document.body.removeChild(iframe)
    }
    document.body.appendChild(iframe)
  }
}
