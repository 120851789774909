export default function(len = 2) {
  return [
    {
      txt: '转让',
      href: '/ensure-select-protectors?params={"guid":"727E217F81F104D64BFBBDDEA8D31975"}',
    },
    {
      txt: '接单',
      ajax: {
        url: '/home/login',
        data: {
          username: 'zhengxiaohuan',
          password: '123456',
        },
        method: 'get',
        message: '确定要接单',
      },
    },
  ].slice(0, len)
}
