<template>
  <div
    class="xa-bg-white"
    :class="{ 'xa-com__space': space, 'ensure-panel__line': !space }"
  >
    <div
      v-if="title"
      class="ensure-panel__head xa-line__bottom xa-cell"
      @click="$emit('action', { href: href })"
    >
      <div class="xa-flex xa-cell head__box">
        <div class="xa-flex head__title">{{ title }}</div>
        <MarkdownLoader
          v-if="subTitle"
          :value="subTitle"
          class="xa-txt-12 xa-txt-secondary"
        />
      </div>
      <div v-if="href" class="xa-arrow"></div>
    </div>
    <slot> </slot>
  </div>
</template>
<script>
import MarkdownLoader from '@/components/MarkdownLoader'
export default {
  components: {
    MarkdownLoader
  },
  inheritAttrs: false,
  props: {
    title: String,
    subTitle: String,
    href: String,
    space: {
      type: Boolean,
      default: true
    }
  }
}
</script>
<style lang="scss">
.ensure-panel__head {
  position: relative;
  padding: 5px 15px;
}
.head__box {
  flex-wrap: wrap;
}
.head__title {
  font-size: 15px;
  font-weight: bold;
  line-height: 1.6;
  white-space: nowrap;
}
.ensure-panel__line {
  position: relative;
  &::after {
    content: ' ';
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 1px;
    transform: scaleY(0.5);
    background-color: #a5a5a5;
  }
}
</style>
