<template>
  <div
    class="template-detail"
    :class="{ 'has-foot': actions && actions.length && !stickyFoot }"
  >
    <EnsurePanel
      v-for="(com, index) in coms"
      :key="index"
      v-bind="com"
      v-on="$listeners"
    >
      <component :is="com.type" v-bind="com" v-on="$listeners"></component>
    </EnsurePanel>
    <EnsureFootBtns
      v-if="actions.length"
      :items="actions"
      v-on="$listeners"
      :sticky="stickyFoot"
    />
  </div>
</template>
<script>
import EnsurePanel from '@/components/EnsurePanel'
import EnsureFootBtns from '@/components/diyDetial/EnsureFootBtns'
import esStep from '@/components/EnsureStep'
import esInfo from '@/components/EnsureInfo'
import esPayInfo from '@/components/EnsurePayInfo'
import esCells from '@/components/EnsureCells'
import esLogistics from '@/components/EnsureLogistics'
import esMList from '@/components/material/MaterialReadList'
import esMTList from '@/components/material/MaterialTypeList'
export default {
  components: {
    EnsureFootBtns,
    EnsurePanel,
    esCells,
    esInfo,
    esMList,
    esMTList,
    esPayInfo,
    esStep,
    esLogistics
  },
  data() {
    return {
      cellsCfg: {}
    }
  },
  props: {
    stickyFoot: {
      type: Boolean,
      default: false
    },
    coms: {
      type: Array,
      default() {
        return []
      }
    },
    actions: {
      type: Array,
      default() {
        return []
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.template-detail {
  &.has-foot {
    padding-bottom: 58px;
    min-height: 100vh;
  }
}
</style>
