export default class {
  constructor(config) {
    this.config = config
  }
  toRightAjaxFormat(ajaxConfig) {
    return {
      ...ajaxConfig,
      url: ajaxConfig.url || ajaxConfig.api,
      data: ajaxConfig.data || ajaxConfig.params,
      method: ajaxConfig.method || 'get',
      message: ajaxConfig.message || ajaxConfig.confirm,
    }
  }
  out() {
    return {
      ...this.config,
      ajax: this.config.ajax ? this.toRightAjaxFormat(this.config.ajax) : null,
    }
  }
}
