/* eslint-disable no-debugger */
import { register } from '../service'

function getProtectorsByGuid() {
  return [
    {
      name: '广州南洋第一仓呢',
      guid: '245DCD9F4185360CEDF7CA755FE97C27',
      p_name: '广州极飞阿克苏仓',
      p_guid: '0D3B830597F984F6E052EC24F0674A48'
    },
    {
      name: 'wd1',
      guid: '3E823C2AF53167B48960326FADE154DF',
      p_name: '广州极飞阿克苏仓',
      p_guid: '0D3B830597F984F6E052EC24F0674A48'
    },
    {
      name: '233333',
      guid: 'D907D66B52058D05EF1D6A520D499878',
      p_name: '广州极飞阿克苏仓',
      p_guid: '0D3B830597F984F6E052EC24F0674A48'
    },
    {
      name: 'who',
      guid: 'FDB8BAF788BF84EF593A27309A4968B',
      p_name: '广州极飞阿克苏仓1',
      p_guid: '0D3B830597F984F6E052EC24F0674A48'
    },
    {
      name: '辜奕敏xag网点',
      guid: '44CCF2086777470C431DBC974CED235',
      p_name: '广州极飞阿克苏仓',
      p_guid: '0D3B830597F984F6E052EC24F0674A48'
    },
    {
      name: 'test0731',
      guid: 'F81DE69DBEED3F97F4B6FCD6B2C29B3',
      p_name: '广州极飞阿克苏仓1',
      p_guid: '0D3B830597F984F6E052EC24F0674A48'
    },
    {
      name: 'test0731333',
      guid: 'F81DE69DBEED3F97F4B6FCD26B2C293',
      p_name: '广州极飞阿克苏仓1',
      p_guid: '0D3B830597F984F6E052EC24F0674A48'
    }
  ]
}

function transferService() {
  return {}
}

register(
  '/app/member/protection/service/home/get_protectors',
  getProtectorsByGuid
)
register('/app/operation/protection/service/auth/transfer', transferService)

register('/app/operation/protection/service/v2/mp_list', function() {
  return Array(5)
    .fill(1)
    .map((_, index) => {
      return {
        no: `1234${index}`,
        mp_guid: '123243243|配件单guid',
        title: '3类2件',
        status: ['待出库', '待领料', '已领料', '已取消'][index % 4],
        express_type: '到仓自取',
        warehouse: `广州极飞大仓 ${index}`,
        create_at: '2019-08-09'
      }
    })
})

function getNoSingleMaterial() {
  return {
    image:
      'http://agri-private.static.xag.cn/xservice/protection/materials/2019/07/04/bada3bba3e7ffc3e2cb8a7b61fbff976.jpg?imageView2/0/w/150/h/150&e=1878185193&token=kL3qRYaFy4Ip8uZVbJyYE1KL6GgwtdNbqwu5C4lO:FCUd7OOxItMw2uCZr9htopMseVo=',
    name: 'P20 2017机臂（长）',
    code: '14-001-00000',
    count: 2,
    is_single: 0,
    cur_select_count: 5,
    max_select_count: 10
  }
}

function getSingleMaterial() {
  return {
    image:
      'http://agri-private.static.xag.cn/xservice/protection/materials/2019/07/04/bada3bba3e7ffc3e2cb8a7b61fbff976.jpg?imageView2/0/w/150/h/150&e=1878185193&token=kL3qRYaFy4Ip8uZVbJyYE1KL6GgwtdNbqwu5C4lO:FCUd7OOxItMw2uCZr9htopMseVo=',
    name: 'P20 2017机臂（短）',
    code: '14-001-00001',
    count: 0,
    is_single: 1,
    serial_numbers: ['1234', '5678']
  }
}

function GetMultiMrSelectMaterials() {
  return {
    guid1: [getNoSingleMaterial(), getSingleMaterial()],
    guid2: [getNoSingleMaterial(), getSingleMaterial()]
  }
}

function GetMrSelectMaterials() {
  var materials = [getNoSingleMaterial(), getSingleMaterial()]
  return {
    materials
  }
}

register(
  '/app/operation/protection/service/v2/mr_select_material_multi',
  GetMultiMrSelectMaterials
)

register(
  '/app/operation/protection/service/v2/mr_select_materials',
  GetMrSelectMaterials
)

register('/app/operation/protection/service/v2/mr_apply_multi', () => {
  return {}
})

register('/app/operation/protection/service/v2/mr_apply', function() {
  return {}
})

register('/app/operation/protection/service/loss/submit_loss_info', function() {
  return {}
})

register('/app/operation/protection/service/loss/commit', function() {
  return {}
})
