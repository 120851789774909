<template>
  <van-cell-group class="ensure-cells">
    <van-cell
      v-for="(item, index) in displayItems"
      :key="index"
      v-bind="item"
      @click.native.stop="onBtnClick(item)"
    >
    <template #default>
      <span class="text-value">{{item.value}}</span>
      <div class="ensure-step__index ensure-step__index2" v-if="item.redDot=='1'">!</div>
      <!-- <span class="redDot" v-if="item.redDot=='1'"></span> -->
    </template>
      <div v-if="item.btns && item.btns.length">
        <xa-button
          size="small"
          v-for="(btn, index) in item.btns"
          :key="btn.txt + index"
          v-bind="getBtnCfg(index, item)"
          @click.native.stop="onBtnClick(btn)"
          >{{ btn.txt || btn.title }}</xa-button
        >
      
      </div>
    </van-cell>
  </van-cell-group>
</template>
<script>
import { Cell, CellGroup } from 'vant'
import Button from '@/components/common/Button'
export default {
  components: {
    VanCell: Cell,
    VanCellGroup: CellGroup,
    XaButton: Button
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    displayItems() {
      return this.items.map(item => {
        return {
          ...item,
          label: item.subTitle,
          'is-link': !!item.href
        }
      })
    }
  },
  methods: {
    getBtnCfg(btnIndex, item) {
      if (item.btns.length > 1 && btnIndex === 0) {
        return {
          plain: true,
          type: 'info'
        }
      }
      return {
        type: 'info'
      }
    },
    onBtnClick(btn) {
      this.$emit('action', btn, this)
    }
  }
}
</script>
<style lang="scss">
.ensure-cells {
  .van-button + .van-button {
    margin-left: 8px;
  }
  .van-button--plain {
    border: none;
  }
}
.redDot{
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  vertical-align: middle;
  margin-left: 5px;
}
.ensure-step__index2 {
  background: red;
  display: inline-block;
  margin-right: 0;
}
</style>
