export default function() {
  return {
    name: '顺丰快递',
    code: '13343424234324',
    status: '[寄送中](#228800)',
    items: [
      {
        title: '物料已在送达途中，请耐心等候',
        value: '2019-09-28 21:31:19'
      },
      {
        title: '物料已在送达途中，请耐心等候',
        value: '2019-09-28 21:31:19'
      },
      {
        title: '物料已在送达途中，请耐心等候',
        value: '2019-09-28 21:31:19'
      }
    ]
  }
}
