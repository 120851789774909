/* eslint-disable no-debugger */
import actionV4Adapter from '@/controllers/actionV4Adapter'
import request from '@/controllers/request'
import { mapActions } from 'vuex'
import appController from '@/controllers/app-android'
import { setDocTitle } from '@/utils/pages'
function sleep(time = 500) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, time)
  })
}
const mockService =
  process.env.VUE_APP_ONLINE_MOCK > 0 // 是否需要在线mock数据
    ? require('@/mock/service').default
    : null
/**
 * 抽取页面请求源url，以及参数data
 * @param {*} query
 */
function extractRequestInfo(query) {
  const url = query.src
  let data = query.params || {}
  if (typeof data === 'string') {
    data = JSON.parse(data)
  }
  return {
    url,
    data
  }
}
export const LOAD_ACTION = {
  INIT: 'INIT',
  REFRESH: 'REFRESH'
}

export default {
  config: {
    title: '',
    fetchDataFn: '', // todo 混合组件如果设置了这个属性，就去调用组件这个方法来获取页面数据
    canRefresh: true, // 当前页面可以下拉刷新
    watchRoute: false // 检测路由变化来刷新数据
  },
  computed: {
    isRefreshing() {
      return this.$store.state.isRefreshing
    }
  },
  watch: {
    isRefreshing(value) {
      if (this._sleep === true) return
      value && this.$_toRefresh()
    },
    $route() {
      // 是否要监听路由的变化
      const { watchRoute } = this.$_analysisViewConfig()
      watchRoute && this.$_initPage()
    }
  },
  methods: {
    ...mapActions(['showActionSheet']),
    /**
     * @description 统一异步请求，同时代理了mock数据
     * @param {*} config
     */
    async $_request(config) {
      return mockService && mockService.in(config)
        ? mockService(config)
        : request(config)
    },
    /**
     * 处理页面可能发生的交互动作:
     * 跳转链接
     * 发送ajax请求
     * @param {*} actionCfg
     */
    async $_handleCfgAction(actionCfg) {
      const cfg = new actionV4Adapter(actionCfg).out()
      let { href, ajax, action, items } = cfg
      let response = null
      if (ajax && ajax.url) {
        response = await this.$_submitDataWidthUi(ajax, ajax.message)
        href = (response && response.href) || href
      }
      if (items && items.length > 0) {
        const btnIndex = await this.showActionSheet(
          items.map(item => item.title)
        )
        return this.$_handleCfgAction(items[btnIndex])
      } else if (action === 'fetch') {
        return response
      } else if (href) {
        const matched = this.$router.match(href)
        if (matched.matched.length > 0) {
          this.$router.push(href)
        } else if (href.indexOf('app://') === 0) {
          appController.push(href)
        } else {
          window.location.href = href
        }
      } else {
        this.$_toRefresh()
      }
    },
    /**
     * @description 处理页面顶部的下拉刷新事件
     */
    async $_toRefresh() {
      const { canRefresh, fetchDataFn } = this.$_analysisViewConfig()
      // 设置了不能刷新 以及没有指定请求方法 就直接关闭下拉刷新操作
      if (canRefresh === false || !fetchDataFn) {
        return this.$store.commit('triggleRefresh', false)
      }
      try {
        await this[fetchDataFn](LOAD_ACTION.REFRESH)
      } catch (error) {
        this.$toast.fail(error.message || error.msg)
      }
      this.$store.commit('triggleRefresh', false)
    },
    /**
     * 从路由地址抽取信息
     * @param {*} route
     * @returns {url, data}
     */
    $_extractRequestInfoFormRoute(route = this.$route) {
      return extractRequestInfo(route.query || route)
    },
    async $_syncSubmitData(promise, { notify = false } = {}) {
      let toast
      let result
      try {
        toast = this.$toast.loading({
          message: '请求中...',
          forbidClick: false,
          duration: 0
        })
        result = await this.runPromise(promise)
        toast.close()
        if (notify) {
          this.$notify({ type: 'success', message: '提交成功' })
          await sleep()
        }
      } catch (error) {
        toast.close()
        await this.$dialog.alert({
          message: error.message || error.msg
        })
      }
      return result
    },
    // 提交请求
    async runPromise(promise) {
      let result
      if (typeof promise === 'function') {
        // 传递过来的是请求方法 则调用下
        result = await promise()
      } else if (typeof promise === 'object' && promise instanceof Promise) {
        // 传递的是promise 对象 则监听其请求
        result = await promise
      } else if (typeof promise === 'object' && 'url' in promise) {
        // 传递的是请求配置，则发起请求
        result = await this.$_request(promise)
      }
      return result
    },
    async $_submitDataWidthUi(promise, message, title = '', notify) {
      if (message) {
        try {
          await this.$dialog.confirm({
            title,
            message
          })
        } catch (error) {
          return false
        }
      }
      let toast
      let result
      try {
        toast = this.$toast.loading({
          message: '请求中...',
          forbidClick: true,
          duration: 0
        })
        result = await this.runPromise(promise)
        notify && this.$notify({ type: 'success', message: '提交成功' })
      } catch (error) {
        this.$dialog.alert({
          message: error.message || error.msg
        })
        throw error
      } finally {
        toast.close()
      }
      return result
    },
    // 有交互提示的获取数据
    async $_fetchDataWidthUi(promise) {
      let result
      let toast
      try {
        toast = this.$toast.loading({
          message: '加载中...',
          forbidClick: true,
          duration: 0
        })
        if (promise) {
          result = await this.runPromise(promise)
        } else {
          const { fetchDataFn } = this.$_analysisViewConfig()
          result = await this[fetchDataFn](LOAD_ACTION.INIT)
        }
        toast.close()
      } catch (error) {
        toast.close()
        this.$toast.fail(error.message || error.msg)
        throw error
      }
      return result
    },
    /* 解析当前页面的config配置
     * title: 顶部标题
     * fetchDataFn: 获取数据的方法
     * canRefresh: 当前页面能否刷新
     */
    $_analysisViewConfig() {
      const config = this.$options.config || {}
      if (this.$options.$_canRefresh !== undefined) {
        config.canRefresh = this.$options.$_canRefresh
      }
      return {
        title: config.navigationBarTitleText || config.title,
        fetchDataFn: config.fetchDataFn || this.$options.$_fetchDataFn,
        canRefresh: config.canRefresh,
        watchRoute: config.watchRoute || false
      }
    },
    async $_initPage() {
      const result = await this.$_fetchDataWidthUi()
      if (result && result.title) {
        this.$options.title = result.title
        setDocTitle(result.title)
      }
    }
  },
  beforeMount() {
    const { title, fetchDataFn } = this.$_analysisViewConfig()
    title && setDocTitle(title)
    if (fetchDataFn) {
      this.$_initPage()
    }
  },
  activated() {
    this._sleep = false
    const { title } = this.$_analysisViewConfig()
    title && setDocTitle(title)
  },
  deactivated() {
    this._sleep = true
  }
}
