import { register } from '../service'
function getWarehouseList() {
  return [
    {
      name: '曲阜子林农业科技授权保障网点',
      guid: 'C809BB8B6FF89AFA15BBD6827747D9F8'
    },
    {
      name: '测试授权保障服务网点1',
      guid: '0D724E15F88F355BA3B7BBAE14A653AA'
    },
    {
      name: '极飞东莞直属服务中心',
      guid: '11C35ECACC21FC36C3F3A27C98975BBB'
    },
    {
      name: '江西思煌生态农业开发授权保障网点',
      guid: 'D85AB630FD5899FD9223018760064036'
    },
    {
      name: '松滋市八宝农机授权保障网点',
      guid: '722C281C86F8EBBCF6ACE3A1FD82E804'
    },
    {
      name: '晋州市德农植保技术服务授权保障网点',
      guid: '80B2C36A6886507D49BC1C34C03F1482'
    },
    {
      name: '河北米格农业科技授权保障网点',
      guid: '2770A4CBE677632E0E14714F1C624E0C'
    },
    {
      name: '温州极飞农业科技授权保障网点',
      guid: '757777D22D17A4C34DCD40820499340C'
    },
    {
      name: '常德锦绣千村农业开发授权保障网点',
      guid: 'A6C90947414ABF9BEF82D866F876B389'
    },
    {
      name: '泰来县良人农业发展授权保障网点',
      guid: 'DFE71247DDBA153A6989964B2EDDBDCA'
    },
    {
      name: '靖江市农机贸易授权保障网点',
      guid: '0044BFA77CF5DF2835F14D67EE405420'
    },
    {
      name: '潜江市风山农机授权保障网点',
      guid: '90EA3AD40C07074DA5173945E1D4EE11'
    },
    {
      name: '湖南省蓝鹰科技授权保障网点',
      guid: '44D80F9B581EB6E4BA675A0B4BB8B2E6'
    },
    {
      name: '广州智农农业服务授权保障网点',
      guid: 'C6D067D249DD07FA14DEA4159713BB9F'
    },
    {
      name: '泰州福沃农机授权保障网点',
      guid: 'C9A9C1AEFCD0A62B7C4A0C181DEC0C4F'
    },
    {
      name: '泗洪县惠农农业机械授权保障网点',
      guid: 'EA5C598B276D2BC4734A588B4A12F7BB'
    },
    {
      name: '武陟县雨鑫农业科技授权保障网点',
      guid: 'C8584DBD26015DB63C8DD9B77E1D8D30'
    },
    {
      name: '宁波水平农业科技授权保障网点',
      guid: 'AEB66130CFFEAD2D05DF9DDD4BA1C2E3'
    },
    {
      name: '镇江市农业机械授权保障网点',
      guid: 'D156C7A26147CF08C1BD3E8DEF960927'
    },
    {
      name: '泗阳极飞农业科技授权保障网点',
      guid: 'E39E711E7CA9517823B430FD7F2CBC15'
    },
    {
      name: '滦南县久岭农资经销处授权保障网点',
      guid: '5CCC9B138B1890EA9E07C2696C82DB77'
    },
    {
      name: '濮阳市满仓农业授权保障网点',
      guid: '1C9A12AB3F7F4027CBD661A1701E2291'
    },
    {
      name: '山东国本农业服务授权保障网点',
      guid: 'CFAE7989E1E9E160EDF88B004B3D0C3A'
    },
    {
      name: '济南培鸿植保服务授权保障网点',
      guid: '83A8A863DA3023281F75D44E2E45D8AE'
    },
    {
      name: '珲春市恒大农机销售授权保障网点',
      guid: '075A3B7D3D0F962E0C18116CDF3B2B5E'
    },
    {
      name: '海南智能助农科技授权保障网点',
      guid: 'D17BC76E471F1DBDD414F342EE956622'
    },
    {
      name: '环县大朝阳农业植保授权保障网点',
      guid: '02814960BC627372DDDD4BC19FD0C83D'
    },
    {
      name: '河南极云农业服务授权保障网点',
      guid: '98F8521C37CE54854DF27601585C722D'
    },
    {
      name: '泰州苏盛农机授权保障网点',
      guid: 'A0A21A84ABB1491CFF9638F3ADA4A660'
    },
    {
      name: '宁夏英航智控农业服务授权保障网点',
      guid: 'E7C929091BA2A68AAB761E20881A3B7B'
    },
    {
      name: '兴化市润农农业机械授权保障网点',
      guid: '01D6C86D7CCDB872501E1D4D6C6D998C'
    },
    {
      name: '江苏大中农场集团授权保障网点',
      guid: 'E1428C2D62A815502AA49E45CC11E103'
    },
    {
      name: '河南二田农业科技授权保障网点',
      guid: 'C1E78A5C9CCF7B53537AF626C2D9DF4F'
    },
    {
      name: '甘南县极智植保无人机销售处授权保障网点',
      guid: '7BB009FD59A4F0A4A208F3B0EAC767D0'
    },
    {
      name: '唐山睿禾农业科技发展授权保障网点',
      guid: '71BCFEB7AFFCA0AD435A7898DB2181A9'
    },
    {
      name: '驻马店市申飞农业技术服务授权保障网点',
      guid: '10F0B99BEEF45295FEB8171711AE8ED0'
    },
    {
      name: '成都寿泰通航科技授权保障网点',
      guid: 'A5D244E71F93BBD22AD55FE172FE38E8'
    },
    {
      name: '梁山县君臣农业机械授权保障网点',
      guid: 'F2063D0864B9B2C71B49573CE516A533'
    },
    {
      name: '山东农小二农业服务授权保障网点',
      guid: '07B1D7D6C7046A20393B3A5ACC34E033'
    },
    {
      name: '高邮市秦邮农业机械授权保障网点',
      guid: 'D8517EE591875619CA9A2C92EED3CCF1'
    },
    {
      name: '河南鸿卓信息技术授权保障网点',
      guid: 'D2D9B1920F65136FDC3076BD199F88ED'
    },
    {
      name: '福建闽飞航空科技授权保障网点',
      guid: '92D7CA588C2A55E70C3892534CEBEF4D'
    },
    {
      name: '湖北荆丰农业技术授权保障网点',
      guid: '661825F89CED287201408165B55051AE'
    },
    {
      name: '商河县金瑞化肥经营部授权保障网点',
      guid: 'C7003F1843C69E34F38F139E841B1A99'
    },
    {
      name: '广州粤丰农业科技授权保障网点',
      guid: 'AD668CEA1EB18313A713127A8C875507'
    },
    {
      name: '江苏蒲公英无人机授权保障网点',
      guid: '502D5A8D4C8988A32BDFEF27F02B6312'
    },
    {
      name: '盐城瑞峰菱汽车贸易授权保障网点',
      guid: 'C29DB64A0A2DAEDD6589B2A065039E1F'
    },
    {
      name: '南京溧水永润农机授权保障网点',
      guid: '6DB9BD00E18B97DC1CA8D7A7A969565C'
    },
    {
      name: '湛江市田园农机授权保障网点',
      guid: 'B1C7F69C4DEF2D2000246C73606E1AE2'
    },
    {
      name: '依安县民泰农业机械销售部授权保障网点',
      guid: 'CD961C5035FD0978753C868BB65A43EE'
    },
    {
      name: '磐石市鼎晟农机经销授权保障网点',
      guid: '029B337938D077ADC7D91D00FD4418FC'
    },
    {
      name: '盱眙苏盛农机授权保障网点',
      guid: '558564AAAD89164F5FD3C52631D2CC2D'
    },
    {
      name: '德惠市保田农机授权保障网点',
      guid: 'D1ADF58AFF8E8EB1052E61C373E17121'
    },
    {
      name: '广州市兴农农业服务授权保障网点',
      guid: 'DCFD9825791712185EE6E4FE9AE66424'
    },
    {
      name: '河北绿盾植保技术服务授权保障网点',
      guid: 'EF718250882190BB4BB297BC95A0F5FE'
    },
    {
      name: '嘉兴信泰飞防农业科技授权保障网点',
      guid: '91CFF0E3B2C52C200FA1B368BEB74821'
    },
    {
      name: '临海市腾云农业技术服务授权保障网点',
      guid: 'F4A5B2B23693B142721E602F9CAE0B1B'
    },
    {
      name: '临沂市新思维电子科技授权保障网点',
      guid: 'FC8B05424189ADF2E89B7E2E1C68CE43'
    },
    {
      name: '临沂市新思维电子科技授权保障网点',
      guid: '08A1757E3FBEACB9699B6749C95C6DA7'
    },
    {
      name: '睢宁张军农机销售授权保障网点',
      guid: '9FF4F75E9B0E62B6C6654B35C17902D0'
    },
    {
      name: '南平市丰溢机电授权保障网点',
      guid: '2DAE810D3924F82E02E59DA30FB797D1'
    },
    {
      name: '唐山市丰南区昌胜农机销售授权保障网点',
      guid: '0DF5B37BABC0E415886285B5FE84F651'
    },
    {
      name: '连云港阳丰农机销售授权保障网点',
      guid: '800B60A872FE1A6B52F89AE735E8AAEC'
    },
    {
      name: '镇赉县昊龙现代农业机械经销授权保障网点',
      guid: 'D44AAE3609E1333B3118ED1BE767A76C'
    },
    {
      name: '海南惠飞植保工程科技授权保障网点',
      guid: '8BCBC9462F196073BD16407CCDC03B0E'
    },
    {
      name: '德清吉丰农业机械授权保障网点',
      guid: 'EEFEEBA8C3111F07AE766346DEA5B032'
    },
    {
      name: '佳木斯汇发农机授权保障网点',
      guid: '7474CEC32DD4E5B0E07C81F28E6B105C'
    },
    {
      name: '单县顺利农业科技服务授权保障网点',
      guid: '575AFC8446DD259306BC1B04EBF31DF2'
    },
    {
      name: '鸡东县环宇农业机械经销处授权保障网点',
      guid: '2303479FDB6B760C2B2316BD1A72BF06'
    },
    {
      name: '黑龙江省宝泉岭农垦龙江二手车销售授权保障网点',
      guid: '6B48145AF78794E0F5BFBD524C0A35C7'
    },
    {
      name: '黑龙江省牡丹江农垦鸿展农机经销授权保障网点',
      guid: '38FEA10E2F3585760BBE3767155BE015'
    },
    {
      name: '界首市粮友农业服务授权保障网点',
      guid: 'C37354B07AD2975ABC538708F03ACEC4'
    },
    {
      name: '韶关市辉鸿生态科技开发授权保障网点',
      guid: 'C471D2B8DB1F5B782C254F33B17BBB3A'
    },
    {
      name: '宁波鑫超农业科技授权保障网点',
      guid: '955BC27B7E9329AA1F4EC89D855EE626'
    },
    {
      name: '衢州智丰农业技术授权保障网点',
      guid: '557A17DD1B505D6E6B5712715E564EA6'
    },
    {
      name: '四川极云智飞科技授权保障网点',
      guid: 'C828A9F062F3BF836E17F57415259E04'
    },
    {
      name: '台州市勤丰农业机械授权保障网点',
      guid: '9723D0D8DB49DD79499C0C002BC451E2'
    },
    {
      name: '安徽农祥航施农业科技授权保障网点',
      guid: 'D6E84CFA59EE569BAE2B0151B7271FAC'
    },
    {
      name: '佳木斯瑞恒农机授权保障网点',
      guid: '714FF89E3055D7A99F6B242EBBCAA030'
    },
    {
      name: '穆棱市有为农业技术推广服务部授权保障网点',
      guid: '312822B295574E0D1BF63F599ABCA890'
    },
    {
      name: '徐州望田农业机械授权保障网点',
      guid: '14A608D45901D2CFA5EEFCC20F45B83F'
    },
    {
      name: '重庆翼飞创新科技授权保障网点',
      guid: '94E621866A667EB7D1840B798C50A18F'
    },
    {
      name: '缙云县兆通农机服务授权保障网点',
      guid: '557816639BD2BE63986D0B489E8D0203'
    },
    {
      name: '黑龙江省牡丹江农垦协众农业生产资料经销授权保障网点',
      guid: '73EC5AA32F2B37778BA785CC93E574E9'
    },
    {
      name: '云南羽陆航空科技授权保障网点',
      guid: '23F2CAF3F85B2225A2B71DA905618BCA'
    },
    {
      name: '徐州博然农业机械授权保障网点',
      guid: 'F0C9E985A897DBA16966C8E59515AE7A'
    },
    {
      name: '沈阳亿达农业机械授权保障网点',
      guid: 'F316E843D8913B51530D8AD53145DEC8'
    },
    {
      name: '黑龙江广沃科技开发授权保障网点',
      guid: 'B6B091E496E84D7CC2167E09335EBDE0'
    },
    {
      name: '汪清新皓农机销售授权保障网点',
      guid: 'FE6A6AA102872C97030E7621BB163711'
    },
    {
      name: '大安市金成农业机械设备销售授权保障网点',
      guid: 'C026F555EFE6019713531B29A8838D3F'
    },
    {
      name: '黑龙江鑫农翼农业科技授权保障网点',
      guid: 'F0992F32F4F829729AA70FB9ED3876B8'
    },
    {
      name: '吉林市昌盛农业服务授权保障网点',
      guid: '28F02E2D8AE73B62317488FC77FA656E'
    },
    {
      name: '梅河口市衣禾农机授权保障网点',
      guid: 'B14F5C0A43135110E225FE5BAEF8ED77'
    },
    {
      name: '新乐市德成农资授权保障网点',
      guid: '50DA45612531036C991C375C23D7A675'
    },
    {
      name: '枣庄万丰农林科技授权保障网点',
      guid: '60BD4372F35D35B1AB5EDD1F47E31EB6'
    },
    {
      name: '绥棱县极飞农业科技授权保障网点',
      guid: 'D6C77C4C16E9B68B373F37A5946ADC08'
    },
    {
      name: '益阳农飞客农业科技授权保障网点',
      guid: 'DFF0F6D794783AAB96634EC771CE6358'
    },
    {
      name: '德惠市众诚农机授权保障网点',
      guid: '5D50CF885AB4462EA147CD18309EDD8E'
    },
    {
      name: '宜兴苏欣农机授权保障网点',
      guid: '32C86A294A6B8CAA6FCFBCECE285DAC6'
    },
    {
      name: '丹阳润欣农机授权保障网点',
      guid: '8BB19EC355D58BFC528F13A3DCFD99D3'
    },
    {
      name: '句容汇宝农机授权保障网点',
      guid: '9B0B2C607ACA6E67B8E91335FEFB419F'
    },
    {
      name: '常州市金坛汇宝农业机械授权保障网点',
      guid: '065A4784C123E223FB8E6964082463FC'
    },
    {
      name: '安徽亿飞高科农业授权保障网点',
      guid: '0B0E70D76B389E4E418F863C18C58B45'
    },
    {
      name: '定远县金丰农业服务授权保障网点',
      guid: 'C2D0262BF90BD3C63D48B835258A62FF'
    },
    {
      name: '武陟县云丰农业科技授权保障网点',
      guid: '39AEF22478F7335875A8E718CE15A293'
    },
    {
      name: '东至县吉民机械销售授权保障网点',
      guid: '03DEEEA8C3F4A61F2D2492B9BB944597'
    },
    {
      name: '河南联创农业服务授权保障网点',
      guid: 'E8599EB63ECCCCF4C4B8403F7CCBBC2E'
    },
    {
      name: '湘潭县亿达农机授权保障网点',
      guid: 'E7D3106965E437D1F807A3573A8A28BF'
    },
    {
      name: '垦区红兴隆八五三农场兴和综合物资商店授权保障网点',
      guid: 'F0339FF7FE869936059D2B2077E9EF32'
    },
    {
      name: '河南满豫农业服务授权保障网点',
      guid: 'F38015732D01B2828251BCB6BC62FAEB'
    },
    {
      name: '岳阳市农飞客农业科技授权保障网点',
      guid: '7EB98624EE82B793C1D1F00B887F8291'
    },
    {
      name: '沭阳飞航农业服务授权保障网点',
      guid: '680DC095B32F5221740B87BA934DCFF4'
    },
    {
      name: '淮安鸿恩科技发展授权保障网点',
      guid: '69B24440D5744396A07164F6A332FA61'
    },
    {
      name: '黑龙江省建三江农垦竣奇农机销售授权保障网点',
      guid: 'BC16D201B79134170214ED04F556501B'
    },
    {
      name: '渭南荔来农业科技服务授权保障网点',
      guid: '1B6F4F84C04343B8531BBCD22CB46A6B'
    },
    {
      name: '安徽省农业机械股份授权保障网点',
      guid: '12CEF0F0A603438A1160D99FB5BFE24C'
    },
    {
      name: '陕西极飞智慧农业授权保障网点',
      guid: '5C68BC214FE947C25989A354A8634FBA'
    },
    {
      name: '江西省亿发姆农业发展授权保障网点',
      guid: '3FDB69EF0BAB2CC5DF8A4DCC4F0A5231'
    },
    {
      name: '淮安福沃农机授权保障网点',
      guid: '4D7C117200AEC0D231A067EE6B1A9703'
    },
    {
      name: '滨州万丰农化授权保障网点',
      guid: 'C4CB586A5391820294C24DD68B582283'
    },
    {
      name: '杭州科创机电授权保障网点',
      guid: '6DF470FEE1BA29EC70D281F9A0AE0C3D'
    },
    {
      name: '邹城市金粮丰农业服务授权保障网点',
      guid: '5482DB113A3BA7510F886B9A8D7A6AB9'
    },
    {
      name: '赵刚授权保障网点',
      guid: '6E8DAF4387C500789C43F22883E82958'
    },
    {
      name: '长岭县振兴农业机械制造授权保障网点',
      guid: '4F276C0C4615C1722553EEF5381D8D3F'
    },
    {
      name: '梅河口市时风汽车贸易授权保障网点',
      guid: '01A70A4D6FFE5D06A824FAAFC0A1BB84'
    },
    {
      name: '河南青苗农业发展授权保障网点',
      guid: '6A59ADBF8FD4189BD977780901800FE3'
    },
    {
      name: '无为县富民农机授权保障网点',
      guid: '74D6A0F8C423F19960146AB8D8AE8A47'
    },
    {
      name: '天津绿农源生物科技授权保障网点',
      guid: '62437E4F6FEB5082A5150D6622687B92'
    },
    {
      name: '庐江县福田农业机械销售授权保障网点',
      guid: '1BA73A42FD8CD90BA7B9C750FECF2592'
    },
    {
      name: '湖南谷成农业发展股份授权保障网点',
      guid: 'DA308B956859C92698CB80B3C262D13D'
    },
    {
      name: '富锦市极飞农业机械授权保障网点',
      guid: '9FEEE4EADB798989885B89B40E55D670'
    },
    {
      name: '湖南农飞客农业科技授权保障网点',
      guid: '3E85AA8C2F1CFBAE27518FBBD0C6E6BE'
    },
    {
      name: '枣庄农博士农业科技授权保障网点',
      guid: '037AD15FD2C99649EB646A4668F15FBD'
    },
    {
      name: '徐州市铜山区耀然农机授权保障网点',
      guid: '7AA6632FC9025C913A444743015A8347'
    },
    {
      name: '盐城市凯纽农业机械授权保障网点',
      guid: '5D3E400E9142C43FC7D359E9A0388691'
    },
    {
      name: '黎川县裕农农资服务推广站授权保障网点',
      guid: 'C3363651C777787BEC8E348A803BF922'
    },
    {
      name: '南京苏欣福沃农机授权保障网点',
      guid: '1C9997C98D9363C98D0E2325505E48D6'
    },
    {
      name: '武宣县明佳农业科技授权保障网点',
      guid: '65E53CD3ECEC4FBBD50EA6FA84CB0D77'
    },
    {
      name: '淄博市盛合农业科技授权保障网点',
      guid: '936905326B605DA0E1B71DA9FE899919'
    },
    {
      name: '滨州市畅通农业机械授权保障网点',
      guid: '77BA8C718273A513749266C7E75059E0'
    },
    {
      name: '吉林省沃而得农业科技授权保障网点',
      guid: '1282B7AD5D83DD484F2D228A056AB3FF'
    },
    {
      name: '讷河市全胜农业发展授权保障网点',
      guid: '770668F7A94EB0860AAF0ADDD73303E4'
    },
    {
      name: '凤阳润田农机销售授权保障网点',
      guid: '6B385770341F982B13086A9F51D2789D'
    },
    {
      name: '海安保丰农机授权保障网点',
      guid: 'B6B7920F4DCCB8A624F17F51D090EEB9'
    },
    {
      name: '仪征市农业机械授权保障网点',
      guid: 'ED4DBC932DB55B231201ECF4E71F0A1C'
    },
    {
      name: '余干县冠雄农机授权保障网点',
      guid: 'CA7D91F409F6DDF7731A9A76A68D1B47'
    },
    {
      name: '南城县协农植保专业飞防队授权保障网点',
      guid: '2B7DDF74CC3C0F57C9953DA5BB0B7336'
    },
    {
      name: '合肥市大唐农机销售授权保障网点',
      guid: 'D756E8C037E00080B95A96BE8599C614'
    },
    {
      name: '湛江徐飞农业服务授权保障网点',
      guid: '80D56BC5A57B14BA9C7B448BA1B02DCC'
    },
    {
      name: '陕西盛富同祥贸易授权保障网点',
      guid: '6D2DA7CDAFF7D31F4051C4A481055387'
    },
    {
      name: '依兰县众邦农机经销处授权保障网点',
      guid: '7BEA716F5684900A727BE99ADA286679'
    },
    {
      name: '江西省同升农业发展授权保障网点',
      guid: 'CB532EE70725B49ACB47791EDE8D755F'
    },
    {
      name: '河南冠丰农业科技授权保障网点',
      guid: 'AA73EAB8281B21E44644F5CA29D2A6AC'
    },
    {
      name: '鄱阳县极飞智能科技授权保障网点',
      guid: '89CC2734452A60E4A2F9ED0BCBB1B206'
    },
    {
      name: '安徽嘉禾植保科技授权保障网点',
      guid: '831F639E73F58D1CF7F45736DE41ED42'
    },
    {
      name: '内蒙古鸿鑫启元农业科技服务授权保障网点',
      guid: 'BF55238571B089711883B806AD4009FC'
    },
    {
      name: '贵池区世明农机经营部授权保障网点',
      guid: '324A34D75E0CFDC6983FA1035C86796A'
    },
    {
      name: '唐山金甸农业科技授权保障网点',
      guid: '30D50C44904B59E538D7BBE29D44D780'
    },
    {
      name: '江西省宝顺农机授权保障网点',
      guid: 'D40E3C931E9716F7B8B6AA4778367FC0'
    },
    {
      name: '合肥裕邦农业科技授权保障网点',
      guid: 'BC4DBE12C7E859E0905D1A918AB0970C'
    },
    {
      name: '亳州市大启农机销售授权保障网点',
      guid: '3699F900BEBE414AA5D42FC2BE4DAA88'
    },
    {
      name: '安徽省顺悦农业机械销售授权保障网点',
      guid: 'FAB5080222905EDFF2E985E367360C6E'
    },
    {
      name: '陕西瑞飞创新科技授权保障网点',
      guid: '36A6F29FBE3A1FCCCC97F0AA507EFC24'
    },
    {
      name: '当涂县东方农业机械授权保障网点',
      guid: 'F0C9EDB9760DBAE16DF6AE19FAB20EF4'
    },
    {
      name: '鹤岗市宝泉岭江滨德辉农资商店授权保障网点',
      guid: 'DC0ED56F4D8DE3FDF58B3AB5CA91C2BC'
    },
    {
      name: '濉溪淮匠机械设备授权保障网点',
      guid: 'B55E2BDAFABC3894AAE1247E3C87D35D'
    },
    {
      name: '如皋市现代农业机械授权保障网点',
      guid: 'D65C3C5844923BD59BBBDAAC45992AB1'
    },
    {
      name: '如东县双华农机销售授权保障网点',
      guid: '33FE0904EDE74C2623FC277F90A65AF1'
    },
    {
      name: '芜湖保田农业机械授权保障网点',
      guid: '4FF6BB2187124123CA9D18B902938E37'
    },
    {
      name: '南京科地农机授权保障网点',
      guid: 'EF2B03DE2A7A0FF24870E95A315F5CD8'
    },
    {
      name: '安徽志木农业科技授权保障网点',
      guid: '6CA7E1AE08B611847A21D64493D01228'
    },
    {
      name: '凤台县农旺植保服务授权保障网点',
      guid: '6039AFCFC191D8EEEDF97A03D3E83F23'
    },
    {
      name: '安徽正徽无人机科技授权保障网点',
      guid: '92753622168CF677DAA30C83A73C1858'
    },
    {
      name: '湖北长投农机授权保障网点',
      guid: 'BB5002C4E6064A72ACD790A146DBD65F'
    },
    {
      name: '永丰县农航植保授权保障网点',
      guid: '04E5F90A8FC705E6BFCFFB93B4E52903'
    },
    {
      name: '绍兴市友田农机授权保障网点',
      guid: '10B9AAB860952C9DEF9A03F15B5FB99C'
    },
    {
      name: '霍邱县农事通农业科技服务授权保障网点',
      guid: 'E11754A702D6F3A3FCF62232415C1908'
    },
    {
      name: '颍上县宏达农机销售授权保障网点',
      guid: 'AA52B50BCB1DB39443F71AEA2457572A'
    },
    {
      name: '江西省田卫士农业科技授权保障网点',
      guid: '218FC00A3583471BB86DDFEAE3CBD1D1'
    },
    {
      name: '农飞客湖北农业科技授权保障网点',
      guid: '0AB49496235117AA053ED3E77983B8A3'
    },
    {
      name: '荆门市五三顺达农机授权保障网点',
      guid: '05F9A77F20F404B75B939189DAB4BA60'
    },
    {
      name: '河南省花大姐农业科技服务授权保障网点',
      guid: 'BC37557C55B4625A0E6DB23E57372007'
    },
    {
      name: '黑龙江省建三江农垦天臻美农机销售授权保障网点',
      guid: 'D527A38EE18FE18631B7D13A1C4D1CD4'
    },
    {
      name: '汉寿县昌隆农机授权保障网点',
      guid: 'E014137F0C179BCD9575AEE1BC9F04C7'
    },
    {
      name: '宿迁丰瑞植保服务授权保障网点',
      guid: '9E7CE57F4625E3F926728AB35744D99D'
    },
    {
      name: '聊城人和农资授权保障网点',
      guid: 'BDF619AA7A71A43940B71B155C2D8160'
    },
    {
      name: '南县农飞客农业科技授权保障网点',
      guid: '581C6A78952E0DDDE4A8EA716EBEB716'
    },
    {
      name: '黄石市智农科技授权保障网点',
      guid: 'B1703956EB6A5F4E3C5FAD924845C435'
    },
    {
      name: '德阳市华锐农机授权保障网点',
      guid: '720DF012B7BBE9808008276B6DACCCD2'
    },
    {
      name: '浙江吉峰农业科技服务有限公司授权保障网点',
      guid: 'C2DDD8DFC765329AC53DA80355E0C046'
    },
    {
      name: '湖北千飞智能科技授权保障网点',
      guid: '35BA0D2556E3B99F6B998127FD77E1D1'
    },
    {
      name: '湖北明蓝科技授权保障网点',
      guid: '68A9787415EF0899A2D6AB6ABB82EA0F'
    },
    {
      name: '山西美达丰农业科技授权保障网点',
      guid: 'CBFEBCC56DB76797656597A32B3336E9'
    },
    {
      name: '湛江市悦湛农业科技授权保障网点',
      guid: 'E8632C95550147ECB328C9C29F1FB04D'
    },
    {
      name: '通许县旺达化肥门市部授权保障网点',
      guid: 'D132A2288E0F8D0AF4D09BFCCCC5A519'
    },
    {
      name: '泰来县国达农机授权保障网点',
      guid: '2C023DBC564B347B5BCEBCB9E679BBE7'
    },
    {
      name: '当阳市华宇惠民农机销售授权保障网点',
      guid: '6A15F50CBACA4D62D0D7734A985FE0E9'
    },
    {
      name: '张家港市全程农机化服务授权保障网点',
      guid: '19DD6251BDA65640C9393601F19A4F31'
    },
    {
      name: '丁永利授权保障网点',
      guid: '0D49691BD70225FFE782F6C25CAD4A73'
    },
    {
      name: '黑龙江省宝泉岭农垦丰裕农机销售授权保障网点',
      guid: '860E993C89789A282DBA8CC5F7BC8A30'
    },
    {
      name: '巢湖市经纬飞防科技授权保障网点',
      guid: '7DF1C4ADF2CF2CAE6F5749073144EAF7'
    },
    {
      name: '淇县润丰农机销售授权保障网点',
      guid: '7EA029D71F0AE5BDA886C020FB5C1061'
    },
    {
      name: '禹城市丰豪商贸授权保障网点',
      guid: 'F9B45A8A15E1A5A27A868308B6973D81'
    },
    {
      name: '安徽航宇航空植保授权保障网点',
      guid: '6E4533BC1211BEA7C3212B4CD138BF56'
    },
    {
      name: '李明授权保障网点',
      guid: 'ECBBA389A3B5B06E83001CBBB29F9579'
    },
    {
      name: '连云港市赣榆金利农机销售授权保障网点',
      guid: '18BD94FA128D6308B2C7A6555A580D46'
    },
    {
      name: '淮安领航飞防植保服务授权保障网点',
      guid: '27ADE829158396BBC0DACF0964FE36A6'
    },
    {
      name: '桦南新华一农业科技发展授权保障网点',
      guid: '5ECEA76F1C28A9F217163C22D4251937'
    },
    {
      name: '虎林市农江无人机销售授权保障网点',
      guid: 'DF7C9AB62287D86CD1062009EEB0C968'
    },
    {
      name: '建宁县农秘书农业服务授权保障网点',
      guid: '82A2E74CFBEAF02E9BCEACC6A644FA84'
    },
    {
      name: '韶关市恒昊农业科技授权保障网点',
      guid: '6F881E8ED3F2BD37A08B865C92029BCE'
    },
    {
      name: '钟祥市天和农机销售授权保障网点',
      guid: '638D8C735AB2190BF21C7C64699D0CFC'
    },
    {
      name: '宿松新胜农业机械销售授权保障网点',
      guid: '1CB0DE0425753C147CBFDEA9A7274054'
    },
    {
      name: '抚州力达农机授权保障网点',
      guid: 'E441D9D3B0666FF89065ECE82A91344E'
    },
    {
      name: '六安市绿苗农资授权保障网点',
      guid: 'D03AB172295DCF437325D51019FA9590'
    },
    {
      name: '安徽百舸争流农业科技授权保障网点',
      guid: 'CE23DFA126C88FA445DEF677FDA28822'
    },
    {
      name: '南平建阳区放飞航空科技授权保障网点',
      guid: 'E87C37AF77564AC01A940E71A008B2D3'
    },
    {
      name: '田勇授权保障网点',
      guid: 'BBE8417AA1B9DA82D8FE657FFDF70445'
    },
    {
      name: '南通利华农机授权保障网点',
      guid: '589DC8A012B52E8662B592E16B2DB99B'
    },
    {
      name: '铜陵市泽民农机销售授权保障网点',
      guid: '95FEE91D30AC2F1F2170D4FFCF095D83'
    },
    {
      name: '潍坊潍一农业科技授权保障网点',
      guid: 'DC926EAB67007210E8EEA49ECCB5E354'
    },
    {
      name: '山东省阳信美农植物保护授权保障网点',
      guid: 'E9F2AF2090CD37E304B77CB92B915979'
    },
    {
      name: '溧阳市汇恒农业科技授权保障网点',
      guid: '1A9216E9FBE906487017CD8ED80BE906'
    },
    {
      name: '莱州市佳农植保机械授权保障网点',
      guid: 'BD099F01DFF9FD60909B1B9379350A68'
    },
    {
      name: '黑龙江省建三江农垦东南西北农机销售授权保障网点',
      guid: 'E2FA496EB8E95408AD6BCD60CA9D797F'
    },
    {
      name: '峡江县超农病虫害防治授权保障网点',
      guid: 'B47165504CDCEC776AC9946C18D3D747'
    },
    {
      name: '石首市中南机电授权保障网点',
      guid: '2197C29CFAF78DCD1D723B8EA9733AC3'
    },
    {
      name: '湖南大队长农业授权保障网点',
      guid: '593280599F27497E9B4C41FE6D92704B'
    },
    {
      name: '寿县中惠农机销售授权保障网点',
      guid: 'D39062E125A82AB1E9005FEA2D1D1A03'
    },
    {
      name: '上海晨韬农业科技授权保障网点',
      guid: '6141A9CC61B75A75047CA77A41992CF0'
    },
    {
      name: '张超授权保障网点',
      guid: '544EC956C2A60058501C23F543AC6A83'
    },
    {
      name: '饶河智农农业开发授权保障网点',
      guid: 'BF808C84BB1CE2C7E9FDA14B416874BA'
    },
    {
      name: '靖远亿同发农业机械发展授权保障网点',
      guid: '3DD6E4928E85BB8C30D2BE582E575246'
    },
    {
      name: '黑龙江省建三江农垦鹤翔农业植保服务授权保障网点',
      guid: '50DE888A3BCCE796D19CA17AE53E13E2'
    },
    {
      name: '山西极云智新科技授权保障网点',
      guid: '5CC4FD7A0C93B69D748B52A86173BBF1'
    },
    {
      name: '安徽伟航农业科技授权保障网点',
      guid: '81940D7E3914837165FC664E7653CEAF'
    },
    {
      name: '荆州市瑞丰农机授权保障网点',
      guid: 'AE25AEA6EE3103B0D27F3B954C96D19B'
    },
    {
      name: '河北帅植科技授权保障网点',
      guid: 'D54948F687D27AD0AAB28326EDD4867C'
    },
    {
      name: '贵州金州农业开发授权保障网点',
      guid: '96A3CC8D90A1451D40D0314E9E451B14'
    },
    {
      name: '衡水市冀州区金丰公社农业服务授权保障网点',
      guid: '34D6A32C56C35EE7FAF68B8FD6EEF17F'
    },
    {
      name: '东明及时雨农机授权保障网点',
      guid: '5F1C92E4BF61F654E68B8CD6BC2E3F6C'
    },
    {
      name: '东海县天宇农机销售授权保障网点',
      guid: 'E0634E36053471A9AEE06BCD7BE6F46D'
    },
    {
      name: '宁乡极智农机销售授权保障网点',
      guid: 'A218AB1C9834CE0803C731ADF738757C'
    },
    {
      name: '诚润农业（辽宁）授权保障网点',
      guid: '28106BC41689867F78493FB7BBC00A85'
    },
    {
      name: '虎林市鹏程农业发展授权保障网点',
      guid: '331B798A851F99B32B9A1CB34A551688'
    },
    {
      name: '铁力市极飞农机经销授权保障网点',
      guid: '2345F8AC2A461C923709BD7EC3AA2B66'
    },
    {
      name: '吉水县吉泰现代综合农业服务授权保障网点',
      guid: '6FD62CE9B79238E79FE5214F219C7370'
    },
    {
      name: '河北迅捷无人机技术服务授权保障网点',
      guid: '649219D12BBDC841FB5CF5FCD626C238'
    },
    {
      name: '湖北省金色希望农贸授权保障网点',
      guid: 'A9031C55547D206EC78CF400A501E60D'
    },
    {
      name: '当阳市华宇惠民农机销售授权保障网点',
      guid: '56506A994A9F5A0A4BA487A6A925B468'
    },
    {
      name: '临邑县八十八号植保农资旗舰店授权保障网点',
      guid: 'F73ECEA1BE7CFFE86777AB72D09E3911'
    },
    {
      name: '江门市琨鹏农业服务授权保障网点',
      guid: '6E8CB13993932483CA0991D9DFA103A2'
    },
    {
      name: '铁岭润丰农业技术推广服务中心授权保障网点',
      guid: '0525F6D863E17BFF62E9CAF0CFE1264A'
    },
    {
      name: '湘阴鑫赛农机销售授权保障网点',
      guid: '770D62596870F4C2EEADE3C8E6F4E64B'
    },
    {
      name: '新沂市立农农机销售授权保障网点',
      guid: '399AD0C69D4F75A247AD6687528F8ABD'
    },
    {
      name: '获嘉县嘉由农业服务授权保障网点',
      guid: 'FE0AACE667A6B4A61B6E18057886E26E'
    },
    {
      name: '阜阳极飞农业科技授权保障网点',
      guid: '9D33DD92E85D629E594E72B17D61F2D6'
    },
    {
      name: '淮安众联农机授权保障网点',
      guid: '8281D58DE24ABFF736E54DB7016C9DFE'
    },
    {
      name: '安徽省泽文智慧农业科技授权保障网点',
      guid: '410CD662331532F1E878700B57A480E0'
    },
    {
      name: '辽中县亿发农机新民分公司授权保障网点',
      guid: '438B2CCD45A48CDCEB1EF922515E4E9C'
    },
    {
      name: '湖北隆凯农机贸易授权保障网点',
      guid: '221BB69DE76B9CC32A025343339B810A'
    },
    {
      name: '五常市万达农机销售授权保障网点',
      guid: '7F2577FE1478AC01DD3BE3982F87D9CB'
    },
    {
      name: '四川耀农农业装备授权保障网点',
      guid: '3E867C84C07AE4C77B2DE635B162165D'
    },
    {
      name: '兴化市苏盛农机授权保障网点',
      guid: '4C873CD0A38FA213B21D8AF610238499'
    },
    {
      name: '吴江市震丰农机授权保障网点',
      guid: '5F22610F5F5CE03BA990E72164BF0E9F'
    },
    {
      name: '启东市农业机械授权保障网点',
      guid: '536C5AF43A466C6658A43A3A8859B956'
    },
    {
      name: '建三江管理局创业农场洪臣水田物资商店授权保障网点',
      guid: 'DC8A5F32D55E6B0294316E18C36E2D2B'
    },
    {
      name: '滁州云海农机授权保障网点',
      guid: '5CE9CD8C298B2642AA297A9D51EC3D77'
    },
    {
      name: '张北裕丰科技发展授权保障网点',
      guid: '0B5E619C95E78D03A069E8EFFD57B35C'
    },
    {
      name: '江门市三六五农业科技服务授权保障网点',
      guid: '6F05CE8958C712A188BB72BEAA8E2067'
    },
    {
      name: '刘海銮授权保障网点',
      guid: '4671820D3C7E48B61C005B9D55412288'
    },
    {
      name: '扬州田田圈农业科技发展授权保障网点',
      guid: '76D4EA43196AFA539D3E92F5B9CA4B8C'
    },
    {
      name: '佳木斯瑞恒农机授权保障网点',
      guid: 'DA7F95C8ADE6319974BD067201081F53'
    },
    {
      name: '郑孝国授权保障网点',
      guid: '220983AC14A49D28F9D3A236ED72BBB4'
    },
    {
      name: '涡阳县沃野农业科技发展授权保障网点',
      guid: '9944A83AEDB9CAEBD9B90D0BF2199C16'
    },
    {
      name: '新疆丰泽汇商贸授权保障网点',
      guid: 'A095C535C2860B793F734A68D686D7A4'
    },
    {
      name: '乌苏市翔源禾机械作业服务农民授权保障网点',
      guid: 'AB95B2C01BEC0B18530BB552F63AEEA4'
    },
    {
      name: '新疆顺为农业科技授权保障网点',
      guid: '6AFB5708A879A0D77568671E1516BED9'
    },
    {
      name: '宁夏民腾植保科技授权保障网点',
      guid: 'CE70D6A9E631FD4AEAA61A6A40BE8D02'
    },
    {
      name: '南阳鹏睿农业植保技术服务授权保障网点',
      guid: 'BD082BABA07158926CB4237D46648215'
    },
    {
      name: '滑县昌盛科技种业授权保障网点',
      guid: '65E6F4102C027EF14D61714AC0782A26'
    },
    {
      name: '海城市汇丰农机经销授权保障网点',
      guid: 'CD91F3B6CB8E16F3368524A9FF5B8081'
    },
    {
      name: '江阴市陈氏农机授权保障网点',
      guid: '507666B3C49A0BEFF668A8432189096B'
    },
    {
      name: '怀宁县华地飞防植保科技授权保障网点',
      guid: 'EE7F1044684653518D028BB010D1C92F'
    },
    {
      name: '萧县科迪种业授权保障网点',
      guid: 'B99218C7EC6FD128E39B230E773AA6C6'
    },
    {
      name: '沅江市农飞客农业科技授权保障网点',
      guid: 'DDBA0893045E4119BE9C16D31D0645E5'
    },
    {
      name: '昌图县盛泰农机服务授权保障网点',
      guid: '8530096DD95FE9087D88869254A6B9FB'
    },
    {
      name: '徐州汇欣农业机械授权保障网点',
      guid: '81C6E3A459BAF4198565AB3EE17FAD1A'
    },
    {
      name: '新疆翼亩田农业科技授权保障网点',
      guid: 'AD3589738410FD6EBFC82331EFA266EA'
    },
    {
      name: '木兰县极飞无人机农业服务授权保障网点',
      guid: 'D8A2499C1BA304800ED4EBBE94AC6FB6'
    },
    {
      name: '新疆四点零农业科技授权保障网点',
      guid: 'A2478881E04CDB9DB226C9F928A75DA6'
    },
    {
      name: '黑龙江乐飞农业科技授权保障网点',
      guid: '2EA3B2357603D9F3EB15D192B932974E'
    },
    {
      name: '双鸭山市红兴隆胜利农场名智植保机服务站授权保障网点',
      guid: '79A0C0A86A912A42EBDC5643007F8EB3'
    },
    {
      name: '安徽欣旺农业机械授权保障网点',
      guid: '1EEF71A9DB2E9FBEA31F52F5AF97065D'
    },
    {
      name: '南昌吉嘉农牧发展授权保障网点',
      guid: 'E8D43664D01E00C7EA8CAC9B755C313F'
    },
    {
      name: '潜江高峰农业科技授权保障网点',
      guid: 'BC2A3A8B123ECEBFED6F90BF455C2D1C'
    },
    {
      name: '渭南盛丰泰植保科技授权保障网点',
      guid: '78EEA5A926597DBF5CF959A1A17BA822'
    },
    {
      name: '济南福田惠民商贸授权保障网点',
      guid: 'B9BF9B5ED153BD2BF532EA65AE2A5143'
    },
    {
      name: '武穴市供销罗城植保授权保障网点',
      guid: 'DBD1A3A4C978E26CB660C10DE6552A05'
    },
    {
      name: '容城县欣丰农机销售授权保障网点',
      guid: '8653C1F925A2BB0BE876A8AA9F2F804B'
    },
    {
      name: '公安县绿欣植保授权保障网点',
      guid: '63A1A4DD6AF718C41A4E039F5971EA73'
    },
    {
      name: '郑州市丰亿生态农业授权保障网点',
      guid: 'C0F12ADE5DA71A8205F57626C3AC0230'
    },
    {
      name: '阿瓦提县华庆农资授权保障网点',
      guid: 'CE9DCA0F6F3FBCCB71AA020C08F5518B'
    },
    {
      name: '青山润农综合农杂商店授权保障网点',
      guid: '107B3CC54B7597102F9E8AB6747F00DC'
    },
    {
      name: '江苏省洪泽湖农场集团授权保障网点',
      guid: '03E7A6A0D03C3C0A3C8A0A5F5529DAB9'
    },
    {
      name: '徐州飞梦电子科技授权保障网点',
      guid: 'B4F18A4DD6AAEDC1BC31F67CD2759B9D'
    },
    {
      name: '大连云峰农业发展授权保障网点',
      guid: '44E84796E1060EA31AB3E4B9604F88E5'
    },
    {
      name: '宁波市奔田农机销售授权保障网点',
      guid: 'E463FAB1124F2666BE468F18233C87D9'
    },
    {
      name: '河南虎克农业科技授权保障网点',
      guid: 'F5736A496443513219A3F8E184676F80'
    },
    {
      name: '淮安胜农农机授权保障网点',
      guid: '5EB6CFD7AFA72F0DEB9C261303C50AF4'
    },
    {
      name: '汶上县俊杰商贸授权保障网点',
      guid: '113B30D4989B70D368E3890FF5C441C9'
    },
    {
      name: '鹤岗市五丰农机销售授权保障网点',
      guid: 'B6C2CE2E0406AFCAC10B8EB2CFB926D4'
    },
    {
      name: '巴州贰十四农业科技授权保障网点',
      guid: '71BF05A0504375CA2874E95603BB5524'
    },
    {
      name: '樟树市现代农机机电授权保障网点',
      guid: 'F65798C6B4B386053AD9CF60BDD7D814'
    },
    {
      name: '抚远市寒葱沟镇迅飞无人机销售中心授权保障网点',
      guid: '5975C8C280E10C61E0685F07570F5D7B'
    },
    {
      name: '黑龙江省建三江农垦丰悦农业科技服务授权保障网点',
      guid: 'D37CE6DABD68051E43585022CCE9658C'
    },
    {
      name: '崇仁县护农种植授权保障网点',
      guid: 'F840D860494FDFDC9B6D874B06650F3E'
    },
    {
      name: '邓州市明星农作物种植授权保障网点',
      guid: '529F392DD51D4356B2D09D28A39A303F'
    },
    {
      name: '安徽农服大圹圩农业服务授权保障网点',
      guid: '92F07F448C06A9FBEA1BC5E2A95C023D'
    },
    {
      name: '盘锦淇霖农机授权保障网点',
      guid: '8DC73540C128B2510CC49B88DD723E18'
    },
    {
      name: '吴海龙授权保障网点',
      guid: 'EE1E1113649271C0EC5BD25EBA1C9F88'
    },
    {
      name: '莱西金丰公社农业服务授权保障网点',
      guid: '1E91D14B9967D04EEA430C3CF992493D'
    },
    {
      name: '吉林省雨莉丰无人机植保科技服务授权保障网点',
      guid: 'E8AAA45C4D4FE0D87C748D00A4C044FF'
    },
    {
      name: '淮南市天瑞航施植保服务授权保障网点',
      guid: 'C406D9D4CB16A72DF271655842626920'
    },
    {
      name: '新疆启未来农业科技授权保障网点',
      guid: 'AE6763D4233956246EF6BEA82E127202'
    },
    {
      name: '石河子市鑫久顺农业科技授权保障网点',
      guid: '4DDB54443D93644C5A9CE633211F31C0'
    },
    {
      name: '新干县川飞病虫害防治授权保障网点',
      guid: '7A0E565966211EA05C759FB59465131E'
    },
    {
      name: '江苏省农垦农业发展股份临海分公司授权保障网点',
      guid: '3E56EFF7116EF14F651835005269E51B'
    },
    {
      name: '建瓯市众宏农资经营部授权保障网点',
      guid: '16CC98CFC5EFB11616F80A93A6F08B8B'
    },
    {
      name: '荆州市明蓝农业科技授权保障网点',
      guid: 'B59C46F0D41CAA2A99F1920A44FF212F'
    },
    {
      name: '河南丰易农业服务授权保障网点',
      guid: 'BA74339C7C1416B26BFC8E4A10C2CBDB'
    },
    {
      name: '山西夏盛楠农业科技授权保障网点',
      guid: '01E81D454892D532A82AF3173906EBCA'
    },
    {
      name: '华容县诚业农机授权保障网点',
      guid: 'DD33ADCABEE30004A5DCD91155E5A2DA'
    },
    {
      name: '通辽市新经纬测绘仪器授权保障网点',
      guid: '73FF5F4FA178DFE953191A5A6133AA26'
    },
    {
      name: '阜新蒙古族祥瑞农机销售授权保障网点',
      guid: 'FB644D5E8FFF0E2B1867E50236CAD905'
    },
    {
      name: '中卫市禾翼生态农业科技授权保障网点',
      guid: '4D84072ECC6534E7FB842FAB7F004A26'
    },
    {
      name: '齐河县标普农业科技授权保障网点',
      guid: 'CD52F3B741B7E36B6FD6B2DDDC96EAED'
    },
    {
      name: '黑龙江省建三江农垦丰悦农业科技服务授权保障网点',
      guid: '31B2D9F46EBEF971CC12FF37B0167C1C'
    },
    {
      name: '浙江极智农业开发授权保障网点',
      guid: '3886A04809A4625A4CB0D8DDC481DF94'
    },
    {
      name: '鄄城县助农农业发展授权保障网点',
      guid: '20918B30A5E676FBF049F30E825B83F8'
    },
    {
      name: '高密市邦升农业授权保障网点',
      guid: '45D7B973C5D2C9D407FBF1DC074EC6F1'
    },
    {
      name: '上海昊燊农业机械设备授权保障网点',
      guid: '16C8936D7345FDBEA2AB90DDA8C51D70'
    },
    {
      name: '万载飞擎农业授权保障网点',
      guid: '9B104E7A19E66FCEC52FAD3075BA4A3D'
    },
    {
      name: '寿光市合景农业科技授权保障网点',
      guid: '98C33D716C11571DC5881FCB215D7EF8'
    },
    {
      name: '扬中市裕衡农机服务授权保障网点',
      guid: '86DABD7B3272966F36E63CDB789E9BD2'
    },
    {
      name: '吉林秋歌农业科技授权保障网点',
      guid: '8E88C724BC237379578895260FB42247'
    },
    {
      name: '兰陵县苍垦种植农机化服务授权保障网点',
      guid: '2A14DBD3539BE9265A261E9049A23365'
    },
    {
      name: '抚远市抚远镇金丰农业科技授权保障网点',
      guid: '6DEA638F6AE587B23BFF93BCCAD045EF'
    },
    {
      name: '廊坊绿园农业开发授权保障网点',
      guid: '6572EA9356CC3F8D2942EC0AA6EBAA4C'
    },
    {
      name: '洪湖市金旭农业机械服务授权保障网点',
      guid: '6B37B55A9CDCF73EDD9F873592BDC2E5'
    },
    {
      name: '定州市第一农机授权保障网点',
      guid: '985F9AA623116C80CAEDE34EF1EF1D39'
    },
    {
      name: '绥滨县精准农业科技授权保障网点',
      guid: 'AFD5FBF57FA7F8C1DA1497420346E156'
    },
    {
      name: '温县永生农业植保授权保障网点',
      guid: '75D9A0ECE3B44926667189FED124FCDA'
    },
    {
      name: '扬州市江都区高丰农机授权保障网点',
      guid: 'A2A724687F71D0E6C5FBD6D813031943'
    },
    {
      name: '彭泽县昌隆植保授权保障网点',
      guid: '0D03F784A17AAF23800F52286F0E1B18'
    },
    {
      name: '北安市二井镇益泽大豆种植授权保障网点',
      guid: '373F87FAFD43B321888A63C249CA5CAA'
    },
    {
      name: '湖南锐为农资服务授权保障网点',
      guid: 'B886A5D93F17B160096CCFF8DE979F3E'
    },
    {
      name: '淮安市耕耘农机授权保障网点',
      guid: '62A1A3EA6C788E7A92A7EF84E8842819'
    },
    {
      name: '湖北丘旌智能科技授权保障网点',
      guid: '3E06F8C6DF65274CA4340559E7571571'
    },
    {
      name: '福建合新智能农业科技授权保障网点',
      guid: '418D74925D8A287019CF7A28C64C08B9'
    },
    {
      name: '甘肃极云农业科技授权保障网点',
      guid: '284A62A963AF3483B5645BAEBCD47FD9'
    },
    {
      name: '辽宁稻香园农业科技服务授权保障网点',
      guid: '169D8254E5DEDC3098325211A8F9EB34'
    },
    {
      name: '龙岩市擎农农业机械授权保障网点',
      guid: 'E2641E1CCACDFC2792C53DD8D2211957'
    },
    {
      name: '岳阳新和美农业科技服务授权保障网点',
      guid: 'F6D4751F03E2EC5A77DEE99C4097059F'
    },
    {
      name: '常德市德飞农业机械销售授权保障网点',
      guid: 'A1A996CA0E90571B35FF53C35EC09968'
    },
    {
      name: '绥化市极飞农业科技授权保障网点',
      guid: 'FB52F728DAC842F3426AE68627E6B2C3'
    },
    {
      name: '友谊县农聚福农业服务授权保障网点',
      guid: '934449C0827EBC13E380ED299168F5D1'
    },
    {
      name: '湖南省大地飞歌农业发展衡南县分公司授权保障网点',
      guid: '126404FD6569A652172F29D101E58172'
    },
    {
      name: '菏泽市仁达农业机械授权保障网点',
      guid: '993B7A75652CD033AC448747A76ABC74'
    },
    {
      name: '青岛强地农机销售授权保障网点',
      guid: '6A909F927374208D7F74937706D93605'
    },
    {
      name: '云南神飞航空植保技术授权保障网点',
      guid: '069A003D9B3036A4B27A8858A31BCAAD'
    },
    {
      name: '新和县天翔农业科技授权保障网点',
      guid: '2DE0D7C43AAD4E1686E817E209426B00'
    },
    {
      name: '吉木萨尔县红旗农场农富裕牧业授权保障网点',
      guid: 'C2EB61E23C55F71010818191DE806090'
    },
    {
      name: '徐龙授权保障网点',
      guid: '1DA1ED6381AAA55FE61B659AFBBBD5F5'
    },
    {
      name: '精河县瑞禾农业科技授权保障网点',
      guid: '07D3EB2F3478FDA05E7C4985095911AB'
    },
    {
      name: '河南广尔拓农业科技授权保障网点',
      guid: '7AD9B505DB69465451E8B037FA84F57A'
    },
    {
      name: '郑长征授权保障网点',
      guid: 'F5D53892EE9B8B09A132C0C2164A0B7D'
    },
    {
      name: '松原市众合农业科技授权保障网点',
      guid: '08FC29188DEE16F6E98A6CB6D336DAE7'
    },
    {
      name: '安庆市乐飞农业科技授权保障网点',
      guid: '5C8041C4468E37BCD974C1AF8538565B'
    },
    {
      name: '威海华仁电子授权保障网点',
      guid: '3EE5CC770E494634646418E8C7667B26'
    },
    {
      name: '茌平龙鑫农机授权保障网点',
      guid: '4899CBADE469EF4FF186E85C9F7C6434'
    },
    {
      name: '宁夏绿先锋农业机械化服务授权保障网点',
      guid: '4072184337430E94E68CBB7FE1277E2C'
    },
    {
      name: '开原市禾顺农机销售授权保障网点',
      guid: '3CA96A767DEA05B53DAE0D62CF22E0A5'
    },
    {
      name: '内蒙古新极飞商贸授权保障网点',
      guid: 'BAD250DB55F8B8A1A11FE5CAE3F99D2F'
    },
    {
      name: '安乡县农飞客农业科技授权保障网点',
      guid: 'F79EF0447CC2D45A7CD7BFD3D4E3C08F'
    },
    {
      name: '邵武市鑫祥农机销售授权保障网点',
      guid: '594FFB1FD25323C56B50C3C1940C663C'
    },
    {
      name: '肇源县蓝蜻蜓农业科技授权保障网点',
      guid: '5661D4D5DFE9E0BFA93EDABA27392254'
    },
    {
      name: '四川福荫自动化授权保障网点',
      guid: '841938624685617E4933B447CAC4831B'
    },
    {
      name: '鱼台县三高机械授权保障网点',
      guid: 'E34E443799DAF1D7EF05BFADEC939772'
    },
    {
      name: '和布克赛尔蒙古坤元农作物种植授权保障网点',
      guid: '4F2C5EB2BC908082BE8F74C3BBDBE2F9'
    },
    {
      name: '霍城县清水河镇鑫涛农资经销部授权保障网点',
      guid: '94B1280F2D52D3EA2C1FDC0169D2FC05'
    },
    {
      name: '郓城迎朋农机授权保障网点',
      guid: '76FAB0DAD6F807AAD60651CFC3E3784D'
    },
    {
      name: '滨海永丰农机授权保障网点',
      guid: 'DBD4929CA0CE94C93F33C1CC3706D0B1'
    },
    {
      name: '陕西致远天航信息科技授权保障网点',
      guid: 'DB6061764B7A9467A23D30EB95755806'
    },
    {
      name: '隆尧野田农化授权保障网点',
      guid: '11C9C293ED9F0FC519676EB82712E0BD'
    },
    {
      name: '桦川县盛禾极飞植保无人机店授权保障网点',
      guid: '13A5751DAB304701B81B0A181949459A'
    },
    {
      name: '新野县丰泽农业科技授权保障网点',
      guid: 'A19FCC088623ED5E166E6A9DD7B33D74'
    },
    {
      name: '公主岭市宏腾农业机械授权保障网点',
      guid: 'AD3DC5DC641187B0559DE641B978816C'
    },
    {
      name: '广饶县荣跃农业机械授权保障网点',
      guid: 'D0CBA958685968C4324F21DEC56CD7EC'
    },
    {
      name: '江西瑞凯机械装备授权保障网点',
      guid: '04EDCFEA672A5D06775EB065D1214EFF'
    },
    {
      name: '郑州中逸农业授权保障网点',
      guid: '6CF1AE394BD1AB678FF39C8F4C8A2A87'
    },
    {
      name: '金华吉康农业机械设备授权保障网点',
      guid: '854F4420219C0772F0F7C3DD26383FA6'
    },
    {
      name: '同江市极飞无人机商店授权保障网点',
      guid: 'D1880F0F19D6A61517B9C8E2180A97EC'
    },
    {
      name: '江西佳旺机械设备授权保障网点',
      guid: '3F9E18EF3FD6EE0628554383DA6980D6'
    },
    {
      name: '岳阳市屈原管理区宝塔种养授权保障网点',
      guid: 'E995743EAE929D5E6BDDA06C7E7D5CD5'
    },
    {
      name: '武汉星测空间信息技术授权保障网点',
      guid: '1570460BEA813774329F09CD05D2F380'
    },
    {
      name: '通城宏伟农机授权保障网点',
      guid: '942EA4A63B96AFBA96DB2E622F76A9E2'
    },
    {
      name: '甘肃旭航智能科技授权保障网点',
      guid: 'F4D4E0B5144BEA84D12B4BDCA3950E70'
    },
    {
      name: '吉林省升泰农业生产资料授权保障网点',
      guid: '1810211437DA42C08B445A3C47CD6104'
    },
    {
      name: '漯河豫丰种业授权保障网点',
      guid: '91AF8794A72CA1EFCE6737741FAC7A42'
    },
    {
      name: '尚志市稻盛农机销售授权保障网点',
      guid: 'A87495E6D308A191BE97378F39E0EDDF'
    },
    {
      name: '宜城市楚飞无人机科技授权保障网点',
      guid: '52F87D567C3084ED420A3FB572207246'
    },
    {
      name: '瓜州县鼎峰农业科技授权保障网点',
      guid: '555154C3FF26C75F8BEC192B1A73FF31'
    },
    {
      name: '肥城市金地晓农资授权保障网点',
      guid: '64EDDB1C41346C7E37F6748F248AB380'
    },
    {
      name: '汤原县梧桐河讯飞植保无人机专营店授权保障网点',
      guid: '0322F8D8F2ECCAA9AA5E2AD2EC93C96A'
    },
    {
      name: '黑龙江省建三江农垦极飞农业植保科技服务授权保障网点',
      guid: '4986FB8908ACAC184A02E45A3DC68658'
    },
    {
      name: '黑龙江省辰月农业科技授权保障网点',
      guid: '65709F8D3A63A08CE3AA888033423B3C'
    },
    {
      name: '内蒙古极创无人机技术授权保障网点',
      guid: '85F178688EA114BA58C919F19798F07E'
    },
    {
      name: '河南翰宇农业科技授权保障网点',
      guid: '8B3BF4B4FB4EA01C251F19EFCC33E20C'
    },
    {
      name: '海门市农业机械授权保障网点',
      guid: 'F535FF753D38980F485CA2FFDFF0BF52'
    },
    {
      name: '内蒙古司南智慧农业授权保障网点',
      guid: '4DC0591B9166A5E3F4F48558B156BFC6'
    },
    {
      name: '哈尔滨艾克瑞农业科技授权保障网点',
      guid: 'E117F29EDB1C04F976ED3A9C0BCA9390'
    },
    {
      name: '惠州市裕玖农业科技发展授权保障网点',
      guid: '1485DBD0006602BD3DD187FC3B5C687F'
    },
    {
      name: '河南绿保科技发展授权保障网点',
      guid: '5591079AA40AB2991430EB20D4069751'
    },
    {
      name: '黑龙江华夏统联农业技术开发授权保障网点',
      guid: '281F269BDC46109297659DB221E8C579'
    },
    {
      name: '伊犁乾宸农业科技授权保障网点',
      guid: 'E133E71AFC4230A159632524972F90B3'
    },
    {
      name: '莲花县惠飞农业发展授权保障网点',
      guid: '40EC56AC345A96B0D87B8F235AC43C3C'
    },
    {
      name: '七台河市千盛农资商店授权保障网点',
      guid: '6DB5268D2BD966FD16CB71AE2B643E4C'
    },
    {
      name: '蚌埠市淮海农业科技授权保障网点',
      guid: 'A071344A9CB5217BFB1E375DC545BE20'
    },
    {
      name: '沙县卧农农机机电授权保障网点',
      guid: 'F6776D6B899E167DB1CFAB38E57768EE'
    },
    {
      name: '铁门关市丰收农资授权保障网点',
      guid: '4254A0B4A097360CFA56FB1C62FEA79F'
    },
    {
      name: '灵璧县金地农业机械销售授权保障网点',
      guid: '476BA0EE16427F365F361F6E98670ABF'
    },
    {
      name: '河北犇垚农业科技授权保障网点',
      guid: 'E247492B8D262BF79A18E2120FFDE0A9'
    },
    {
      name: '高唐县庆余农机销售授权保障网点',
      guid: 'B88EF53B452853D5A6EE1903248D7DC8'
    },
    {
      name: '双鸭山市红兴隆二九一农场极飞植保无人机商店授权保障网点',
      guid: 'D27517E3FAB6B2F1D96A0B766CA89DF0'
    },
    {
      name: '宁津县惠群共创农业种植农民联合社授权保障网点',
      guid: '18632B57CA2DF3C3600A816104ABD92D'
    },
    {
      name: '东阿福民农机授权保障网点',
      guid: 'E0F2F02C83F22DCB70701197BFC5CEA8'
    },
    {
      name: '山东神舟病虫害生物防控授权保障网点',
      guid: 'BEA1C0FFDA9618E070E66FF68DE13ADB'
    },
    {
      name: '密山市九天农业科技授权保障网点',
      guid: 'F1BD1EFE2D4E19D8406D02A47F107B2E'
    },
    {
      name: '巴彦淖尔市灵达农机授权保障网点',
      guid: '6093681C201E0845B0083BBAC748CF58'
    },
    {
      name: '汤原县铸成农机销售授权保障网点',
      guid: '1EC7B79BB0B1D27F1C035D08AC0244A5'
    },
    {
      name: '巴彦县顺邦农机销售授权保障网点',
      guid: 'BF33B2B723CCBE2F49E23A3ABC19E088'
    },
    {
      name: '枣阳市海霖农机授权保障网点',
      guid: 'F24C89BDE5C3B816251246EAACDE3F7E'
    },
    {
      name: '信丰县富民农化服务中心授权保障网点',
      guid: 'F751EC5062F46D6A18B5A746EA086D69'
    },
    {
      name: '极飞东莞直属服务中心（2）',
      guid: 'DD208EAF78A890890D8FA815DE46E86A'
    },
    {
      name: '淮安苏欣农机授权保障网点',
      guid: '081318E4107E78B92435BC3C8C1F9247'
    },
    {
      name: '罗定市丰收农业机械授权保障网点',
      guid: '80F2C46CB13F52D7F7719A113F0D2ED5'
    },
    {
      name: '余干县金丰公社农业服务授权保障网点',
      guid: '0A21A03A1AD72F649FA178BD352E66B9'
    },
    {
      name: '南丰县合农农机服务授权保障网点',
      guid: '60B6DC4802D1E1495DEAFABA0A32F7E3'
    },
    {
      name: '江西恒冠农业服务授权保障网点',
      guid: '2941D9C30A026C6527CDF61C8A34FF80'
    },
    {
      name: '洛阳茁壮肥业授权保障网点',
      guid: '748F83A6E19D97585F0B109C1D3E2F44'
    },
    {
      name: '长春汇丰农资授权保障网点',
      guid: '7263E2E309DA548FB92E6B462D54C891'
    },
    {
      name: '农安县丰盛农机授权保障网点',
      guid: '02DA44FD0E951E9F9481B271BC5F5245'
    },
    {
      name: '扶余市云盛农机授权保障网点',
      guid: 'E2B80684C417B95BA33986D05FC42629'
    },
    {
      name: '攸县富民农机销售授权保障网点',
      guid: 'C26B055F8EA59F96710D532BB0E17786'
    },
    {
      name: '广西叶绿体农业科技授权保障网点',
      guid: '06B17877EF2399F0F1C038E84E3E5019'
    },
    {
      name: '哈密地区新发农业机械供应授权保障网点',
      guid: 'A8D640DF67394E51A0CEF4B2F9642B77'
    },
    {
      name: '山东禾多美农业服务授权保障网点',
      guid: '7858E4ADF74112552970A5FFA6B26CDF'
    },
    {
      name: '奎屯宏鹏农业科技授权保障网点',
      guid: '3F4B5654E081BBF3D539EFA25DC7C11A'
    },
    {
      name: '固镇县保丰植保授权保障网点',
      guid: 'A01A5FC3F06AF6CDA382308FE5C1DC37'
    },
    {
      name: '荆门市兴沃农业机械授权保障网点',
      guid: '2BF664560B6262F12893DBEBFF786DAB'
    },
    {
      name: '奎屯师诚农资授权保障网点',
      guid: 'C2F5180DBB42EB1B86D73ABEE219A71E'
    },
    {
      name: '临海市穗香农业机械授权保障网点',
      guid: '8C4B3B53A7B8AB1823605BFA897A6AAC'
    },
    {
      name: '凌海市大凌河街三和农资经销处授权保障网点',
      guid: 'E95A07E1106D806C53CADB76E34CB745'
    },
    {
      name: '萝北县恒田农业科技开发授权保障网点',
      guid: '869D460DB2D39DEC513094E0717DD497'
    },
    {
      name: '洮南市极飞植保无人机经销处授权保障网点',
      guid: 'DE8FBBCBF95C1AC37305A8967E477697'
    },
    {
      name: '陆丰市三农农机授权保障网点',
      guid: '8B37DDE93CDF700199F1E6097DAC8CE6'
    },
    {
      name: '察布查尔锡伯科农农资经销部授权保障网点',
      guid: 'ACDB0A6BECA7280F960007CBD8A88656'
    },
    {
      name: '冠县云丰农资服务部授权保障网点',
      guid: '9A0CA4CD0FA85AACF37D0E9E9CD1915B'
    },
    {
      name: '太康县超顺汽车贸易授权保障网点',
      guid: '7B72467A27EAA77877E385F2B71A0AD0'
    },
    {
      name: '广昌县亲农乐农机植保授权保障网点',
      guid: '8AE59B10B8999CA352491C9157A8368D'
    },
    {
      name: '郸城县峻熙种植授权保障网点',
      guid: 'F8EB5963899566D9F6AC3B1994138EC4'
    },
    {
      name: '杜尔伯特蒙古族惠农源农资店授权保障网点',
      guid: '407A84DF6819EB1898FDD2356825A23D'
    },
    {
      name: '洪洞慧飞农业科技授权保障网点',
      guid: '61C6F49268F7FD317F9D7D85FB7D975E'
    },
    {
      name: '敦化市天增长农机授权保障网点',
      guid: '12DEE01263D08E5BEC8E434CDC7280ED'
    },
    {
      name: '舒兰市云刚农机授权保障网点',
      guid: '2CF4F114D40F573ECC330EDC250C5F4D'
    },
    {
      name: '壶关县飞雄农业科技授权保障网点',
      guid: '0ECBF3C3DBBB971591F7D97F794DE7AD'
    },
    {
      name: '安仁县鑫亮粮油发展授权保障网点',
      guid: '2864F6B09211559781F01DC0AB9BE62F'
    },
    {
      name: '泰和县惠万家农资连锁授权保障网点',
      guid: '10D68E0DD30596B9698E9FFFC5D5D88C'
    },
    {
      name: '青岛春翔农机销售授权保障网点',
      guid: 'D487D018710014AF610FFDCF57DD443C'
    },
    {
      name: '遵化金丰农业服务授权保障网点',
      guid: 'E651D365C14ABB5567CD4F9CE4D4AE7F'
    },
    {
      name: '济宁市任城区金丰公社农业服务授权保障网点',
      guid: '32C90F0A78BEC954CFB45E767B48E93A'
    },
    {
      name: '襄阳市襄州区金丰农业服务授权保障网点',
      guid: 'F3CF0AD497F0E6E72E9F287B70CE0390'
    },
    {
      name: '漯河金丰公社农业服务授权保障网点',
      guid: '70F3C155C0BA39131A34BCF6C5774D07'
    },
    {
      name: '阿拉尔市疆耀飞植保服务授权保障网点',
      guid: 'C31A9EC2EF3E40A5987FF3BFF1D918CE'
    },
    {
      name: '榆树市聚源农机授权保障网点',
      guid: '4B2E844FAC977F60EFB481EC9DB69DCE'
    },
    {
      name: '西安零点无人机科技授权保障网点',
      guid: 'CAF91474E891CF606495BA424A8F9F17'
    },
    {
      name: '湖北荆宏无人机科技授权保障网点',
      guid: '623913ECA2BC87F70547CE99C8B1C954'
    },
    {
      name: '轮台县蜂鸟农业科技授权保障网点',
      guid: '35E1F80EEAD430ED82209EB90605D6F0'
    },
    {
      name: '安仁县张峰农机销售授权保障网点',
      guid: 'FE9DBD7AA0B62B6D4C06B17F3AC2F43B'
    },
    {
      name: '山东荣建智慧农业授权保障网点',
      guid: 'D64DEAAF392F4A408542E27757CA34BE'
    },
    {
      name: '怀化市正泰农机销售授权保障网点',
      guid: '4009B2EE9BB6FA549313C90243DB06CF'
    },
    {
      name: '河北润谷农业科技授权保障网点',
      guid: 'A867011027C0B41CAD85653F33192AB4'
    },
    {
      name: '河南标普农业科技授权保障网点',
      guid: 'AA867E24B1338097020E60DC5B3BCCC9'
    },
    {
      name: '建湖县福临农业装备授权保障网点',
      guid: '0E85775112F51408068F04CEB72F55CA'
    },
    {
      name: '灌南县久洋农业机械销售授权保障网点',
      guid: '71EED415FA0B12C87D852846C52AAA61'
    },
    {
      name: '甘肃绿康智慧农业科技授权保障网点',
      guid: '7A901B56D688F013AB84610AE56F42AA'
    },
    {
      name: '宣城市宣州区金丰农业服务授权保障网点',
      guid: '031C3F2D9743158C92C94A9586FA7E64'
    },
    {
      name: '玛纳斯县唐氏兴万家农业科技发展授权保障网点',
      guid: '88B148715464FDFB7AB2190181F5818D'
    },
    {
      name: '鹤岗市晶洋农机授权保障网点',
      guid: 'B2B67D9E6CBE4C3B5691351DF1E762FE'
    },
    {
      name: '仙桃市金丰公社农业服务授权保障网点',
      guid: '6B2A97F05AB80F090F91FE5AD67232DA'
    },
    {
      name: '淮安市金湖县金丰农业服务授权保障网点',
      guid: 'D2518C93E2E6A43237E497C38CAD46FB'
    },
    {
      name: '桓台县金丰公社农业服务授权保障网点',
      guid: '4A22EB1A9C33075C7B4ADFDED378282D'
    },
    {
      name: '阿鲁科尔沁旗新胜农机商贸授权保障网点',
      guid: '214A74406C8440AD5CE075A63DCF429C'
    },
    {
      name: '且末县旺胜飞防植保科技授权保障网点',
      guid: 'AED0531DE44BD5B86D742E945372EE09'
    },
    {
      name: '江苏方强农场集团授权保障网点',
      guid: '14BFB01E6A23CE13B9A41862C2236C1C'
    },
    {
      name: '南皮县盛美农资商城授权保障网点',
      guid: '077FECDECF82EBD473E996C706FB46A9'
    },
    {
      name: '赣州市亿发姆智慧农业服务授权保障网点',
      guid: '0D6F039033274924F71847A056926D19'
    },
    {
      name: '溧阳市溧城东兴机电经营部授权保障网点',
      guid: '5BAA58F95E7C29F994C74C88F6E5AC7E'
    },
    {
      name: '通河县极飞农业发展授权保障网点',
      guid: '5B1F8051396D64E492232B869CBD7FFF'
    },
    {
      name: '太和县极飞客农业科技授权保障网点',
      guid: '691A59EAF94480184BDD573273A221AA'
    },
    {
      name: '吉林省梅舒农业服务授权保障网点',
      guid: 'C057EA858D76495EBE37763EA1F7EBCA'
    },
    {
      name: '溆浦农飞客农业科技授权保障网点',
      guid: 'CD7E104FDE3DEAC0A4A1B8CB6AD2621D'
    },
    {
      name: '蕲春县富祥农机授权保障网点',
      guid: 'EF91A559660C63D2B395300C4E058803'
    },
    {
      name: '榆树市大坡镇旺达农药化肥经销处授权保障网点',
      guid: 'C6DC9C68A7A003A0627B40354FF595F2'
    },
    {
      name: '曹县昌发农机销售授权保障网点',
      guid: '16E854BE22E1A9F5E8D1C33A3AA1AE07'
    },
    {
      name: '长丰农哈哈农业机械授权保障网点',
      guid: 'C078BC057912CAF2D608C6BB7899CFD3'
    },
    {
      name: '微山县欢城镇沃野种植授权保障网点',
      guid: '05FC22FDF3B3730B318F6D16D47768D9'
    },
    {
      name: '莘县农发农机授权保障网点',
      guid: 'C68CD434154AE95C91E1882AA75919B7'
    },
    {
      name: '民勤县金丰公社农业服务授权保障网点',
      guid: '474F4F09A8C2395D0E11B6CC376F92C0'
    },
    {
      name: '开鲁县天丰农机授权保障网点',
      guid: 'D075FF123A8ADD165C0319DCD6340121'
    },
    {
      name: '太和县金丰公社农业服务授权保障网点',
      guid: 'CDC03B7C2AD8494E4196F32180A9F6F9'
    },
    {
      name: '菏泽市牡丹区金丰公社农业服务授权保障网点',
      guid: 'A7EBCCBA79280F2B3B105597C1CB73ED'
    },
    {
      name: '青海极沃农业科技授权保障网点',
      guid: '17661ABEC9E3B0EF2F13416CED103627'
    },
    {
      name: '曲阜金丰公社农业服务授权保障网点',
      guid: '9BF909F3BAAA0F37214B97C1A70A5C40'
    },
    {
      name: '醴陵市颖达农业科技授权保障网点',
      guid: '6EFC68C0C3CAE734C580C571369C6797'
    },
    {
      name: '临沂易耕农业科技授权保障网点',
      guid: '5E75F2338EE183C56AF8A3F700600AAD'
    },
    {
      name: '怀宁县强龙农机销售授权保障网点',
      guid: '2BFD52C42696F7881C38368E968D8127'
    },
    {
      name: '绍兴市三久农机授权保障网点',
      guid: 'B3727564002803D462E547240AFF5E84'
    },
    {
      name: '伊犁鑫汇农商贸授权保障网点',
      guid: '342758811C95313B47C86AF96870AA32'
    },
    {
      name: '湖北至简农业服务授权保障网点',
      guid: '00B497701586F0D35421C53709EC9CF7'
    },
    {
      name: '霍邱县丰沃农机销售授权保障网点',
      guid: 'E4247216ED023F9FD35B075D5EC59A6C'
    },
    {
      name: '长兴和谐家庭农场授权保障网点',
      guid: '1D624DCEF4ACF263A42FB1456345B4B1'
    },
    {
      name: '宁安市祥臣农业科技开发授权保障网点',
      guid: '297A93BFEA46D8DCDB8DA2470D120A89'
    },
    {
      name: '云南得森现代农业装备授权保障网点',
      guid: '666B4918BE373591BD4047EB7081DD1B'
    },
    {
      name: '云南天华智能系统授权保障网点',
      guid: '8F94B7A9B8EE1B6B5B5AD4C10CC9AC71'
    },
    {
      name: '陕西唐德纳尔农业科技授权保障网点',
      guid: 'A116063B3BD487A6C82DA5321C775376'
    },
    {
      name: '库车县昌盛农机授权保障网点',
      guid: '0076015679BF98D375B453BE55F28E6C'
    },
    {
      name: '山西全无敌无人机授权保障网点',
      guid: 'B57371BE80F7AA803B18BA2575AB3D24'
    },
    {
      name: '尉氏县翼达农机授权保障网点',
      guid: '9AAE0165F4E406EF043067C9B998878D'
    },
    {
      name: '乐平市乐飞农业发展授权保障网点',
      guid: '57DAF6E104BA1004CF61F751FB41F858'
    },
    {
      name: '邯郸市邯山区金丰农业服务授权保障网点',
      guid: '687E1FA283B19F99752F18B3AAAA338E'
    },
    {
      name: '单县姬胜利植保授权保障网点',
      guid: '795C048A17466DD2B09ADC07EE92D848'
    },
    {
      name: '常德市鼎城区金丰公社农业服务授权保障网点',
      guid: '22B0CD662633B63ED32CF5751127BAA3'
    },
    {
      name: '永州市顺农农机机电设备授权保障网点',
      guid: '86F115205EB7326B5FF495C2FA07EDF0'
    },
    {
      name: '射阳县金丰农业服务授权保障网点',
      guid: 'B8540F762C804C5D39DC732A40EEF744'
    },
    {
      name: '柏乡县金丰农业服务授权保障网点',
      guid: '25EEC9787E8572A09933BFABC6F9EF2A'
    },
    {
      name: '南县金丰公社农业服务授权保障网点',
      guid: '838F612192E82E21659F1F5FF19AF037'
    },
    {
      name: '东营市东营区金丰公社农业服务授权保障网点',
      guid: '9000C800482FC6CAECC8382AAB86C0B2'
    },
    {
      name: '沈丘金丰公社农业服务授权保障网点',
      guid: '17E9A7B9A089F92B828F212101BCFC5C'
    },
    {
      name: '桐城市双新农业机械销售授权保障网点',
      guid: '04DCA6F58BF8AFE2CB4667E6C62EE8E8'
    },
    {
      name: '河南省天祯农机授权保障网点',
      guid: '71B3D1F88E09FCEA9554F022154161E4'
    },
    {
      name: '双鸭山市红兴隆荣邦水井工程服务授权保障网点',
      guid: '2EB70AEE9AC936457B6C6EA44E12FB96'
    },
    {
      name: '柳河县润萍商贸授权保障网点',
      guid: '904481AB92D760F98A3968EC82BC98A8'
    },
    {
      name: '云南省玉溪市农业生产资料授权保障网点',
      guid: 'F03A456526623DC264208349AA1FCCE5'
    },
    {
      name: '常德市宏瑞奇植保服务授权保障网点',
      guid: 'E7E3CE21DC89431B010F8CA93C4D981F'
    },
    {
      name: '稷山金丰公社农业服务授权保障网点',
      guid: '9774200A32F0E19B3C4CAE31FDA8E2B7'
    },
    {
      name: '甘肃思源智慧农业科技授权保障网点',
      guid: 'BF55CE4EA3604B674DB85F585414A434'
    },
    {
      name: '淮北大迈农机销售服务授权保障网点',
      guid: 'DCCC1BAC27D5B6D908F672D9FD152362'
    },
    {
      name: '白城市恒科农资商店授权保障网点',
      guid: 'C3E3F26813C43BBCB38D8D235D64B077'
    },
    {
      name: '盐城润田植保服务授权保障网点',
      guid: '2B46CD68FEC4B433A92C49FE9F4A8FA3'
    },
    {
      name: '饶阳县雄峰农业服务授权保障网点',
      guid: '42EA78AC317B8A404A2DFE44092BC452'
    },
    {
      name: '英德市新一代农机授权保障网点',
      guid: '60F6024A566386665A561795F2ED0B84'
    },
    {
      name: '叶县极飞植保服务授权保障网点',
      guid: 'D69FCDBCC36E8B594DD68B2227BBDE0A'
    },
    {
      name: '北京顺海丰农农业科技发展授权保障网点',
      guid: '1BDE784DF8FF0912D648AC7C801DC828'
    },
    {
      name: '三门力鼎农业设备授权保障网点',
      guid: 'ED4EAC956558FA9DB2760FAD805E8FFC'
    },
    {
      name: '山西塞北星农业科技授权保障网点',
      guid: '24B9598267AE00EF6300CA93906AF250'
    },
    {
      name: '精河县众拓智能农业授权保障网点',
      guid: '3583BB6165DBE0BD2FCE81D530B66736'
    },
    {
      name: '兰西县极飞智慧农业科技授权保障网点',
      guid: 'DCF6876224EB8DAE37AC0B185A6BF116'
    },
    {
      name: '宁夏科辰农业科技授权保障网点',
      guid: '1C19B3395C9D9C787E118D3AE86F23E7'
    },
    {
      name: '永康市惠丰农业机械授权保障网点',
      guid: '2B35AE3D16CC8A9B9F65682512607A4F'
    },
    {
      name: '瑞安市首飞飞防植保服务授权保障网点',
      guid: '6CA22416DAF787C8D87852E7802AF802'
    },
    {
      name: '岳普湖老李农业科技授权保障网点',
      guid: 'F302B045C7E4822F94B4FEE24D098FBF'
    },
    {
      name: '石河子市万盛源科技授权保障网点',
      guid: '9A68D35A0D8452986722327198B06003'
    },
    {
      name: '喀什年酬勤农资授权保障网点',
      guid: '30508B4DE2482EAFC18638BB7EEA225B'
    },
    {
      name: '伽师县东风农机销售部授权保障网点',
      guid: '24B788CE56BC582D1FEDCC7FAB185E13'
    },
    {
      name: '图木舒克市宝顺农业服务店授权保障网点',
      guid: 'CCD23DC33087CBC46537EEE56D191FD5'
    },
    {
      name: '石河子市飞客农业科技授权保障网点',
      guid: '1D1BD75FE6C74D66790A38917A2524AB'
    },
    {
      name: '内蒙古极飞商贸授权保障网点',
      guid: '0B56353F01F600EBA95BC9C65AD2DE2D'
    },
    {
      name: '巴楚县鑫鑫农资授权保障网点',
      guid: '5189D188B06971BCD6F5CD58C43ADCAA'
    },
    {
      name: '福建武夷山鑫龙农机授权保障网点',
      guid: '34C7E06906BC27DB8F8B6ED7A4A1DFF8'
    },
    {
      name: '南漳食天粮安农机授权保障网点',
      guid: 'EA3A48DB22D86EFB19DAABD677BADC00'
    },
    {
      name: '麻城市农欢植保授权保障网点',
      guid: '304F9E541CD6BE11A6D380D9D0266801'
    },
    {
      name: '绥宁星鑫生态农业开发授权保障网点',
      guid: '70A36DCE37319EBBDC4B75832E0545A0'
    },
    {
      name: '武冈市文华现代农业授权保障网点',
      guid: '231B2DE5399576238DE7F77C0B83EA0A'
    },
    {
      name: '沙河市嘉希植保统防统治服务队授权保障网点',
      guid: '6C6429AC32411F65DED42D687339F4FB'
    },
    {
      name: '广西丰港农业科技发展授权保障网点',
      guid: 'AFB0E86E0658C9DEF4B277AECDD22F18'
    },
    {
      name: '阿克苏地区农事顺农业服务授权保障网点',
      guid: 'FE6F6A87E040BDA09A69E6CC954A4BB2'
    },
    {
      name: '赤峰凯斯纽荷兰销售服务授权保障网点',
      guid: '17718879A65054B68C3E35351177DE87'
    },
    {
      name: '盱眙三农农业装备授权保障网点',
      guid: '4A77003A61D33ED83DE7644D53255106'
    },
    {
      name: '南宁市源多益商贸授权保障网点',
      guid: 'F181AF7E9723ED4A669D5F4C106F1893'
    },
    {
      name: '云南金刚飞侠科技授权保障网点',
      guid: '7D6DB2D1DB5808490233180B12F4E60F'
    },
    {
      name: '湛江市烽炎农业科技授权保障网点',
      guid: '1864DA829804B855A321D7D328A5F114'
    },
    {
      name: '青冈县天润农机授权保障网点',
      guid: '6041386DC10799184C6E8636AB1B7007'
    },
    {
      name: '湛江市源军农业科技授权保障网点',
      guid: 'C5682A9463A762684587F91EA2514D0B'
    },
    {
      name: '三明市润田贸易授权保障网点',
      guid: 'B1485313F749081B7BF7BFF59D8CA8A0'
    },
    {
      name: '南昌市润泽丰农业服务授权保障网点',
      guid: '6BEFAF0D4C3387702F47EAEC524BFCC2'
    },
    {
      name: '泊头华宇领航农业科技授权保障网点',
      guid: 'CA2D812E44B4F48B8F0779CB52EFA190'
    },
    {
      name: '巴林左旗同兴农机服务授权保障网点',
      guid: '3CA67756B34AA08C5F5AAECED15BEE9D'
    },
    {
      name: '河东区合胜水稻种植授权保障网点',
      guid: 'DB81DA7B0D29A83BBBED78C505126091'
    },
    {
      name: '昭苏县七分田农业技术服务部授权保障网点',
      guid: 'A199E7D17C4506B695228D0D0B99AB2B'
    },
    {
      name: '衡阳县丰顺农机服务授权保障网点',
      guid: 'EB2B75CE02BBFF94A24F17C95834896C'
    },
    {
      name: '连云港市赣榆区金丰农业服务授权保障网点',
      guid: '4C8BF9A48B6791173F38D71947478CF0'
    },
    {
      name: '枣庄市台儿庄区金丰农业服务授权保障网点',
      guid: '3BE6EAF475E8B10411CC2FE14A02E414'
    },
    {
      name: '平原县金丰农业服务授权保障网点',
      guid: 'DDF3658409B1A157F2E5DC6DBA6DC00F'
    },
    {
      name: '喀什益丰农资麦盖提县第一经销部授权保障网点',
      guid: '1BE89425943760B637ED11EA8106D2B0'
    },
    {
      name: '常德市景农堂农业服务授权保障网点',
      guid: '695E2384FB991F66A113A6C2660753F5'
    },
    {
      name: '巴州鑫农机械授权保障网点',
      guid: '6C4F0E8F0BD1DE2D7EF42BDF9CC8AEA7'
    },
    {
      name: '胡杨河市云天飞防农业科技服务部授权保障网点',
      guid: 'E742C2A99F934911E61AA474D55E6FF2'
    },
    {
      name: '东台市金丰农业技术服务授权保障网点',
      guid: '08BA3672E05C5AEC2B5A998858C7F2D3'
    },
    {
      name: '沙雅县金动力农机销售中心授权保障网点',
      guid: 'CE2C0D9508AA00DDD4EAD8E9D2387E77'
    },
    {
      name: '广西雅穗农业开发授权保障网点',
      guid: 'C202900217F64D1FEC71977A8CD94B43'
    },
    {
      name: '清远市建清农业机械销售授权保障网点',
      guid: '319888245D0313ECF9E446130CE83902'
    },
    {
      name: '贵州智慧教育科技授权保障网点',
      guid: '0D4964B5081E3E2941BD1AB62B8FE413'
    },
    {
      name: '郯城县泓瑞农资销售授权保障网点',
      guid: 'B76B7C0661B07B8004A5BC84517DE33D'
    },
    {
      name: '庐山市常发农机经销授权保障网点',
      guid: 'B98E5F9016A2D6E3806771FB97E388FC'
    },
    {
      name: '舞阳金丰公社农业服务授权保障网点',
      guid: 'AE363156670B4F96C010FE63154DE4C2'
    },
    {
      name: '宁晋县佳阳农业科技授权保障网点',
      guid: '5E25E7069279F861A89D97BF74FA23E6'
    },
    {
      name: '株洲县丰达农业生产资料授权保障网点',
      guid: '63BA65F88269EA4ADBA935675A175635'
    },
    {
      name: '遂川县三方乐农作物病虫害防治授权保障网点',
      guid: '4F8B424D4FF0F28DA68CEA7B18E30802'
    },
    {
      name: '湖北飞农农业发展授权保障网点',
      guid: 'C675C96880129405B39DE425CFF0EA52'
    },
    {
      name: '昌邑市宜捷农机授权保障网点',
      guid: 'C29C35CCC73403E6583CF4A81AA58A4A'
    },
    {
      name: '额敏鹏程汽车销售授权保障网点',
      guid: '6AFEA5F66D5CE99216BAAE4D356A33F0'
    },
    {
      name: '内蒙古智能数据农业信息服务授权保障网点',
      guid: 'BE2C1E8FA52190A4BDC2C6618D0BD525'
    },
    {
      name: '平乡县金丰农业服务授权保障网点',
      guid: '65D48C0647D720E435D7B130EFAD9CE8'
    },
    {
      name: '江苏汇丰农机授权保障网点',
      guid: 'D1D8E3BD9C136BA6524A428F6C51D1DF'
    },
    {
      name: '阿荣旗金丰公社农业服务授权保障网点',
      guid: '1106C5AE6FC7C78A9934EC2F299869F6'
    },
    {
      name: '杭州富阳广茂农机授权保障网点',
      guid: 'BE950A9C9745A69B581C6AD8E3F2D4CB'
    },
    {
      name: '河北聚福农业科技授权保障网点',
      guid: '5D6D372EA4BD9812D39AF6009679FC52'
    },
    {
      name: '宁津县金丰公社农业服务授权保障网点',
      guid: 'C64777B14E5BB9A62385DD2DC66B573C'
    },
    {
      name: '永城市益农农业服务授权保障网点',
      guid: '82752D8587419383AB6CE8B8E60E73FB'
    },
    {
      name: '重庆铁锐农机授权保障网点',
      guid: '412C3E2A4B1E26D5CC523239573CC120'
    },
    {
      name: '文安县金超农业科技授权保障网点',
      guid: '8C8F5BAD6A61CD4B664EA7E4FF467FAC'
    },
    {
      name: '鸡西市梦翔农机销售授权保障网点',
      guid: '56EF33DAB0FA4A6C2607C06C8676005C'
    },
    {
      name: '邓州市皆乐农业科技授权保障网点',
      guid: '247F7CBC6D8F3B53090834687624D2FF'
    },
    {
      name: '赵县谷乐农业机械服务部授权保障网点',
      guid: '62874523F0175BB2F5ACCE96CAC3868C'
    },
    {
      name: '巴州云飞农业服务授权保障网点',
      guid: '82935C2A3CCDC5878E9D97361416E26F'
    },
    {
      name: '百旺亚捷航空科技（山东）授权保障网点',
      guid: '7F664C8B38CABA045F5586CB65CB3AA4'
    },
    {
      name: '北安市国胜面业授权保障网点',
      guid: '2D883FE7999AFE3F6161589380579811'
    },
    {
      name: '浙江智诚农业开发授权保障网点',
      guid: '65B7171F739897E5F7FB5FB43CCC1D20'
    },
    {
      name: '新疆西域华合农业科技发展授权保障网点',
      guid: 'E94DB8D6CC33B69F718343C672388EFA'
    },
    {
      name: '松溪县顺飞航空服务授权保障网点',
      guid: '11189C5BBD45BD2107ECEA527F07184D'
    },
    {
      name: '永新县飞航植保授权保障网点',
      guid: '4D45EC2320E25F20B4BD6DB69F0EFC53'
    },
    {
      name: '淄博盛世云天现代农业发展授权保障网点',
      guid: 'CAEDEE3AB749604633FE1CC6BA1574B4'
    },
    {
      name: '海伦市金丰公社农业服务授权保障网点',
      guid: '853F4F51AEF656DFF1C6B0DEBA6CA158'
    },
    {
      name: '成都丰光农机授权保障网点',
      guid: 'BB451A8297CD572FF91374E0CD4EAD15'
    },
    {
      name: '乌苏十科惠农电子商务授权保障网点',
      guid: '2EDD1877115E78B14FC2A462105090DD'
    },
    {
      name: '枝江市田丰农业机械经营部授权保障网点',
      guid: '78B49812E146583AA24D5AE4B2DAB35B'
    },
    {
      name: '荆州市江岭农机授权保障网点',
      guid: '9B7D0991C36D497CEAF42530D7CEEB34'
    }
  ]
}

register(
  '/app/operation/protection/warehouse/home/auth_warehouse_list',
  getWarehouseList
)

register(
  '/app/operation/protection/service/auth/transfer_auth_list',
  getWarehouseList
)
