export default function(type = 'ONLINE') {
  switch (type) {
    case 'ONLINE':
      return getOnline()
    case 'UNDERLINE':
      return getUnderLine()
    default:
      break
  }
}

function getUnderLine() {
  return {
    items: [
      {
        title: '工时费',
        subTitle: '若需要保障专员提供维修服务，需线下支付工时费',
        value: '¥ 50'
      }
    ]
  }
}

function getOnline() {
  return {
    items: [
      {
        title: '物料费',
        value: '¥ 3400',
        subTitle: '',
        font: '免'
      },
      {
        title: '基础延保服务',
        value: '[-¥ 1900](#D0021B)',
        subTitle: '额度抵扣后剩余0元',
        font: '全'
      },
      {
        title: '意外损失服务额度支付',
        value: '[-¥ 1500](#D0021B)',
        subTitle: '额度抵扣后剩余500元',
        font: '延'
      },
      {
        title: '还需支付',
        value: '¥ 500'
      }
    ]
  }
}
