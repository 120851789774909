import { register } from './service'

/**
 * 
 * @param {*} title  左侧标题
 * @param {*} to 同vue-router 的 to 属性
 * @param {*} value 右侧内容	
 * @returns 
 */
function getCellCfg(title, to, value) {
  return {
    title,
    label: JSON.stringify(to, null, 2),
    to,
    value,
    'is-link': true
  }
}
function buildToCfg(path, params) {
  if (!params) {
    return { path }
  }
  return {
    path,
    query: {
      params: JSON.stringify(params)
    }
  }
}
const dataSource = {
  业务页面: [
    getCellCfg('【授权网点售后】:保障单列表', '/ensure-list?is_auth_after_sale=1', '234'),
    getCellCfg('【授权网点售后】:诊断', '/authas/diagnosis?guid=test'),
    getCellCfg('【授权网点售后】:维修-录入好件', '/authas/input-good-part?guid=test'),
    getCellCfg('【授权网点售后】:详情', '/authas/diagnosis-detail?guid=test'),
    getCellCfg('【授权网点售后】:账单', '/authas/cost-bill?guid=test'),
    getCellCfg('【授权网点售后】:定责分析', '/authas/detection-list?guid=test'),
    getCellCfg('【P系列定损】:提交定损信息', '/ensure/loss-verify?guid=test'),
    getCellCfg('消息', '/message'),
    getCellCfg('门店的消息', '/store-message'),
    getCellCfg('故障服务单-列表', '/ensure-list'),
    getCellCfg('保障单处理记录', '/ensure-steps'),
    getCellCfg('转让订单', {
      path: '/ensure-select-protectors',
      query: {
        params: JSON.stringify({
          auth_warehouse_guid: '245DCD9F4185360CEDF7CA755FE97C27'
        })
      }
    }),
    getCellCfg('关闭保障单', '/ensure-close-form'),
    getCellCfg(
      '诊断-录入坏件',
      buildToCfg('/ensure-loss-material', {
        guid: 'test'
      })
    ),
    getCellCfg(
      '退料-配料申请单',
      buildToCfg('/material-apply-list', {
        guid: 'test'
      })
    ),
    getCellCfg(
      '退料-多个申请单',
      buildToCfg('/material-select-multi', {
        guid: 'test',
        mp_guids: ['guid124', 'guid125']
      })
    ),
    getCellCfg(
      '物流信息-填写',
      buildToCfg('/logistics-edit?src=/mock/logistics', {
        type: 'edit'
      })
    ),
    getCellCfg(
      '定责分析报告',
      buildToCfg('/ensure-report-index?src=/mock/responAnalysis', {
      })
    ),
    getCellCfg('问答-数据处理服务功能答疑', buildToCfg('/serve/xpilot-qa')),
    getCellCfg(
      '问答-数据处理服务功能答疑-en',
      buildToCfg('/serve/xpilot-qa/en')
    ),
    getCellCfg('问答-果树测绘-帮助一', buildToCfg('/serve/xpilot-qa/果数测绘1')),
    getCellCfg('问答-果树测绘-帮助二', buildToCfg('/serve/xpilot-qa/果数测绘2'))
  ],
  详情模板: [
    getCellCfg(
      '组件-目录',
      buildToCfg(`/ensure-detail?src=/mock/ensure-detail/index`),
      '展示所有组件'
    ),
    getCellCfg('页面-故障详情', '/ensure-detail', '详情模板diyDetail'),
    getCellCfg(
      '页面-诊断审核',
      '/ensure-diagnostic-audit',
      '详情模板diyDetail'
    ),
    getCellCfg(
      '页面-维修领料单',
      '/ensure-repair-pick-list',
      '详情模板diyDetail'
    )
  ],
  列表模板: [
    getCellCfg(
      '列表',
      buildToCfg('/ensure-diy-list', {
        guid: 'test'
      })
    ),
    getCellCfg(
      '详情列表',
      buildToCfg('/ensure-detail-list', {
        guid: 'test'
      })
    )
  ],
  App: [
    {
      title: '扫描二维码',
      method: 'scanQrCode',
      'is-link': true
    }
  ],
  小程序: [
    {
      title: '跳转小程序',
      method: 'gotoMini',
      'is-link': true
    }
  ]
}

register('/mock/catalog', function() {
  return dataSource
})
