<template>
  <div class="ensure-pay-info xa-bg-white xa-txt-14">
    <div
      v-for="(item, index) in items"
      :key="index"
      class="ensure-pay-info__cell"
    >
      <div class="xa-cell ensure-pay-info__cell">
        <div class="font-icon" v-if="item.font">{{ item.font }}</div>
        <MarkdownLoader class="xa-flex" :value="item.title" />
        <MarkdownLoader v-bind="item" />
      </div>
      <MarkdownLoader
        :data-font="!!item.font"
        v-if="item.subTitle"
        class="xa-txt-12 xa-txt-secondary"
        :value="item.subTitle"
      />
    </div>
  </div>
</template>
<script>
import MarkdownLoader from '@/components/MarkdownLoader'
export default {
  components: {
    MarkdownLoader
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    }
  }
}
</script>
<style lang="scss">
.ensure-pay-info {
  padding: 15px;
  text-align: left;
  line-height: 1.5;
  .font-icon {
    margin-right: 3px;
    width: 17px;
    height: 17px;
    border-radius: 3px;
    background-color: #a90418;
    font-size: 10px;
    line-height: 17px;
    text-align: center;
    color: #fff;
  }
  &__cell {
    justify-content: space-between;
    & + & {
      margin-top: 4px;
    }
  }
  &__title {
    flex-shrink: 0;
    color: #333;
  }
  div[data-font='true'] {
    padding-left: 20px;
  }
}
</style>
