<template>
  <div v-html="render(value)" data-role="markdownLoader"></div>
</template>
<script>
// eslint-disable-next-line no-useless-escape
const hrefReg = /\[([^\[\]]+)\]\((\S+)\)/g
export default {
  name: 'MarkdownLoader',
  props: {
    value: String
  },
  methods: {
    getAdaperHref(href) {
      if (href.indexOf('http') === 0) return href
      const coms = this.$router.getMatchedComponents(href)
      if (coms.length) return href
      return window.location.origin + href
    },
    renderColor(title, value) {
      return `<span style="color:${value}">${title}</span>`
    },
    renderHref(title, value) {
      return `<a class="xa-txt-blue" href="${this.getAdaperHref(
        value
      )}" target="view_frame">《${title}》</a>`
    },
    render(content) {
      if (hrefReg.test(content)) {
        content = content.replace(hrefReg, (_, title, value) => {
          if (value.match(/^#[\w]{3,6}/)) {
            return this.renderColor(title, value)
          } else {
            return this.renderHref(title, value)
          }
        })
      }
      return content
    }
  }
}
</script>
<style lang="scss">
[data-role='markdownLoader'] {
  white-space: pre-line;
}
</style>
