<template>
  <section id="app">
    <div id="app-container">
      <PullRefresh v-model="isLoading" :disabled="pullRefreshDisabled">
        <keep-alive :include="/^Cache/">
          <router-view />
        </keep-alive>
      </PullRefresh>
    </div>
    <van-action-sheet
      v-model="showActionSheet"
      :actions="actionSheetActions"
      @select="onActionSheetSelect"
    />
  </section>
</template>
<script>
import { mapState } from 'vuex'
import { PullRefresh, ActionSheet } from 'vant'
import { setGlobelHeaderByUrl } from '@/controllers/request'
import { login } from '@/apis/user'
import request from '@/controllers/request'
export default {
  components: {
    PullRefresh,
    VanActionSheet: ActionSheet
  },
  watch: {
    $route() {
      setGlobelHeaderByUrl(window.location.hash, 'token')
    }
  },
  computed: {
    isLoading: {
      get() {
        return this.$store.state.isRefreshing
      },
      set(value) {
        this.$store.commit('triggleRefresh', value)
      }
    },
    showActionSheet: {
      get() {
        return this.$store.state.showActionSheet
      },
      set(value) {
        this.$store.commit('triggleActionSheet', value)
      }
    },
    ...mapState({
      actionSheetActions: state => state.actionSheetActions,
      pullRefreshDisabled: state => state.pullRefreshDisabled
    })
  },
  methods: {
    onActionSheetSelect(item) {
      this.$store.commit('setActionSheetResult', item)
    },
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false
      }, 500)
    }
  },
  beforeMount() {
    if (process.env.NODE_ENV !== 'production') request(login())
  }
}
</script>

<style lang="scss">
#app {
  font-family: 'PingFangSC-Regular', 'Microsoft YaHei', 'Avenir', Helvetica,
    Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background: #efeff4;
  min-height: 100vh;
}

#app-container {
  min-height: 100vh;
  max-width: $max-page-width;
  margin: 0 auto;
  box-shadow: -20px 0px 20px $color-primary, 0px 00px 10px #fff,
    20px 0px 20px $color-primary, 0px 0px 0px #fff;
}

#app-container > .van-pull-refresh {
  min-height: 100vh;
}
</style>
