<template>
  <div class="btns-wrap" :class="{ 'btns-wrap--sticky': sticky }">
    <div class="xa-cell max-container">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="xa-flex btns-item xa-cell"
        :class="item.UiType"
        :style="dealStyle(item.theme)"
        @click="onClick(item)"
      >
        <van-icon v-if="item.UiType === 'more'" name="ascending" />
        <span v-else>{{ item.title }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { Icon } from 'vant'
export default {
  components: {
    VanIcon: Icon
  },
  props: {
    sticky: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default() {
        return []
      }
    }
  },
  methods: {
    onClick(btn) {
      this.$emit('action', btn)
    },
    dealStyle(theme){
      if (theme=='grey') {
        return 'background-color:#e4e4e4'
      }
      
    }
  }
}
</script>
<style lang="scss" scoped>
.btns-wrap {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  box-sizing: border-box;
  text-align: center;
  z-index: 10;
  &--sticky {
    position: sticky;
  }
  > .max-container {
    background-color: $default-page-color--light;
  }
  .xa-cell {
    height: 100%;
    justify-content: center;
  }
  .btns-item {
    box-shadow: inset 0 8px 0 $default-page-color--light,
      inset 0 -8px 0 $default-page-color--light,
      inset 4px 8px 0 $default-page-color--light,
      inset -4px 8px 0 $default-page-color--light;
    background-color: $color-primary;
    color: #fff;
    &.more {
      flex: 0;
      padding: 0 16px;
      color: $color-primary;
      background-color: #fff;
    }
    .grey{
  background-color: #ccc;
  color:white
}
  }
}

</style>
