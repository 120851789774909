import { register } from '@/mock/service'
import { console } from '@/mock/util'

register('/mock/logistics', function() {
  console('录入物流信息: 为底部提交按钮的配置')
  console('录入物流信息: 页面录入的物流信息，将以【express】字段提交')
  console('录入物流信息: 在页面完成提交后，将后退到上一个页面')
  return {
    title: '寄件物流信息',
    body: {
      info: {
        title: '仓库收件信息',
        name: '正小环',
        phone: '15915812324',
        address: '广州市天河区高普路四成接 广州市天河区高普路四成接',
        tip: '请附上纸条，写上用户姓名，保障单号'
      },
      express: []
    },
    actions: {
      method: 'post',
      url: '/mock/logistics/save',
      data: {}
    }
  }
})

register('/mock/logistics/save', function() {
  return {}
})
