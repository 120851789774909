import { request } from './util'
import { register } from './service'
let id = 0
const statusInfo = [
  {
    title: '已结束',
    type: 'primary'
  },
  {
    title: '已结束',
    type: 'success'
  },
  {
    title: '已结束',
    type: 'danger'
  },
  {
    title: '已结束',
    type: 'warning'
  }
]
function getBaseInfoItem_service() {
  return {
    href:
      '/ensure-detail-list?params=%7B"guid"%3A"EE8FD25AC792F331F7EA03897BDAD2F5"%7D',
    title: '全保服务',
    items: [
      {
        title: '保障服务类型',
        value: '全保服务 [服务协议](https://www.xa.com/)'
      },
      {
        title: '故障维修剩余额度',
        value: '[￥31998.00](#1989fa)'
      },
      {
        title: '消耗件剩余额度',
        value: '[￥30000.00](#f17e20)'
      },
      {
        title: '服务周期',
        value: '2010.1.3 ~ 2012.1.1'
      }
    ]
  }
}
function getBaseInfoItem() {
  return {
    href: '',
    title: `[3类 2件 ${++id}](#00ff00)`,
    subTitle: '极飞华南区仓储中心',
    items: [
      {
        title: '已结束',
        value: 'primary'
      },
      {
        title: '物流配送(已签收)',
        value: '2020.1.1 15:30'
      }
    ],
    status: statusInfo.slice(0, 4),
    tags: statusInfo.slice(0, 4),
    foot: '刘波',
    time: '2020.01.01 00:00:00'
  }
}

function getMoreCom() {
  return {
    api: '/mock/diy-list',
    params: {
      type: 'more'
    }
  }
}

function getBaseFormate({ title, filter, body }) {
  return {
    title,
    config: {},
    filter,
    body
  }
}

function pageInit(params) {
  return request(
    params,
    getBaseFormate({
      title: 'diy-list',
      body: [
        {
          UiType: 'eventinfo',
          content:
            '2020-01-09 15:49:39[故障分析报告](/pm/#!/ensure/report?src=/app/dev/service/technical_pdf_report&params={%22guid%22%3A%22DD2583A2CED2D83B40F782C3B44F4454%22}) | 故障分析 [待客户确认](#ff7700)'
        },
        {
          UiType: 'baseinfo',
          ...getBaseInfoItem()
        },
        {
          UiType: 'baseinfo',
          ...getBaseInfoItem()
        },
        {
          UiType: 'baseinfo',
          ...getBaseInfoItem_service()
        },
        {
          UiType: 'empty',
          content: '暂无相关数据，请新建。'
        },
        {
          UiType: 'More',
          ...getMoreCom()
        }
      ]
    })
  )
}
function getDetailList() {
  return {
    title: '保障服务详情',
    info: getBaseInfoItem_service(),
    list: {
      src: '/mock/diy-list',
      params: {
        type: 'list'
      }
    }
  }
}
register('/mock/diy-list', pageInit)

register('/mock/diy-list/ensure-detail-list', getDetailList)
